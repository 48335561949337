import React, { Component } from 'react';
import { ContactInfo, Country, FriendRequest, GlobalContactInfo, GroupForm, GroupInfo } from '../interfaces';
import restService from './rest.service';
import axios, { AxiosInstance } from 'axios';
import { getImageFormData } from './helpers.services';

export const getContacts = (): Promise<ContactInfo[]> => {
    // return axios.get('https://randomuser.me/api/?results=50')
    //     .then((response: any) => {
    //         return response.data.results;
    //     })
    return restService.getUserContacts().then(response => response.data);
}


export const getGroups = (): Promise<GroupInfo[]> => {
    return restService.getMyGroups().then(response => {
        if (response.data) return response.data;
        return [];
    });
    // return axios.get('https://randomuser.me/api/?results=50')
    //     .then((response: any) => {
    //         return response.data.results;
    //     })
}

export const getGroupById = (id: string) => {
    return restService.getGroupById(id).then(response => response.data);
}


export const deleteGroup = (id: string) => {
    return restService.deleteGroup(id).then(response => response.data);
}

export const createNewGroup = (doc: GroupForm): Promise<GroupInfo> => {
    return restService.createNewGroup(doc).then(response => response.data);
}

export const uploadGroupImage = (doc) => {
    return restService.uploadGroupImage(getImageFormData(doc)).then(response => {
        return response.data;
    })
}

export const getGlobalContacts = (doc: { page: number, limit: number }) => {
    return restService.getGlobalContacts(doc).then(response => {
        return {
            ...doc,
            countAll: parseInt(response.headers["x-total-count"]),
            result: response.data
        };
    });
}


//Friends
export const searchAppUsers = (value: string) => {
    return restService.searchFriends(value).then(response => response.data);
}

export const getSentRquests = () => {
    return restService.getAllSentFriendRequests().then(response => response.data);
}

export const getAppUsers = (doc: { page: number, limit: number }) => {
    return restService.getAllAppUsers(doc).then(response => {
        return {
            ...doc,
            countAll: parseInt(response.headers["x-total-count"]),
            result: response.data
        };
    });
}

export const getFriendRquests = () => {
    return restService.getAllFriendRequests().then(response => response.data);
}


export const addFriend = (doc) => {
    return restService.addUserAsFriend(doc).then(response => response.data);
}

export const acceptFriendRequest = (id) => {
    return restService.acceptFriendRequest(id).then(response => response.data);
}

export const rejectFriendRequest = (id) => {
    return restService.rejectFriendRequest(id).then(response => response.data);
}


// export function getUserInfoFromFriendRequests(docs: FriendRequest[]): GlobalContactInfo[]{
//     return docs.map(doc => {
//         return {
//             id: doc.requestedUser.id,
//             email: doc.requestedUser.email,
//             login: doc.requestedUser.login,
//             firstName: doc.requestedUser.firstName,
//             lastName: doc.requestedUser.lastName
//         }
//     })
// }

export function validateTotalCountForList(headerCountAll: number, responseCount: number, limit: number) {
    if (responseCount === limit) return headerCountAll;
    if (responseCount < limit && responseCount === headerCountAll) return headerCountAll;
    if (responseCount < limit && responseCount !== headerCountAll) return responseCount;
    return headerCountAll;
}