import React, { useEffect, useState } from "react";
import { DRAWER_TYPE, FONT, THEME } from "../enums";
import { useTranslation } from 'react-i18next';
import { currentWindowHeight, currentWindowWidth, EMAIL_PATTERN, GENDERS } from "../variables";
import { CustomButton, ScreenHolder, SelectorList } from "../components";
import { CustomCheckBox, FormInput, FormSelector } from "../utils";
import CustomText from "../utils/custom-text";
import { useDispatch, useSelector } from "react-redux";
import { selectUserState } from "../store/selectors";
import * as fromStore from "../store";
import { extractError, getUserName } from "../services";
import { User } from "../interfaces";
import { SpinnerScreen } from "../utils/spinner.screen";
import { MAIN_APP_ROUTES } from "../routes";
import * as AuthService from '../services/auth.service';

const wrapperHeight = (currentWindowHeight() / 3) * 2;

const EDIT_PROFILE_FORM = [
    {
        field: 'username',
        label: '',
        secure: false,
        type: 'username',
        placeholder: 'NAME',
        pattern: ''

    },
    {
        field: 'email',
        label: '',
        secure: false,
        type: 'emailAddress',
        placeholder: 'EMAIL',
        pattern: EMAIL_PATTERN,
        editable: false,

    },
    {
        field: 'phone',
        label: '',
        secure: false,
        type: 'telephoneNumber',
        keyboardtype: 'phone-pad',
        placeholder: 'PHONE',
        pattern: ''

    },
    {
        field: 'gender',
        label: '',
        secure: false,
        type: 'selector',
        placeholder: 'GENDER',
        pattern: ''

    },
    // {
    //     field: 'password',
    //     label: '',
    //     secure: true,
    //     type: 'password',
    //     placeholder: 'PASSWORD',
    //     pattern: /^\w+([\.-]?\w+)/
    // }
];



export function EditProfileScreen(props: any) {
    const { history, location } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { user, loading } = useSelector((state) => fromStore.selectUserState(state));
    const profileState = useSelector((state) => fromStore.selectProfileState(state));
    const [state, setState] = useState<any>({
        username: '',
        email: '',
        phone: '',
        gender: { name: '', code: '' },
        password: '',
        newsletter: false,
    });
    const [prevState, setPrevState] = useState<any>(null);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    // const [isEnabled, setIsEnabled] = useState(false);

    useEffect(() => {
        if (user) {
            const formState = getUserForm(user);
            setState(formState);
            setPrevState(formState);
        }
        return () => {

        }

    }, [user])

    function getUserForm(user) {
        return {
            ...state,
            username: getUserName(user),
            email: user.email,
            gender: user.gender ? GENDERS.find(doc => doc.code === user.gender) : { name: '', code: '' },
            phone: user.phone,
            newsletter: user.newsletterSubscribed
        };

    }

    // const toggleSwitch = () => setIsEnabled(previousState => !previousState);

    function handleFieldChange(fieldId, value) {
        setState({ ...state, [fieldId]: value });
    }

    function _isFormValid() {
        const { username, email, phone, gender, password } = state;
        const pattern = EMAIL_PATTERN;
        if (username == '') return false;
        if (email == '') return false;
        // if (phone == '') return false;
        if (phone && isNaN(phone)) return false;
        if (phone && (phone.length > 10 || phone.length < 10)) return false;
        if (gender == '') return false;
        if (pattern.test(email) == false) return false;
        if (isFormChanged()) return false;
        // if (password == '') return false;
        // if (password && password.length < 6) return false;
        // if (password && password.length >= 15) return false;
        return true;
    }

    function isFormChanged(): boolean {
        if (!prevState) return false;
        return (
            state.username === prevState.username &&
            state.email === prevState.email &&
            state.phone === prevState.phone &&
            state.gender === prevState.gender &&
            state.newsletter === prevState.newsletter
        );
    }

    if (profileState.loading) {
        return (
            <ScreenHolder drawerType={DRAWER_TYPE.HALF} location={location} history={history} >
                <SpinnerScreen />
            </ScreenHolder>
        )
    }

    return (
        <ScreenHolder drawerType={DRAWER_TYPE.HALF} location={location} history={history} >
            <div style={styles.formContainer}>
                <div style={styles.header}>
                    <CustomText customStyle={styles.headerText}>{t('editProfile')}</CustomText>
                </div>
                <div style={styles.registerForm}>
                    {EDIT_PROFILE_FORM.map((doc, index) => (
                        <div key={doc.field} style={styles.inputField}>
                            {doc.type !== 'selector' ?
                                <FormInput
                                    id={doc.field}
                                    onChange={handleFieldChange}
                                    secure={doc.secure}
                                    placeholder={doc.placeholder}
                                    type={doc.type}
                                    value={state[doc.field]}
                                    pattern={doc.pattern}
                                    label={doc.label}
                                    keyboardtype={doc?.keyboardtype}
                                    editable={doc.editable}
                                />
                                :
                                <FormSelector
                                    placeholder={doc.placeholder}
                                    selected={state[doc.field]}
                                    onClick={() => setIsVisible(true)}
                                />
                            }
                        </div>
                    ))}
                </div>
                <div style={styles.newsLetter}>
                    <CustomCheckBox
                        title={t('subscribeForNewsLetter')}
                        checked={state['newsletter']}
                        checkedColor="#4B0082"
                        containerStyle={styles.checkbox}
                        onClick={() => handleFieldChange('newsletter', !state['newsletter'])}
                    />
                </div>
                <div style={styles.action}>
                    <div style={{ ...styles.btn, ...{ width: `${(100 / 2) - 5}%`, } }}>
                        <CustomButton
                            textStyle={{ textTransform: 'uppercase' }}
                            name={t('save')}
                            enable={_isFormValid()}
                            padding={10}
                            onClick={saveUserProfile}
                        />
                    </div>
                    <div style={{ ...styles.btn, ...{ width: `${(100 / 2) - 5}%`, } }}>
                        <CustomButton
                            textStyle={{ textTransform: 'uppercase' }}
                            name={t('cancel')}
                            enable={true}
                            padding={10}
                            backgroundColor={THEME.CANCEL}
                            onClick={() => {
                                setState(getUserForm(user));
                                history.push(MAIN_APP_ROUTES.SETTINGS);
                            }}
                        />
                    </div>
                </div>
                {isVisible &&
                    <SelectorList
                        list={GENDERS}
                        selected={state['gender']}
                        loading={false}
                        type={"basic"}
                        setItem={(doc) => {
                            setState({ ...state, ['gender']: doc }); setIsVisible(false)
                        }}
                        title={t("gender")}
                        visible={isVisible}
                        wrapperHeight={wrapperHeight}
                        onClose={() => { setIsVisible(false) }}
                        onDissmiss={() => { setIsVisible(false) }}
                    />}
            </div>
        </ScreenHolder>
    );

    async function saveUserProfile() {
        try {
            const { username, email, phone, gender, newsletter } = state;
            const data: User = {
                ...user as User,
                firstName: username,
                lastName: "",
                email: email,
                phone: phone,
                gender: gender.code ? gender.code : null,
                newsletterSubscribed: newsletter
            }

            // dispatch(fromStore.updateProfile(data));
            const response = await AuthService.updateUserProfile(data.id, data);
            dispatch(fromStore.updateProfileSuccess(response));
            dispatch(fromStore.toastMessage(t('profileUpdatedSuccessfully')));
            history.push(MAIN_APP_ROUTES.SETTINGS);
        } catch (error: any) {
            const newError = extractError(error, MAIN_APP_ROUTES.EDIT_PROFILE);
            dispatch(fromStore.updateProfileError(newError));
        }

    }
}


const styles: { [key: string]: React.CSSProperties } = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: THEME.BLACK
    },
    logo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        paddingLeft: 50,
        paddingRight: 50
    },
    image: {
        width: 250,
        height: "100%",
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: THEME.WHITE,

        width: '100%',
        paddingTop: 30,
        // paddingLeft: 15,
        // paddingRight: 15,
        borderTopLeftRadius: 30,
        borderTopRightRadius: 30,
    },

    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: THEME.WHITE,
        position: 'relative',
        width: '100%',
        height: '100%'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 10,
        paddingRight: 10
    },
    headerText: {
        fontSize: 17,
        color: THEME.BLACK,
        textTransform: 'uppercase',
    },
    registerForm: {
        paddingLeft: 20,
        paddingRight: 20,
        // marginTop: 20
    },
    inputField: {
        marginBottom: 5
    },
    newsLetter: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    action: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 10,
        marginBottom: 20,
    },
    btn: {

        height: 40
    },
    checkbox: {
        backgroundColor: THEME.WHITE,
        borderWidth: 0
    },
}
