import React from "react";
import { THEME } from "../enums";
import { ContactIcon } from "../icons";
import { ContactInfo, SharedUser } from "../interfaces";
import { getUserName } from "../services";
import { ImageWithSpinner } from "../utils";
import CustomText from "../utils/custom-text";


interface UserContactProps {
    item: ContactInfo,
    isSelected: boolean,
    onSelect: (doc: ContactInfo) => void,
    onDeselect: (doc: ContactInfo) => void
}
export function UserContact(props: UserContactProps) {
    const { item, isSelected, onSelect, onDeselect } = props;
    const { recipientUser } = item;
    return (
        <div
            className={"on-press-cls"}
            style={styles.contactPressable}
            onClick={() => { isSelected ? onDeselect(item) : onSelect(item) }}
        >

            <div style={styles.contactContainer}>
                <div style={styles.contactInfo}>
                    <div style={styles.contactIcon}>
                        <ImageWithSpinner
                            uri={recipientUser.profilePicUrl}
                            resizeMode={"cover"}
                            customStyle={{
                                height: "100%",
                                width: "100%",
                                backgroundColor: "transparent",
                                borderRadius: 25
                            }}
                            imgStyle={{
                                height: "100%",
                                width: "100%",
                                backgroundColor: "transparent",
                                borderRadius: 25
                            }}
                            spinnerSize={15}

                        />
                    </div>
                    <div style={{ width: '70%' }}>
                        <CustomText
                            customStyle={{
                                fontSize: 14,
                                width: '100%',
                                // whiteSpace: "nowrap",
                                overflowX: "hidden",
                                textOverflow: "ellipsis",
                            }}
                        >{getUserName(recipientUser)}</CustomText>
                    </div>
                </div>
                <div style={{
                    ...styles.indicator,
                    ...{ backgroundColor: isSelected ? THEME.ORANGE_RED : THEME.WHITE }
                }}
                ></div>
            </div>

        </div>

    )
}

interface GroupContactProps {
    item: SharedUser,
}

export function GroupUserContact(props: GroupContactProps) {
    const { item } = props;
    return (
        <div
            style={styles.contactPressable}
            onClick={() => { }}
        >
            <div style={styles.contactContainer}>
                <div style={styles.contactInfo}>
                    <div style={styles.contactIcon}>
                        <ImageWithSpinner
                            uri={item.profilePicUrl}
                            resizeMode={"cover"}
                            customStyle={{
                                height: "100%",
                                width: "100%",
                                backgroundColor: "transparent",
                                borderRadius: 25
                            }}
                            imgStyle={{
                                height: "100%",
                                width: "100%",
                                backgroundColor: "transparent",
                                borderRadius: 25
                            }}
                            spinnerSize={15}

                        />
                    </div>
                    <div style={{ width: '75%' }}>
                        <CustomText customStyle={{ fontSize: 14 }} numberOfLines={1} ellipsizeMode={"tail"}>{getUserName(item)}</CustomText>
                    </div>
                </div>
            </div>
        </div>

    )
}

const styles: { [key: string]: React.CSSProperties } = ({
    contactPressable: {
        paddingTop: 10,
        paddingBottom: 10,
        borderBottomWidth: 1,
        borderBottomColor: THEME.INPUT_BORDER,
        borderBottomStyle: 'solid',
        boxSizing: 'border-box',
        width: '100%',
    },
    contactContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxSizing: 'border-box',
        width: '100%',
    },
    contactInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        boxSizing: 'border-box',

        width: '100%',
    },
    contactIcon: {
        width: 45, height: 45, borderRadius: 25, marginRight: 20
    },
    indicator: {
        width: 24,
        height: 24,
        padding: 2,
        borderWidth: 1,
        borderColor: THEME.INPUT_BORDER,
        borderRadius: 24,
        borderStyle: 'solid'
    },
})