import React, { PureComponent, useEffect, useRef, useState } from 'react';
// import { TextInput, View, Keyboard, Text, StyleSheet, div, Image, FlatList } from 'react-native';
import { useTranslation } from 'react-i18next';
import CustomText from '../utils/custom-text';
import { APP_THEME_COLOR, currentWindowWidth, CURRENT_OS, FILE_SIZE_LIMIT } from '../variables';
import { DEVICE_TYPE, FONT, THEME } from '../enums';
import { IconProvider } from './icon-provider';
import { getFileSize, formatBytes } from '../services';

const boxWidth = 70;

interface FormInputWithImageProps {
    index: number,
    value: any,
    secure?: any,
    placeholder: any,
    keyboardtype?: any,
    type: any,
    id: string,
    pattern?: any,
    label: string,
    password?: string,
    fieldStyles?: any,
    clearButtonMode?: any,
    editable?: any,
    placeholderTextColor?: string,
    currentPassword?: string,
    multiline?: boolean,
    numberOfLines?: number,
    backgroundColor?: string,
    remove?: boolean,
    required?: boolean,
    textContentType?: string,
    onChange: (id: string, value: any) => void,
    onRemove: (index: number) => void,
}
export function FormInputWithImage(props: FormInputWithImageProps) {
    const {
        index,
        value,
        secure,
        placeholder,
        keyboardtype,
        type,
        id,
        pattern,
        label,
        password,
        fieldStyles,
        clearButtonMode,
        editable,
        placeholderTextColor,
        multiline,
        numberOfLines,
        backgroundColor,
        remove,
        required,
        textContentType,
        onChange,
        onRemove
    } = props;
    const inputFile = useRef<HTMLInputElement>(null);
    const [error, setError] = useState<string | null>('');
    const [state, setState] = useState<{ value: any, image?: File | null, imageUrl?: any }>(value ? value : {
        value: '',
        image: null,
        imageUrl: null
    });
    const { t } = useTranslation();
    const [dataUrl, setDataUrl] = useState<any>();

    useEffect(() => {
        readImageFile();
    }, [state.image])

    function readImageFile() {
        if (state.image === null) {
            setDataUrl(null);
            return;
        }
        let file = state?.image;
        let reader = new FileReader();
        reader.onload = (event) => {
            // The file's text will be printed here
            if (event.target) {
                setDataUrl(event.target.result);
            }

        };
        reader.readAsDataURL(file as File);
    }

    function onFileChange(e) {
        if (e.target.files[0]) handleImageChange(e.target.files[0]);
    }

    function onAddMore() {
        if (inputFile.current) inputFile?.current.click();
    }

    const handleImageChange = (response: File) => {
        let newState = {
            ...state,
            image: response,
        };
        setState(newState);
        onChange(props.id, newState);
    }

    const handleChange = (text: any) => {
        let newState = {
            ...state,
            value: text,
        };
        setState(newState);
        onChange(props.id, newState);
    }

    const handleValidation = (text: any) => {
        switch (type) {
            case "option": {
                if (text == '') return setError(t('validators.required'));
                setError(null);
                break;
            };
            default: {
                setError(null);
            }
        }

    }

    return (
        <div style={{ ...{ position: 'relative', display: 'flex', flexDirection: 'column', boxSizing: 'border-box' }, ...fieldStyles ? fieldStyles : null }}>
            <input ref={inputFile} type={'file'} accept={"image/*"} style={{ display: 'none' }} onChange={onFileChange} />
            {label ? (
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <CustomText customStyle={{ ...styles.lableHeader, color: THEME.BLACK }}>{label + (required ? ' *' : '')}</CustomText>
                    {remove && <div
                        style={{
                            // { backgroundColor: pressed ? 'rgba(130, 130, 130, 0.2)' : THEME.WHITE }
                        }}
                        onClick={() => onRemove(index)}
                    >

                        <CustomText className={"active-text"} customStyle={{ ...styles.lableHeader, ...{ color: 'blue' } }}>{'Remove'}</CustomText>

                    </div>}
                </div>
            ) : null}
            <div style={{ ...styles.inputIconView, ...editable == false ? { opacity: 0.4, } : null }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    minHeight: 45,
                    borderRadius: 10,
                    // paddingLeft: 15,
                    paddingRight: 15,
                    borderWidth: 1,
                    borderColor: THEME.INPUT_BORDER,
                    backgroundColor: backgroundColor ? backgroundColor : THEME.WHITE,
                    boxSizing: 'border-box'
                }}>
                    <input
                        style={{
                            ...styles.input,
                            ...{
                                height: numberOfLines ? numberOfLines * 30 : 45,
                                width: '90%',
                                borderWidth: 0,
                                borderColor: THEME.WHITE
                            },
                            ...{ backgroundColor: backgroundColor ? backgroundColor : THEME.WHITE }
                        }}
                        placeholder={placeholder}
                        // placeholderTextColor={placeholderTextColor ? placeholderTextColor : THEME.PLACEHOLDER}
                        // textContentType={textContentType ? textContentType : type}
                        // secureTextEntry={secure}
                        // keyboardType={keyboardtype}
                        onChange={event => {
                            handleChange(event.target.value);
                            handleValidation(event.target.value);
                        }}
                        value={state.value}
                        autoCapitalize={"none"}
                        // autoCompleteType={"off"}
                        autoFocus={false}
                        disabled={editable === false}
                    // caretHidden={false}
                    // clearButtonMode={clearButtonMode ? clearButtonMode : "while-editing"}
                    // contextMenuHidden={false}
                    // editable={editable}
                    // multiline={multiline}
                    // numberOfLines={numberOfLines}
                    //onBlur={Keyboard.dismiss} 
                    />
                    {(dataUrl || state.imageUrl) ? (
                        <div
                            style={{
                                ...styles.optionImg,
                                // ...{ opacity: pressed ? 0.5 : 1 },
                            }}
                            onClick={onAddMore}
                        >
                            {dataUrl && <img
                                src={dataUrl}
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    backgroundColor: "transparent",
                                    objectFit: 'cover'
                                }}
                            />}
                            {(state.imageUrl && !state.image) && <img
                                src={state.imageUrl}
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    backgroundColor: "transparent",
                                    objectFit: 'cover'
                                }}
                            />}
                        </div>
                    )
                        :
                        <div
                            title={'Upload an image'}
                            style={{
                                ...styles.optionImgIcon,
                                // ...{ opacity: pressed ? 0.5 : 1 },
                            }}
                            onClick={onAddMore}
                        >
                            <IconProvider type={"image"}  title={'Upload an image'}></IconProvider>
                        </div>}

                </div>

                {value && <OptionErrorMessage image={value.image} error={error} />}
            </div>
        </div>
    )
}


function OptionErrorMessage(props: { image: File | null, error: string | null }) {
    const { image, error } = props;
    const { t, i18n } = useTranslation();
    const size = (image && image.size) ? getFileSize(image.size) : null;
    //` File size ${formatBytes(bytes)} exceeded the allowed limit of ${FILE_SIZE_LIMIT} MB`
    if(error) {
        return (
            <CustomText customStyle={{ color: THEME.RED, fontSize: 11, padding: 5 }}>{error}</CustomText>
        );
    }
    if(size && size > FILE_SIZE_LIMIT){
        return (
            <CustomText customStyle={{ color: THEME.RED, fontSize: 11, padding: 5 }}>{t('exceededFileSizeLimit', { size: formatBytes(image?.size), limit: FILE_SIZE_LIMIT })}</CustomText>
        );
    }
    return null;
}


const styles: { [key: string]: React.CSSProperties } = ({
    lableHeader: {
        fontSize: 13,
        fontFamily: FONT.MANROPE_REGULAR, //Helvatica
        marginBottom: 10,
        marginTop: 10,
        color: THEME.BLACK
    },
    inputIconView: {
        display: 'flex',
        flexDirection: 'column',
    },
    input: {
        // textAlignVertical: "top",
        minHeight: 45,
        color: THEME.BLACK,
        borderRadius: 10,
        paddingLeft: 15,
        fontFamily: FONT.MANROPE_REGULAR,
        borderWidth: 1,
        borderBlockStyle: 'solid',
        borderColor: THEME.INPUT_BORDER,
        fontSize: 12
    },
    validatorError: {
        color: 'red',
        padding: 5,
        fontSize: 10,
        fontFamily: FONT.MANROPE_REGULAR

    },

    //selector styles
    container: {
        display: 'flex', flexDirection: 'column',
        width: '100%',
        position: 'relative',
    },
    innercontainer: {
        position: 'relative',
        width: '100%',
        borderRadius: 10,
        borderWidth: 2,
        borderBlockStyle: 'solid',
        borderColor: 'rgb(244,244,244)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 10,
    },
    // label: {
    //     position: 'absolute',
    //     top: -8,
    //     left: 20,
    //     backgroundColor: THEME.WHITE,
    //     paddingLeft: 10,
    //     paddingRight: 10,
    // },
    labelTxt: {
        fontSize: 12,
        fontFamily: FONT.MANROPE_REGULAR
    },
    innervalue: {
        display: 'flex',
        flexDirection: 'row',
    },
    thumbnail: {
        width: 30, height: 25, marginTop: 10, marginRight: 10, borderRadius: 10
    },
    triangle: {
        height: 6,
        width: 10,
        backgroundColor: "transparent",
        borderColor: "transparent",
        // transform: [
        //     {
        //         rotate: "-180.00deg"
        //     }
        // ],

    },

    multiInput: {
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column'
    },
    selectorText: {
        fontSize: 11,
        fontFamily: FONT.MANROPE_REGULAR, //Helvatica
        color: THEME.BLACK
    },

    selectorPlaceholder: {
        fontSize: 11,
        fontFamily: FONT.MANROPE_REGULAR, //Helvatica
        color: THEME.PLACEHOLDER
    },

    //Image input

    imgInputContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        borderRadius: 10,
        padding: 10,
        borderWidth: 1,
        borderBlockStyle: 'solid',
        borderColor: THEME.INPUT_BORDER,
        borderStyle: 'dashed'
    },
    imgInstructions: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    imgPreviewContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    imgInputPlaceholder: {
        fontSize: 16, color: '#B2B2B2'
    },
    brwTxt: {
        textDecorationLine: 'underline'
    },

    //image preview styles

    imgPreviewItem: {
        position: 'relative',
        display: 'flex',
        width: boxWidth,
        height: boxWidth + 10,
        marginTop: 8,
        borderWidth: 1,
        borderBlockStyle: 'solid',
        borderColor: THEME.INPUT_BORDER,
        borderRadius: 5,
        marginRight: 10
    },

    addMoreContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: boxWidth - 20,
        height: boxWidth - 20,
        marginTop: 20,
        borderWidth: 1,
        borderBlockStyle: 'solid',
        borderColor: THEME.INPUT_BORDER,
        borderRadius: 5
    },


    //Image & form input styles

    optionImg: {
        width: 20, height: 30, borderWidth: 1, borderBlockStyle: 'solid', borderColor: 'rgba(130,130,130,0.2)', cursor: 'pointer'
    },
    optionImgIcon: {
        width: 20, height: 20, cursor: 'pointer'
    },

    optionMenuContainer: {
        // position: 'absolute',
        // bottom: -65,
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        alignItems: 'flex-end',
        width: '100%',
        paddingTop: 5, paddingBottom: 5,
        backgroundColor: THEME.WHITE,
    },

    optionMenu: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: THEME.WHITE,
        borderWidth: 1,
        borderBlockStyle: 'solid',
        borderColor: THEME.INPUT_BORDER,
        // borderRadius: 10,
        // shadowColor: 'rgba(0,0,0,0.2)',
        // shadowOffset: { width: 0, height: 0 },
        // shadowOpacity: 0.7,
        // shadowRadius: 2,
        // elevation: 5
    },

    optionBtn: {
        paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5
    },

});