import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { CustomButton, DecisionItem } from '../components';
import { DEVICE_TYPE, FONT, THEME } from '../enums';
import { Decision, MySurvey } from '../interfaces';
import { MAIN_APP_ROUTES } from '../routes';
import { checkErrorResponse, isCompleted } from '../services';
import * as fromStore from '../store';
import { IconProvider, getIconNameByQuestionType, RefreshWithError, CustomScrollView, ActionDialogHolder, ActionMessage, ActionDialogHolderType } from '../utils';
import CustomText from '../utils/custom-text';
import { SpinnerScreen } from '../utils/spinner.screen';
import { CURRENT_OS } from '../variables';
import '../scss/decisions.scss';

export function LiveSurveyScreen(props: any) {
  const { history, location } = props;
  const { params } = location;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const decisions = useSelector(state => fromStore.selectMyActiveSurveys(state));
  const mySurveyState = useSelector(state => fromStore.selectMyActiveSurveysState(state));
  const actionRef = useRef<ActionDialogHolderType>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [delId, setDelId] = useState<string | null>(null);

  useEffect(() => {
    getMySurveysCallback();
    return () => {
    }
  }, [])

  function displayToastMessage(error) {
    if (!error) return;
    dispatch(fromStore.toastMessage(checkErrorResponse(error)));
  }

  function getMySurveysCallback() {
    if (mySurveyState.error) dispatch(fromStore.clearMyActiveSurveysError());
    dispatch(fromStore.getMyActiveSurveys());
  }

  return (
    <div style={styles.container}>
        <CustomScrollView
          customStyle={{}}
          listLength={decisions.length}
          countAll={mySurveyState.countAll}
          refreshing={mySurveyState.loading}
          emptyTitle={t('noLiveSurveys')}
          error={mySurveyState.error}
          onToast={displayToastMessage}
          onRefresh={getMySurveysCallback}
          fetchMore={() => { dispatch(fromStore.getMoreMyActiveSurveys()) }}
        >
          {decisions && decisions.map((item, index) => (
            <DecisionItem
              key={item.id}
              item={item}
              isCompleted={isCompleted(item)}
              state={mySurveyState.delete}
              onClick={doc => history.push(`${MAIN_APP_ROUTES.SURVEY_RESULTS}/${doc.id}`, { id: doc.id })}
              onDelete={(item) => { setVisible(true); setDelId(item.id) }}
            />
          ))}
        </CustomScrollView>
      {visible && <ActionDialogHolder
        ref={actionRef}
        visible={visible}
        wrapperWidth={'65%'}
        onClose={() => { }}
        onDissmiss={() => { }}
      >
        <ActionMessage
          text={t('dialog.deleteSurvey')}
          onCancel={onCancelDelete}
          onAccept={onConfirmDelete}
          headerText={t('attention!')}
          actions={[`${t('yes')}`, `${t('no')}`]}
        />
      </ActionDialogHolder>}
    </div>
  );

  function onCancelDelete() {
    if (actionRef && actionRef.current) actionRef.current.closeAction(() => {
      setVisible(false);
      setDelId(null);
    })

  }

  function onConfirmDelete() {
    if (actionRef && actionRef.current) actionRef.current.closeAction(() => {
      if (delId) dispatch(fromStore.deleteMyActiveSurvey(delId));
      setVisible(false);
    })

  }
}



const styles: { [key: string]: React.CSSProperties } = ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: THEME.WHITE,
    width: '100%',
    height: '100%',
    overflow: 'hidden'
    // overflowY: 'auto'
  },
  btn: {
    marginBottom: 20,
  },

  //Decision screen

  action: {
    marginTop: 20,
    paddingLeft: 25,
    paddingRight: 25,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
  },

  decisions: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '86%',
    overflow: 'hidden'
  },
});
