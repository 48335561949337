
import axiosInstance from './interceptor.service';
import axiosPatchInstance from './patch-interceptor.service';
import axiosFileInstance from './file-interceptor.service';
import axios, { AxiosRequestConfig } from 'axios';
import { environment } from '../app.config';
import AppStorage from './storage.service';
import { GroupForm, ProfileSettingsForm, ResetPasswordForm, TokenRegistration } from '../interfaces';


const restService = {

    //FCM Token

    registerFCMToken(doc: TokenRegistration) {
        return axiosInstance.post('notification-tokens', doc);
    },

    removeFCMToken(doc: TokenRegistration) {
        return axiosInstance.delete('notification-tokens/logout', { data: doc })
    },

    //Notifications

    getUserNotifications(doc: { page: number, limit: number }) {
        return axiosInstance.get(`notifications/user?page=${doc?.page}&size=${doc?.limit}`);
    },

    markNotificationAsRead(doc) {
        return axiosPatchInstance.patch(`notifications/${doc.id}`, doc);
    },

    deleteNotification(id: string) {
        return axiosInstance.delete(`notifications/${id}`)
    },

    //Countries
    getCountries() {
        return axiosInstance.get('countries');
    },

    //Auth
    loginRequest(user) {
        return axiosInstance.post('authenticate-mobile', user);
    },

    signUpRequest(userDetails) {
        return axiosInstance.post('register', userDetails);
    },

    ssoLogin(userDetails) {
        return axiosInstance.post('sso-login', userDetails);
    },

    changePassword(userId, userPasswordDetails) {
        return axiosInstance.put('auth/change-password/' + userId, userPasswordDetails);
    },

    forgotPassword(user) {
        return axiosInstance.post('forgot-password/request', user);
    },

    contactUs(doc) {
        return axiosInstance.post('contact-us', doc);
    },

    // logout() {
    //     return axiosInstance.get('logout');
    // },

    initiateResetPassword(email: string) {
        return axiosInstance.post('account/reset-password/init', email);
    },

    finishResetPassword(doc: ResetPasswordForm) {
        return axiosInstance.post('account/reset-password/finish', doc);
    },

    activateAccount(key: string) {
        return axiosInstance.get(`activate?key=${key}`);
    },

    //Account 

    uploadProfilePicture(doc) {
        return axiosFileInstance.post('profile-picture', doc);
    },



    getUserDetails() {
        return axiosInstance.get('account/user');
    },

    updateUserProfile(id: string, doc) {
        return axiosInstance.put(`account/user/${id}`, doc);
    },

    updateProfileSettings(doc: ProfileSettingsForm) {
        return axiosPatchInstance.patch(`account/user/${doc.id}/profile-settings`, doc);
    },

    updateCountry(id: string, doc) {
        return axiosPatchInstance.patch(`account/${id}/country`, doc);
    },




    //Categories
    getAllCategories(doc: { page: number, limit: number }) {
        return axiosInstance.get(`categories?page=${doc?.page}&size=${doc?.limit}`);
    },

    //Question/survey

    createQuestion(doc) {
        return axiosInstance.post('notification/survey', doc);
    },

    getMyQuestions(doc: { page: number, limit: number }) {
        return axiosInstance.get(`questions/user?page=${doc?.page}&size=${doc?.limit}`);
    },

    getMyActiveQuestions(doc: { page: number, limit: number }) {
        return axiosInstance.get(`questions/user/active?page=${doc?.page}&size=${doc?.limit}`);
    },

    getMyCompletedQuestions(doc: { page: number, limit: number }) {
        return axiosInstance.get(`questions/user?page=${doc?.page}&size=${doc?.limit}`);
    },

    getQuestionById(id) {
        return axiosInstance.get('questions/' + id);
    },

    getInvitedQuestion(key) {
        return axiosInstance.get(`shares/invite?inviteKey=${key}`)
    },

    getOthersQuestions() {
        return axiosInstance.get('questions?page=0&size=100');
    },

    postMyQuestionToGlobal(doc) {
        return axiosInstance.post('questions', doc);
    },

    shareMyQuestion(doc) {
        return axiosInstance.post('shares', doc);
    },

    getSharedQuestions(doc: { page: number, limit: number }) {
        return axiosInstance.get(`shares/help-others?page=${doc?.page}&size=${doc?.limit}`);
    },

    updateQuestion(doc: any) {
        return axiosInstance.put(`questions/${doc.id}`, doc);
    },

    updateQuestionWithPatch(doc: any) {
        return axiosPatchInstance.patch(`questions/${doc.id}`, doc);
    },

    uploadQuestionImage(doc) {
        return axiosFileInstance.post('questions/image', doc);
    },

    uploadOptionImage(doc) {
        return axiosFileInstance.post('options/image', doc);
    },

    deleteQuestion(id: string) {
        return axiosInstance.delete(`questions/${id}`);
    },

    //Drafts
    getDrafts(doc: { page: number, limit: number }) {
        return axiosInstance.get(`questions/drafts?page=${doc?.page}&size=${doc?.limit}`);
    },

    postDraft(doc: any) {
        return axiosInstance.post('questions/drafts', doc);
    },

    updateDraft(doc: any) {
        return axiosInstance.put(`questions/drafts/${doc.id}`, doc);
    },

    //Comments
    getComments(id: string) {
        return axiosInstance.get(`comments/question/${id}`);
    },

    postComment(doc) {
        return axiosInstance.post('comments', doc);
    },

    uploadCommentMedia(doc) {
        return axiosFileInstance.post('comments/video', doc);
    },

    deleteComment(id: string) {
        return axiosInstance.delete(`comments/${id}`);
    },


    //Answers
    postMyAnswer(doc) {
        return axiosInstance.post('answers', doc);
    },
    updateMyAnswer(doc: any) {
        return axiosInstance.put(`answers/${doc.id}`, doc);
    },
    getAllSurveyAnswers(id: string) {
        return axiosInstance.get(`answers/question/${id}`)
    },
    getMySurveyAnswer(id: string) {
        return axiosInstance.get(`answers/user?questionId=${id}`);
    },




    //Contacts
    getUserContacts() {
        return axiosInstance.get('friends/mine');
    },

    getGlobalContacts(doc: { page: number, limit: number }) {
        return axiosInstance.get(`users?page=${doc?.page}&size=${doc?.limit}`);
    },

    //Invite friends

    requestInviteLink() {
        return axiosInstance.post('user-invites/create');
    },

    getUserInviteLink() {
        return axiosInstance.get('user-invites/mine');
    },

    acceptInvitationRequest(key: string) {
        return axiosInstance.get('user-invites/invite?inviteKey=' + key)
    },

    //Groups
    createNewGroup(doc: GroupForm) {
        return axiosInstance.post('groups', doc);
    },

    getMyGroups() {
        return axiosInstance.get('groups/mine');
    },

    getGroupById(id: string) {
        return axiosInstance.get(`groups/mine/${id}`);
    },

    uploadGroupImage(doc) {
        return axiosFileInstance.post('groups/image', doc);
    },

    deleteGroup(id: string) {
        return axiosInstance.delete(`groups/${id}`);
    },

    //Friends
    getAllAppUsers(doc: { page: number, limit: number }) {
        return axiosInstance.get(`app-users?page=${doc?.page}&size=${doc?.limit}`);
    },

    searchFriends(value: string) {
        return axiosInstance.get(`users/search?keyword=${value}`);
    },

    searchQuestions(value: string) {
        return axiosInstance.get(`questions/search?keyword=${value}`);
    },

    getAllFriendRequests() {
        return axiosInstance.get('friend-requests/received');
    },

    getAllSentFriendRequests() {
        return axiosInstance.get('friend-requests/sent');
    },

    addUserAsFriend(doc) {
        return axiosInstance.post('friend-request', doc);
    },

    acceptFriendRequest(id: string) {
        return axiosPatchInstance.patch(`friend-request/${id}/accept`)
    },

    rejectFriendRequest(id: string) {
        return axiosPatchInstance.patch(`friend-request/${id}/decline`)
    },


}

export default restService;