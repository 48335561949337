import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CustomButton, ScreenHolder } from "../components";
import { THEME, FONT } from "../enums";
import { LOGIN_SIGNUP_ROUTES } from "../routes";
import { FormInput } from "../utils";
import CustomText from "../utils/custom-text";
import { EMAIL_PATTERN } from "../variables";
import * as fromStore from '../store';
import * as AuthService from '../services/auth.service';
import { extractError } from "../services";

const EMAIL = {
    field: 'email',
    label: '',
    secure: false,
    type: 'emailAddress',
    placeholder: 'placeholder.enterYourRegisteredEmail',
    pattern: EMAIL_PATTERN

}

export function ForgotPasswordScreen(props: any) {
    const { history, location } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [email, setEmail] = useState<string>('');
    const { loading, error, country } = useSelector((state) => fromStore.selectAuthState(state));


    function handleFieldChange(fieldId, value) {
        setEmail(value);
    }

    function _isFormValid() {
        const pattern = EMAIL_PATTERN;

        if (email == '') return false;
        if (pattern.test(email) == false) return false;
        return true;
    }

    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <CustomText customStyle={styles.headerText}>{t('forgotPassword')}</CustomText>
            </div>
            <div style={styles.infoContainer}>
                <CustomText customStyle={styles.subInfoText}>{t('forgotPasswordResetMessage')}</CustomText>
            </div>
            <div style={styles.inputHolder}>
                <FormInput
                    key={EMAIL.field}
                    id={EMAIL.field}
                    onChange={handleFieldChange}
                    secure={EMAIL.secure}
                    placeholder={t(EMAIL.placeholder)}
                    type={EMAIL.type}
                    value={email}
                    pattern={EMAIL.pattern}
                    label={EMAIL.label}
                />
            </div>
            <div style={styles.action}>
                <CustomButton
                    name={t('next')}
                    enable={_isFormValid()}
                    loading={loading}
                    onClick={() => requestResetEmail()}
                />
            </div>
        </div>
    )


    async function requestResetEmail() {
        try {
            dispatch(fromStore.resetUserPassword(email));
            const response = await AuthService.resetPassword(email);
            dispatch(fromStore.resetUserPasswordSuccess(response));
            dispatch(fromStore.toastMessage(t('emailWithResetPasswordSent')));
            history.push({pathname: LOGIN_SIGNUP_ROUTES.FORGOT_PASSWORD_MESSAGE, state: location.state});
        } catch (error: any) {
            dispatch(fromStore.resetUserPasswordError(extractError(error, LOGIN_SIGNUP_ROUTES.FORGOT_PASSWORD)))
        }
    }
}

const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: THEME.WHITE,
        position: 'relative',
        width: '100%',
        height: '100%',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        boxSizing: 'border-box',
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 10,
        paddingRight: 10
    },
    headerText: {
        fontSize: 17,
        color: THEME.BLACK
    },
    infoContainer: {
        display: 'flex',
        justifyContent: "center",
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 25,
        marginBottom: 25,
        width: '100%',
        boxSizing: 'border-box'
    },
    subInfoText: {
        fontSize: 13,
        // textAlign: 'auto',
        // fontFamily: FONT.MANROPE_REGULAR,
        color: THEME.BLACK,
    },
    inputHolder: {
        paddingLeft: 20,
        paddingRight: 20,
    },
    action: {
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 30,
    },
})