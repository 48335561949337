import React from 'react';
import { MAIN_APP_ROUTES } from '../routes';
import { AppNotification, FCMNotification, FCMNotificationData } from '../interfaces';
import restService from './rest.service';

/*------------- FCM register --------------------*/

export const registerFcmToken = (doc) => {
    return restService.registerFCMToken(doc).then(response => response.data);
}

export const removeFcmToken = (doc) => {
    return restService.removeFCMToken(doc).then(response => response.data);
}


export function getUserNotifications(doc: { page: number, limit: number }) {
    return restService.getUserNotifications(doc).then(response => {
        return {
            ...doc,
            countAll: parseInt(response.headers["x-total-count"]),
            result: response.data
        };
    });
}

export function markNotificationAsRead(doc) {
    return restService.markNotificationAsRead(doc).then(response => response.data);
}

export function deleteNotification(id: string) {
    return restService.deleteNotification(id).then(response => response.data);
}

export function converFCMNotifaction(doc: FCMNotification): AppNotification {
    return {
        id: doc.data.NOTIFICATION_ID,
        title: doc.notification.title,
        message: doc.notification.body,
        markAsRead: false,
        data: doc.data,
        user: {
            id: null,
            login: null,
            firstName: null,
            lastName: null,
            email: null,
        },
        createdDate: new Date().toISOString(),
        lastModifiedDate: new Date().toISOString(),
        type: "DEFAULT",
    }
}



/*--------------Navigate to Screen ----------------------- */


export function navigateToAppScreen(data: any): string {
    switch (data?.GO_TO_SCREEN) {
        case MAIN_APP_ROUTES.HOME: return '/';
        case MAIN_APP_ROUTES.CONTACT_US: return MAIN_APP_ROUTES.CONTACT_US;
        case MAIN_APP_ROUTES.PRIVACY_POLICY: return MAIN_APP_ROUTES.PRIVACY_POLICY;
        case MAIN_APP_ROUTES.EDIT_PROFILE: return MAIN_APP_ROUTES.EDIT_PROFILE;
        case MAIN_APP_ROUTES.VIDEO_PLAYER: return MAIN_APP_ROUTES.VIDEO_PLAYER;
        case MAIN_APP_ROUTES.YOUR_HELP_MATTERS: return MAIN_APP_ROUTES.ACTIVE_SURVEYS_LIST;
        case MAIN_APP_ROUTES.ACTIVE_SURVEYS_LIST: return MAIN_APP_ROUTES.ACTIVE_SURVEYS_LIST;
        case MAIN_APP_ROUTES.COMPLETED_SURVEYS_LIST: return MAIN_APP_ROUTES.COMPLETED_SURVEYS_LIST
        case MAIN_APP_ROUTES.SURVEY: return `${MAIN_APP_ROUTES.SURVEY}/${data?.QUESTION_ID}`;
        case MAIN_APP_ROUTES.INVITED_SURVEY: return `${MAIN_APP_ROUTES.INVITED_SURVEY}/${data?.QUESTION_ID}`;
        case MAIN_APP_ROUTES.NOTIFICATIONS: return MAIN_APP_ROUTES.NOTIFICATIONS;
        case MAIN_APP_ROUTES.SETTINGS: return MAIN_APP_ROUTES.SETTINGS;
        case MAIN_APP_ROUTES.MENU: return MAIN_APP_ROUTES.MENU;
        case MAIN_APP_ROUTES.GROUPS: return MAIN_APP_ROUTES.GROUPS;
        case MAIN_APP_ROUTES.CREATE_GROUP: return MAIN_APP_ROUTES.CREATE_GROUP;
        case MAIN_APP_ROUTES.EDIT_GROUP: return `${MAIN_APP_ROUTES.EDIT_GROUP}/${data?.param}`;
        case MAIN_APP_ROUTES.FRIENDS: return `${MAIN_APP_ROUTES.MY_FRIENDS}/${data?.param}`;
        case MAIN_APP_ROUTES.MY_FRIENDS: return `${MAIN_APP_ROUTES.MY_FRIENDS}/${data?.param}`;
        case MAIN_APP_ROUTES.RECIEVED: return MAIN_APP_ROUTES.RECIEVED;
        case MAIN_APP_ROUTES.SENT:  return MAIN_APP_ROUTES.SENT;
        case MAIN_APP_ROUTES.ADD_FRIENDS:  return MAIN_APP_ROUTES.ADD_FRIENDS;
        case MAIN_APP_ROUTES.INVITE_FRIENDS: return MAIN_APP_ROUTES.INVITE_FRIENDS;
        case MAIN_APP_ROUTES.SEARCH: return MAIN_APP_ROUTES.SEARCH;
        case MAIN_APP_ROUTES.QUESTION_BANK: return MAIN_APP_ROUTES.QUESTION_BANK;
        case MAIN_APP_ROUTES.SURVEY_RESULTS: return `${MAIN_APP_ROUTES.SURVEY_RESULTS}/${data?.QUESTION_ID}`;
        case MAIN_APP_ROUTES.DRAFTS: return MAIN_APP_ROUTES.DRAFTS;
        case MAIN_APP_ROUTES.PUBLIC_CHOICE: return MAIN_APP_ROUTES.PUBLIC_CHOICE;
        case MAIN_APP_ROUTES.CREATE_QUESTION: return MAIN_APP_ROUTES.CREATE_QUESTION;
        case MAIN_APP_ROUTES.MY_SURVEYS: return MAIN_APP_ROUTES.MY_SURVEYS;
        case MAIN_APP_ROUTES.SINGLE_OPTION: return `${MAIN_APP_ROUTES.SINGLE_OPTION}/${data?.DRAFT_ID}`;
        case MAIN_APP_ROUTES.MULTIPLE_CHOICE: return `${MAIN_APP_ROUTES.MULTIPLE_CHOICE}/${data?.DRAFT_ID}`;
        case MAIN_APP_ROUTES.SHORT_ANSWER: return `${MAIN_APP_ROUTES.SHORT_ANSWER}/${data?.DRAFT_ID}`;
        default: return MAIN_APP_ROUTES.MAIN_APP;
    }
}