import React, { PureComponent, useEffect, useState, useRef, createRef } from 'react';
import { CURRENT_OS } from '../variables';
import { DEVICE_TYPE, THEME } from '../enums';
import CustomText from '../utils/custom-text';
import { RefreshWithError } from '../utils/refresh.screen';
// import Slider from '@react-native-community/slider';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Slider } from '@material-ui/core';
import { AiOutlineArrowLeft, AiOutlinePause, AiOutlinePlayCircle } from 'react-icons/ai';


const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#000',
        height: '100%',
        width: '100%'

    },
    playerFullsecreen: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "#000",
    }
})


export function VideoPlayerScreen(props) {
    const { history, location } = props;
    const { t, i18n } = useTranslation();
    const { uri } = location.state;
    const videoRef = useRef<HTMLVideoElement>(null);
    const [state, setState] = useState({
        userTap: false,
        thumbnailUrl: '',
        videoUrl: uri,
        video: null,
        error: null,
        loading: false,
        isBuffering: true,

        //video controls
        fullscreen: false,
        play: true,
        currentTime: 0,
        duration: 0,
        showControls: true,
    })

    // handleOrientation = (orientation) => {
    //     orientation === 'LANDSCAPE-LEFT' || orientation === 'LANDSCAPE-RIGHT'
    //         ? (setState({ fullscreen: true }), StatusBar.setHidden(true))
    //         : (setState({ fullscreen: false }), StatusBar.setHidden(false));
    // }

    // handleFullscreen = () => {
    //     state.fullscreen
    //         ? Orientation.unlockAllOrientations()
    //         : Orientation.lockToLandscapeLeft();
    // }

    const handlePlayPause = (time?: number) => {

        // if (time) {
        //     if(videoRef.current)videoRef.current.seek(time);
        //     setState({ ...state, currentTime: time });
        //     return;
        // }
        if (!time) setState({ ...state, play: !state.play });

    }

    const skipBackward = () => {
        if (videoRef.current) videoRef.current.currentTime = (state.currentTime - 15);
        setState({ ...state, currentTime: state.currentTime - 15 });
    }

    const skipForward = () => {
        if (videoRef.current) videoRef.current.currentTime = (state.currentTime + 15);
        setState({ ...state, currentTime: state.currentTime + 15 });
    }

    const onSeek = (event: React.ChangeEvent<{}>, value: any) => {
        if (videoRef.current) videoRef.current.currentTime = value;
        setState({ ...state, currentTime: value });
    }

    const onReadyForDisplay = (data) => {
        setState({ ...state, isBuffering: false })
    }

    const onLoadEnd = (data) => {
        setState({
            ...state,
            duration: data.duration,
            currentTime: data.currentTime,
        });
    }

    const onProgress = (data) => {
        setState({
            ...state,
            currentTime: data.currentTime,
        });
    }

    const onBuffer = (data) => {
        setState({ ...state, isBuffering: data.isBuffering })
    }

    const onVideoSeek = (event) => {
        // ignore
    }

    const onEnd = (event) => {
        setState({ ...state, play: false });
        if (videoRef.current) videoRef.current.currentTime = 0;
    }

    const onError = (error) => {
        setState({ ...state, error: error })
    }

    const showControls = () => {
        setState({ ...state, showControls: !state.showControls })
    }

    if (state.loading) {
        return (
            <div style={{ ...styles.container, ...{ alignItems: 'center', justifyContent: 'center' } }}>
                <CircularProgress size={40} />
            </div>
        )
    }

    if (state.error) {
        return (
            <div style={{ display: 'flex', backgroundColor: '#000', height: '100%' }}>
                <div
                    style={styles.container}>
                    <RefreshWithError
                        onRefresh={() => {
                            //book error refresh
                            setState({ ...state, loading: true, error: null });
                            // fetchVideo(id);
                        }}
                        textColor={THEME.WHITE}
                        color={THEME.WHITE}
                        fontSize={16}
                        text={t('videoError')}
                    />
                    <TopActionBar history={history} />
                </div>
            </div>
        )
    }

    return (
        <div style={{ display: 'flex', backgroundColor: '#000', height: '100%', width: '100%' }}>
            <div style={styles.container}>
                <div
                    style={{ width: '100%', height: '100%', position: 'relative' }}
                    onMouseOver={(event)=>{setState({ ...state, showControls: true })}}
                    onMouseLeave={(event)=>{setState({ ...state, showControls: false })}}
                >
                    <video src={state.videoUrl}   // Can be a URL or a local file. { uri: state.videoUrl }
                        ref={videoRef}
                        style={styles.playerFullsecreen}
                        width={'100%'}
                        height={'100%'}
                        // fullScreen={true}
                        // ignoreSilentSwitch={"ignore"}
                        // posterResizeMode={"stretch"}
                        controls={true}
                        autoPlay={true}
                        // resizeMode={'contain'}
                        // paused={!state.play}
                        // bufferConfig={{
                        //     minBufferMs: 15000,
                        //     maxBufferMs: 50000,
                        //     bufferForPlaybackMs: 2500,
                        //     bufferForPlaybackAfterRebufferMs: 5000
                        // }}
                        // onReadyForDisplay={onReadyForDisplay}
                        onLoad={onLoadEnd}
                        onProgress={onProgress}
                        // onEnd={onEnd}
                        onError={onError}
                    // onBuffer={onBuffer}
                    // onSeek={onVideoSeek}
                    // onPlaybackStalled={(evnt) => {
                    //     setState({ ...state, isBuffering: true });
                    // }}
                    // onPlaybackResume={(evnt) => {
                    //     setState({ ...state, isBuffering: false });
                    // }}
                    />
                    {state.showControls && (<TopActionBar history={history} />)}
                </div>
                
                {/* {state.isBuffering && (<BufferIndicator />)}
                {state.showControls && (<BottomActionBar
                    onPlay={handlePlayPause}
                    playing={state.play}
                    currentTime={state.currentTime}
                    duration={state.duration > 0 ? state.duration : 0}
                    onValueChange={onSeek}
                    onSlideStart={handlePlayPause}
                    onSlideComplete={handlePlayPause}
                />)} */}
            </div>
        </div>
    )
}




function BufferIndicator({ }) {

    return (
        <div style={{
            display: 'flex',
            width: '100%',
            height: '100%',

            position: 'absolute',
            backgroundColor: 'transparent',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1
        }}>
            <CircularProgress size={40} />
        </div>
    )
}


function TopActionBar({ history }) {

    return (
        <div
            style={{
                position: 'absolute',
                backgroundColor: 'transparent',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: 10,
                top: 0,
                left: 0,
                right: 0,
                width: '100%',
                zIndex: 2,
                boxSizing: 'border-box'
            }}>
            <div
                style={{
                    ...{
                        marginLeft: 10,
                        padding: 5,
                        borderRadius: 50,
                        backgroundColor: 'rgba(0,0,0,0.6)',
                        cursor: 'pointer'
                    }
                }}
                onClick={() => history.goBack()}
            >
                {/* <Icon name={"arrowleft"} type={"antdesign"} size={25} color={THEME.WHITE} /> */}
                <AiOutlineArrowLeft size={25} color={THEME.WHITE} />
            </div>
            {/* <Pressable style={{ marginRight: 10 }} onClick={() => history.goBack()}>
                <Icon name={"arrowsalt"} type={"antdesign"} size={25} color={THEME.WHITE} />
            </Pressable> */}
        </div>
    )
}

interface BottomActionBarProps {
    onPlay: () => void,
    playing: boolean,
    currentTime: any,
    duration: any,
    onValueChange: (event: React.ChangeEvent<{}>, value: number | number[]) => void,
    onSlideStart: (time: number) => void,
    onSlideComplete: (time: number) => void,
}
function BottomActionBar(props: BottomActionBarProps) {

    const {
        onPlay,
        playing,
        currentTime,
        duration,
        onValueChange,
        onSlideStart,
        onSlideComplete,
    } = props;


    const position = getMinutesFromSeconds(currentTime);
    const fullDuration = getMinutesFromSeconds(duration);

    return (
        <div
            style={{
                position: 'absolute',
                backgroundColor: 'rgba(0,0,0,0.3)',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                padding: 10,
                bottom: 0,
                left: 0,
                right: 0,
                paddingBottom: 30,
                width: '100%',
                zIndex: 2,
                boxSizing: 'border-box'
            }}
        >
            <div style={stylesProgress.wrapper}>
                <Slider
                    value={currentTime}
                    aria-labelledby="continuous-slider"
                    min={0}
                    max={duration}
                    step={0}
                    onChange={onValueChange}

                // onSlidingStart={onSlideStart}
                // onSlidingComplete={onSlideComplete}
                // minimumTrackTintColor={THEME.ORANGE_RED}
                // maximumTrackTintColor={"rgba(255,255,255,0.5)"}
                // thumbTintColor={THEME.WHITE}
                />
                <div style={stylesProgress.timeWrapper}>
                    <div
                        style={{
                            ...stylesProgress.payIconStyle
                        }}
                        onClick={() => onPlay()}
                    >
                        {/* <Icon name={playing ? "pause" : "play"} type={"foundation"} size={30} color={THEME.WHITE} /> */}
                        {playing ? <AiOutlinePause size={30} color={THEME.WHITE} /> : <AiOutlinePlayCircle size={30} color={THEME.WHITE} />}

                    </div>
                    <CustomText customStyle={stylesProgress.timeRight}>{`${position} / ${fullDuration}`}</CustomText>
                </div>
            </div>
        </div>

    );

    function getMinutesFromSeconds(time) {
        const minutes = time >= 60 ? Math.floor(time / 60) : 0;
        const seconds = Math.floor(time - minutes * 60);

        return `${minutes >= 10 ? minutes : '0' + minutes}:${seconds >= 10 ? seconds : '0' + seconds
            }`;
    }

    // function handleOnSlide(time) {
    //     onSlideCapture({ seekTime: time });
    // }
}


const stylesProgress: { [key: string]: React.CSSProperties } = ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        boxSizing: 'border-box'
    },
    timeWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        boxSizing: 'border-box'
        // paddingHorizontal: 5,
    },
    timeLeft: {
        display: 'flex',
        fontSize: 16,
        color: THEME.WHITE,
        paddingLeft: 10,
    },
    timeRight: {
        display: 'flex',
        fontSize: 16,
        color: THEME.WHITE,
        textAlign: 'right',
        paddingRight: 10,
    },

    payIconStyle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 10,
        padding: 5, borderRadius: 50,
        backgroundColor: 'rgba(0,0,0,0.6)',
        width: 40,
        height: 40
    }
});