import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { SearchBar, CustomButton } from "../components";
import { DEVICE_TYPE, FONT, THEME } from "../enums";
import { FriendRequest, GlobalContactInfo } from "../interfaces";
import CustomText from "../utils/custom-text";
import { currentWindowHeight, currentWindowWidth, CURRENT_OS, } from "../variables";
import * as fromStore from "../store";
import { ImageWithSpinner, RefreshWithError } from "../utils";
import { checkErrorResponse, getUserName } from "../services";
import * as ContactsService from '../services/contacts.service';
import { SpinnerScreen } from "../utils/spinner.screen";
import { ContactIcon } from "../icons";
import { MdArrowDropDown } from "react-icons/md";
// import { TabView, TabBar, SceneMap } from 'react-native-tab-view';

const actionSize = currentWindowWidth() / 3;
const halfSize = currentWindowHeight() / 2;
const contactsWindowHeight = halfSize + halfSize / 2 - 10;

export function FriendsRecievedScreen(props: any) {
    const { history, location, match } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [search, setSearch] = useState<string>('');
    const [onSearch, setOnSearch] = useState<boolean>(false);

    const friendRquests = useSelector((state) => fromStore.selectFriendRequests(state));
    const { error, loading } = useSelector((state) => fromStore.selectFriendRequestsState(state));
    const acceptState = useSelector((state) => fromStore.selectAcceptFriendState(state));
    const rejectState = useSelector((state) => fromStore.selectRejectFriendState(state));

    useEffect(() => {
        getContactsInfo()
        return () => {
            dispatch(fromStore.removeFriendRequests());
        }
    }, []);

    function getContactsInfo() {
        dispatch(fromStore.getFriendRequests());
    }

    function filterUsers() {
        return friendRquests
            .filter(doc => {
                return doc.requestedUser.email && doc?.requestedUser.email.toLowerCase().includes(search?.toLowerCase()) ||
                    getUserName(doc.requestedUser) && getUserName(doc.requestedUser)?.toLowerCase().replace(/ /g, '').includes(search?.toLowerCase().replace(/ /g, ''));
            })
    }




    if (friendRquests.length === 0 && loading) {
        return (
            <div style={styles.refreshError}>
                <SpinnerScreen />
            </div>
        )
    }

    if (friendRquests && friendRquests.length === 0 && !error) {
        return (
            <div style={styles.refreshError}>
                <RefreshWithError text={t('noNewFriendRequestsReceived')} onRefresh={getContactsInfo} />
            </div>
        )
    }

    if (error && friendRquests && friendRquests.length === 0) {
        return (
            <div style={styles.refreshError}>
                <RefreshWithError text={checkErrorResponse(error)} onRefresh={getContactsInfo} />
            </div>
        )
    }

    return (
        <div style={styles.screenContent}>
            <div style={{ width: '100%' }}>
                <SearchBar
                    id={"search"}
                    type={"none"}
                    value={search}
                    placeholder={t("searchPeople")}
                    placeholderTextColor={THEME.PLACEHOLDER}
                    onChange={(id, value) => setSearch(value)}
                    onFocus={(event) => { setOnSearch(true) }}
                    onBlur={(event) => { setOnSearch(false) }}
                />
            </div>
            <div style={{ marginTop: 10, marginLeft: 10 }}>
                {/* t('suggestedPeople') */}
                <CustomText customStyle={{ opacity: 0.7 }}>{''}</CustomText>
            </div>
            <div style={styles.listHolder}>
                {filterUsers().map((item, index) => (
                    <FriendRequestItem
                        key={item.id}
                        item={item}
                        acceptState={acceptState}
                        rejectState={rejectState}
                        onAccept={onRequestAccept}
                        onReject={onRequestReject}
                    />
                ))}

            </div>
        </div>
    );


    function onRequestAccept(doc: FriendRequest) {
        dispatch(fromStore.acceptFriend(doc.id));
    }

    function onRequestReject(doc: FriendRequest) {
        dispatch(fromStore.rejectFriend(doc.id));
    }

}



interface FriendRequestItemProps {
    item: FriendRequest,
    acceptState?: any,
    rejectState?: any,
    onAccept: (doc: FriendRequest) => void,
    onReject: (doc: FriendRequest) => void,
}

export function FriendRequestItem(props: FriendRequestItemProps) {
    const {
        item,
        acceptState,
        rejectState,
        onAccept,
        onReject,
    } = props;
    const { t, i18n } = useTranslation();

    return (
        <div style={{ ...styles.contactPressable, ...{ display: 'flex', flexDirection: 'column' } }}>

            <div style={styles.contactContainer}>
                <div style={styles.contactInfo}>
                    <div style={styles.contactIcon}>
                        <ImageWithSpinner
                            uri={item?.requestedUser.profilePicUrl}
                            customStyle={{
                                height: "100%",
                                width: "100%",
                                backgroundColor: "transparent",
                                borderRadius: 25,
                                objectFit: 'cover'
                            }}
                            imgStyle={{
                                height: "100%",
                                width: "100%",
                                backgroundColor: "transparent",
                                borderRadius: 25,
                                objectFit: 'cover'
                            }}
                            spinnerSize={15}
                        />
                    </div>
                    <div style={{ width: '75%' }}>
                        <CustomText customStyle={{ fontSize: 14 }} ellipsizeMode={"tail"} numberOfLines={2}>{getUserName(item.requestedUser)}</CustomText>
                    </div>
                </div>
                <div style={{ marginRight: 15 }}>
                    {/* <Icon name={"chevron-down-outline"} type={"ionicon"} size={20} /> */}
                    <MdArrowDropDown size={20} />
                </div>

            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                padding: 10
            }}>
                <div style={{ width: '30%', marginRight: 15 }}>
                    <CustomButton
                        customStyle={{}}
                        textStyle={{ textTransform: 'uppercase' }}
                        padding={10}
                        loading={acceptState.id === item.id ? acceptState.loading : false}
                        name={t("accept")}
                        enable={rejectState.id === item.id ? !rejectState.loading : true}
                        onClick={() => onAccept(item)}

                    />
                </div>
                <div style={{ width: '30%' }}>
                    <CustomButton
                        customStyle={{ backgroundColor: 'red' }}
                        textStyle={{ textTransform: 'uppercase' }}
                        padding={10}
                        loading={rejectState.id === item.id ? rejectState.loading : false}
                        name={t("reject")}
                        enable={acceptState.id === item.id ? !acceptState.loading : true}
                        onClick={() => onReject(item)}
                        backgroundColor={'red'}
                    />
                </div>
            </div>

        </div>
    )
}

const styles: { [key: string]: React.CSSProperties } = ({
    // Screen container styles
    screenContent: {
        width: '100%',
        height: '100%',
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: THEME.WHITE,
        boxSizing: 'border-box'
    },

    listHolder: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
        marginLeft: 10,
        height: '85%',
        overflowY: 'auto'
    },

    contactPressable: {
        paddingTop: 10, paddingBottom: 10, borderBottomWidth: 1, borderBottomColor: THEME.INPUT_BORDER, borderBottomStyle: 'solid'
    },
    contactContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    contactInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '65%',

    },
    contactIcon: {
        width: 45, height: 45, borderRadius: 25, marginRight: 20
    },
    indicator: {
        width: 24,
        height: 24,
        padding: 2,
        borderWidth: 1,
        borderColor: THEME.INPUT_BORDER,
        borderStyle: 'solid',
        borderRadius: 12,
    },
    refreshError: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        backgroundColor: THEME.WHITE
    }
})