import React, { PureComponent, useEffect, useRef, useState } from 'react';
// import { TextInput, View, Keyboard, Text, StyleSheet, div, Image, FlatList } from 'react-native';
import { useTranslation } from 'react-i18next';
import CustomText from '../utils/custom-text';
import { APP_THEME_COLOR, currentWindowWidth, CURRENT_OS } from '../variables';
import { DEVICE_TYPE, FONT, THEME } from '../enums';
import { IconProvider } from './icon-provider';
import '../scss/form-selector.scss';
/* ----------------- FormSelector --------------------- */

interface FormSelectorProps {
    lable?: string,
    placeholder: any,
    selected: any,
    fieldStyles?: any,
    multiline?: boolean,
    numberOfLines?: number,
    translate?: boolean,
    required?: boolean,
    onClick: () => void,
}

export function FormSelector(props: FormSelectorProps) {
    const { lable, placeholder, selected, fieldStyles, translate, multiline, numberOfLines, required, onClick } = props;
    const { t, i18n } = useTranslation();
    return (
        <div className={"form-selector-container"} style={{ ...fieldStyles }}>
            {lable ? <CustomText customStyle={styles.lableHeader}>{lable + (required ? ' *' : '')}</CustomText> : null}
            <div
                className={"input-container"}
                style={{
                    // ...{ backgroundColor: pressed ? 'rgba(230,230,230,0.2)' : THEME.WHITE }
                }}
                onClick={onClick}
            >
                <div style={styles.innervalue}>
                    {selected?.url && <div style={styles.thumbnail}>
                        <img
                            src={selected?.url}
                            style={{ width: "100%", height: "100%", borderRadius: 10, objectFit: 'cover' }}
                        />
                    </div>}
                    <div style={{ marginTop: 15 }}>
                        {
                            selected && selected.name ? <CustomText customStyle={styles.selectorText}>{translate === false ? selected?.name : t(selected?.name)}</CustomText>
                                : (
                                    typeof (placeholder) === "string" ? <CustomText customStyle={styles.selectorPlaceholder}>{placeholder}</CustomText>
                                        : placeholder.map((doc, index) => (
                                            <div key={index} style={{}}>
                                                <CustomText customStyle={styles.selectorPlaceholder}>{doc}</CustomText>
                                            </div>
                                        ))
                                )
                        }

                    </div>
                </div>
                <div style={{
                    marginTop: 20
                }}>
                    <IconProvider type={"down-arr"} />
                </div>

            </div>

        </div>
    )
}

const styles: { [key: string]: React.CSSProperties } = ({

    //selector styles
    container: {
        display: 'flex', flexDirection: 'column',
        width: '100%',
        position: 'relative',
    },
    innercontainer: {
        position: 'relative',
        width: '100%',
        borderRadius: 10,
        borderWidth: 2,
        borderBlockStyle: 'solid',
        borderColor: 'rgb(244,244,244)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 10,
        boxSizing: 'border-box'
    },
    lableHeader: {
        fontSize: 13,
        fontFamily: FONT.MANROPE_REGULAR, //Helvatica
        marginBottom: 10,
        marginTop: 10,
        color: THEME.BLACK
    },
    // label: {
    //     position: 'absolute',
    //     top: -8,
    //     left: 20,
    //     backgroundColor: THEME.WHITE,
    //     paddingLeft: 10,
    //     paddingRight: 10,
    // },
    labelTxt: {
        fontSize: 12,
        fontFamily: FONT.MANROPE_REGULAR
    },
    innervalue: {
        display: 'flex',
        flexDirection: 'row',
    },
    thumbnail: {
        width: 30, height: 25, marginTop: 10, marginRight: 10, borderRadius: 10
    },
    triangle: {
        height: 6,
        width: 10,
        backgroundColor: "transparent",
        borderColor: "transparent",
        // transform: [
        //     {
        //         rotate: "-180.00deg"
        //     }
        // ],

    },

    multiInput: {
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column'
    },
    selectorText: {
        fontSize: 11,
        fontFamily: FONT.MANROPE_REGULAR, //Helvatica
        color: THEME.BLACK
    },

    selectorPlaceholder: {
        fontSize: 11,
        fontFamily: FONT.MANROPE_REGULAR, //Helvatica
        color: THEME.PLACEHOLDER
    },



});