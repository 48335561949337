import React, { Component, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FONT, THEME } from "../enums";
import CustomText from "../utils/custom-text";
import { currentWindowWidth } from "../variables";
interface Props {
    list: any[]
}

const boxWidth = currentWindowWidth() - 50;

export function TextScroller(props: Props) {
    const { list } = props;
    const { t } = useTranslation();
    const [scrollIndex, setScrollIndex] = useState<number>(0);
    // const onViewRef = useRef(({ viewableItems, changed }) => {
    //     setScrollIndex(changed[0].index);
    //     // Use viewable items in state or as intended
    // })
    // const viewConfigRef = useRef({ viewAreaCoveragePercentThreshold: 50 });
    return (
        <div style={styles.subInfo}>
            <div style={{
                width: '100%',
                // height: 100,
                overflowY: 'hidden',
                overflowX: 'auto',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap'
            }}>
                {/* {
                    list.map((item, index) => (
                        <div key={index} style={{ width: '100%' }}>
                            <CustomText customStyle={styles.subInfoText}>{`${t(item.info)}`}</CustomText>
                        </div>
                    ))
                } */}
                <div key={0} style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <CustomText customStyle={styles.subInfoText}>{`${t(list[0].info)}`}</CustomText>
                </div>
            </div>
            <div style={styles.scroller}>
                {list.map((doc, index) => (
                    <div key={index} style={{ ...styles.indicator, ...{ width: scrollIndex === index ? 20 : 5 } }}></div>
                ))}
            </div>
        </div>
    )
}

const styles: { [key: string]: React.CSSProperties } = ({
    subInfo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        marginTop: 20,
        paddingLeft: 25,
        paddingRight: 25,
    },
    subInfoText: {
        fontSize: 13,
        textAlign: 'center',
        fontFamily: FONT.MANROPE_REGULAR,
        color: THEME.BLACK,

    },
    scroller: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 20
    },
    indicator: {
        width: 5, height: 5,
        borderRadius: 5,
        marginRight: 10,
        backgroundColor: THEME.ORANGE_RED
    }
})
