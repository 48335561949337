import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { THEME } from "../enums"
import { AppError } from "../interfaces";
import { checkErrorResponse } from "../services";
import { RefreshWithError } from "./refresh.screen";
import { SpinnerScreen } from "./spinner.screen";

interface Props {
    customStyle?: any,
    listLength: number,
    countAll: number,
    error?: AppError | null,
    refreshing?: boolean,
    emptyTitle?: string,
    onToast?: (error: AppError) => void,
    fetchMore: () => void,
    onRefresh?: () => void,
    children: any
}


export function CustomScrollView(props: Props) {
    const {
        listLength,
        countAll,
        error,
        refreshing,
        emptyTitle,
        onToast,
        fetchMore,
        onRefresh,
    } = props;
    const [onEnd, setOnEnd] = useState<boolean>(true);
    const { t, i18n } = useTranslation();
    const isCloseToBottom = (nativeEvent) => {
        return Math.ceil(nativeEvent.target.scrollTop) >= Math.ceil(nativeEvent.target.scrollHeight - nativeEvent.target.offsetHeight);
        // const paddingToBottom = 20;
        // return layoutMeasurement.height + contentOffset.y >=
        //     contentSize.height - paddingToBottom;
    };

    useEffect(() => {
        if (error && listLength != 0 && onToast) onToast(error);
        return () => {
            // dispatch(fromStore.clearDecisions());
        }
    }, [error])



    if (listLength === 0 && refreshing) {
        return (
            <div style={styles.refreshError}>
                <SpinnerScreen />
            </div>
        )
    }

    if (listLength === 0 && !error && !refreshing) {
        return (
            <div style={styles.refreshError}>
                <RefreshWithError text={emptyTitle ? emptyTitle.toUpperCase() : t('emptyList')} onRefresh={onRefresh} />
            </div>
        )
    }

    if (error && listLength === 0) {
        return (
            <div style={styles.refreshError}>
                <RefreshWithError text={checkErrorResponse(error)} onRefresh={onRefresh} />
            </div>
        )
    }

    return (
        <div
            style={{ ...styles.container, ...props.customStyle }}
            onScroll={({ nativeEvent }) => {
                setOnEnd(false);
                const isAtBottom = isCloseToBottom(nativeEvent);
                if (isAtBottom) onReachedEnd();
            }}
        >
            {props.children}
            {(props.listLength < props.countAll) && <div style={{ width: '100%', height: 60 }}>
                <SpinnerScreen size={20} />
            </div>}
        </div>
    );

    function onReachedEnd() {
        if (!onEnd) {
            if (listLength < countAll) fetchMore();
            setOnEnd(true);
        }

    }

}


const styles: { [key: string]: React.CSSProperties } = {
    container: {
        width: '100%', height: '100%', overflowY: 'auto'
    },
    refreshError: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%'
    }
}