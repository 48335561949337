import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CONTACT_TYPE, DEVICE_TYPE, THEME } from "../enums";
import { ContactInfo, GroupInfo } from "../interfaces";
import CustomText from "../utils/custom-text";
import { SpinnerScreen } from "../utils/spinner.screen";
import { CURRENT_OS, currentWindowWidth } from "../variables";
import { SearchBar } from "./search-bar";
import * as fromStore from "../store";
import { UserContact } from "./contact";
import { GroupContact } from "./group-contact";
import { CustomCheckBox, IconProvider, RefreshWithError } from "../utils";

interface EveryoneListProps {
    selectedContacts: any[],
    selectedGroups: any[],
    contactsWindowHeight?: any,
    onRefresh: () => void,
    onSelectAll: (contacts: ContactInfo[], groups: GroupInfo[]) => void,
    onDeselectAll: () => void,
    onSelect: (doc: GroupInfo | ContactInfo) => void,
    onDeselect: (doc: GroupInfo | ContactInfo) => void,
}
export function EveryoneList(props: EveryoneListProps) {
    const {
        selectedContacts,
        selectedGroups,
        contactsWindowHeight,
        onSelect,
        onDeselect,
        onRefresh,
        onSelectAll,
        onDeselectAll,
    } = props;
    const [search, setSearch] = useState<string>('');
    const { t, i18n } = useTranslation();
    const contacts = useSelector((state) => fromStore.selectContactsWithType(state));
    const groups = useSelector((state) => fromStore.selectGroupsWithType(state));
    const contactsState = useSelector((state) => fromStore.selectContactsState(state));
    const groupsState = useSelector((state) => fromStore.selectGroupsState(state));
    const everyonList = [...contacts, ...groups];
    const selectedEveryoneList = [...selectedContacts, ...selectedGroups,];

    function filterUsers() {
        return everyonList
            .filter(doc => {
                return doc?.name && doc?.name.toLowerCase().includes(search?.toLowerCase()) ||
                    doc?.name && doc?.name.toLowerCase().includes(search?.toLowerCase())
            })
    }


    return (
        <div style={{ ...styles.container }}>
            <div style={{ width: '100%', boxSizing: 'border-box' }}>
                <SearchBar
                    id={"search"}
                    type={"none"}
                    value={search}
                    placeholder={t("searchPeople")}
                    placeholderTextColor={THEME.PLACEHOLDER}
                    onChange={(id, value) => setSearch(value)}
                />
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 10, marginLeft: 10
            }}>
                {/* t('suggestedPeople') */}
                <CustomText customStyle={{ opacity: 0.7 }}>{''}</CustomText>
                <div style={{}}>
                    <CustomCheckBox
                        iconRight={<IconProvider type={"expand-more"}  title={"select all"} size={25} />}
                        checked={selectedEveryoneList.length === 0 ? false : true}
                        checkedColor={THEME.ORANGE_RED}
                        containerStyle={styles.checkbox}
                        onClick={() => {
                            if (selectedEveryoneList.length === 0) onSelectAll(contacts, groups);
                            else onDeselectAll();
                        }}
                    />
                </div>
                {/* <div
                    style={{}}
                    onClick={() => {
                        if (selectedEveryoneList.length === 0) onSelectAll(contacts, groups);
                        else onDeselectAll();
                    }}
                >

                    <CustomText
                        customStyle={{ opacity: 0.7, color: THEME.BLACK }}
                    >
                        {selectedEveryoneList.length === 0 ? t('selectAll') : t('deselectAll')}
                    </CustomText>

                </div> */}
            </div>
            <div style={{ ...styles.listHolder }}>
                <ItemsList
                    contacts={contacts}
                    groups={groups}
                    contactsState={contactsState}
                    groupsState={groupsState}
                    onRefresh={onRefresh}
                    onSelect={onSelect}
                    onDeselect={onDeselect}
                    filterUsers={filterUsers}
                    selectedContacts={selectedContacts}
                    selectedGroups={selectedGroups}
                />
            </div>

        </div >
    )
}


function ItemsList(props) {
    const { t, i18n } = useTranslation();

    if ((props.contacts.length === 0 || props.groups.length === 0) && (props.contactsState.loading || props.groupsState.loading)) {
        return (
            <SpinnerScreen />
        )
    }

    if (props.contacts.length === 0 && props.groups.length === 0 && (!props.contactsState.error || !props.groupsState.error)) {
        return (
            <RefreshWithError text={t('emptyList')} onRefresh={props.onRefresh} />
        )
    }

    return (
        props.filterUsers().map((item, index) => {

            if (item.type === CONTACT_TYPE.GROUP) return (
                <GroupContact
                    key={item.id}
                    item={item as GroupInfo}
                    isSelected={props.selectedGroups.find(doc => doc.id === item.id) ? true : false}
                    onSelect={props.onSelect}
                    onDeselect={props.onDeselect}
                />
            );
            return (
                <UserContact
                    key={item.id}
                    item={item as ContactInfo}
                    isSelected={props.selectedContacts.find(doc => doc.id === item.id) ? true : false}
                    onSelect={props.onSelect}
                    onDeselect={props.onDeselect}
                />
            )
        })
    )
}



const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex', flexDirection: 'column', height: '100%', boxSizing: 'border-box'
    },
    listHolder: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
        marginLeft: 10,
        height: '75%',
        overflowY: 'auto'
    },
    contactPressable: {
        paddingTop: 10, paddingBottom: 10, borderBottomWidth: 1, borderBottomColor: THEME.INPUT_BORDER
    },
    contactContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    contactInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    contactIcon: {
        width: 45, height: 45, borderRadius: 25, marginRight: 20
    },
    indicator: {
        width: 24,
        height: 24,
        padding: 2,
        borderWidth: 1,
        borderColor: THEME.INPUT_BORDER,
        borderRadius: 12,
    },
})