import React from "react"
import Ad from '../assets/countries/ad.png';
import Ae from '../assets/countries/ae.png';
import Af from '../assets/countries/af.png';
import Ag from '../assets/countries/ag.png';
import Ai from '../assets/countries/ai.png';
import Al from '../assets/countries/al.png';
import Am from '../assets/countries/am.png';
import Ao from '../assets/countries/ao.png';
import Aq from '../assets/countries/aq.png';
import Ar from '../assets/countries/ar.png';
import As from '../assets/countries/as.png';
import At from '../assets/countries/at.png';
import Au from '../assets/countries/au.png';
import Aw from '../assets/countries/aw.png';
import Ax from '../assets/countries/ax.png';
import Az from '../assets/countries/az.png';
import Ba from '../assets/countries/ba.png';
import Bb from '../assets/countries/bb.png';
import Bd from '../assets/countries/bd.png';
import Be from '../assets/countries/be.png';
import Bf from '../assets/countries/bf.png';
import Bg from '../assets/countries/bg.png';
import Bh from '../assets/countries/bh.png';
import Bi from '../assets/countries/bi.png';
import Bj from '../assets/countries/bj.png';
import Bl from '../assets/countries/bl.png';
import Bm from '../assets/countries/bm.png';
import Bn from '../assets/countries/bn.png';
import Bo from '../assets/countries/bo.png';
import Bq from '../assets/countries/bq.png';
import Br from '../assets/countries/br.png';
import Bs from '../assets/countries/bs.png';
import Bt from '../assets/countries/bt.png';
import Bv from '../assets/countries/bv.png';
import Bw from '../assets/countries/bw.png';
import By from '../assets/countries/by.png';
import Bz from '../assets/countries/bz.png';
import Ca from '../assets/countries/ca.png';
import Cc from '../assets/countries/cc.png';
import Cd from '../assets/countries/cd.png';
import Cf from '../assets/countries/cf.png';
import Cg from '../assets/countries/cg.png';
import Ch from '../assets/countries/ch.png';
import Ci from '../assets/countries/ci.png';
import Ck from '../assets/countries/ck.png';
import Cl from '../assets/countries/cl.png';
import Cm from '../assets/countries/cm.png';
import Cn from '../assets/countries/cn.png';
import Co from '../assets/countries/co.png';
import Cr from '../assets/countries/cr.png';
import Cu from '../assets/countries/cu.png';
import Cv from '../assets/countries/cv.png';
import Cw from '../assets/countries/cw.png';
import Cx from '../assets/countries/cx.png';
import Cy from '../assets/countries/cy.png';
import Cz from '../assets/countries/cz.png';
import De from '../assets/countries/de.png';
import Dj from '../assets/countries/dj.png';
import Dk from '../assets/countries/dk.png';
import Dm from '../assets/countries/dm.png';
import Do from '../assets/countries/do.png';
import Dz from '../assets/countries/dz.png';
import Ec from '../assets/countries/ec.png';
import Ee from '../assets/countries/ee.png';
import Eg from '../assets/countries/eg.png';
import Eh from '../assets/countries/eh.png';
import Er from '../assets/countries/er.png';
import Es from '../assets/countries/es.png';
import Et from '../assets/countries/et.png';
import Eu from '../assets/countries/eu.png';
import Fi from '../assets/countries/fi.png';
import Fj from '../assets/countries/fj.png';
import Fk from '../assets/countries/fk.png';
import Fm from '../assets/countries/fm.png';
import Fo from '../assets/countries/fo.png';
import Fr from '../assets/countries/fr.png';
import Ga from '../assets/countries/ga.png';
import Gb from '../assets/countries/gb.png';
import Gd from '../assets/countries/gd.png';
import Ge from '../assets/countries/ge.png';
import Gf from '../assets/countries/gf.png';
import Gg from '../assets/countries/gg.png';
import Gh from '../assets/countries/gh.png';
import Gi from '../assets/countries/gi.png';
import Gl from '../assets/countries/gl.png';
import Gm from '../assets/countries/gm.png';
import Gn from '../assets/countries/gn.png';
import Gp from '../assets/countries/gp.png';
import Gq from '../assets/countries/gq.png';
import Gr from '../assets/countries/gr.png';
import Gs from '../assets/countries/gs.png';
import Gt from '../assets/countries/gt.png';
import Gu from '../assets/countries/gu.png';
import Gw from '../assets/countries/gw.png';
import Gy from '../assets/countries/gy.png';
import Hk from '../assets/countries/hk.png';
import Hm from '../assets/countries/hm.png';
import Hn from '../assets/countries/hn.png';
import Hr from '../assets/countries/hr.png';
import Ht from '../assets/countries/ht.png';
import Hu from '../assets/countries/hu.png';
import Id from '../assets/countries/id.png';
import Ie from '../assets/countries/ie.png';
import Il from '../assets/countries/il.png';
import Im from '../assets/countries/im.png';
import In from '../assets/countries/in.png';
import Io from '../assets/countries/io.png';
import Iq from '../assets/countries/iq.png';
import Ir from '../assets/countries/ir.png';
import Is from '../assets/countries/is.png';
import It from '../assets/countries/it.png';
import je from '../assets/countries/je.png';
import jm from '../assets/countries/jm.png';
import jo from '../assets/countries/jo.png';
import jp from '../assets/countries/jp.png';
import ke from '../assets/countries/ke.png';
import kg from '../assets/countries/kg.png';
import kh from '../assets/countries/kh.png';
import ki from '../assets/countries/ki.png';
import km from '../assets/countries/km.png';
import kn from '../assets/countries/kn.png';
import kp from '../assets/countries/kp.png';
import kr from '../assets/countries/kr.png';
import kw from '../assets/countries/kw.png';
import ky from '../assets/countries/ky.png';
import kz from '../assets/countries/kz.png';
import la from '../assets/countries/la.png';
import lb from '../assets/countries/lb.png';
import lc from '../assets/countries/lc.png';
import li from '../assets/countries/li.png';
import lk from '../assets/countries/lk.png';
import lr from '../assets/countries/lr.png';
import ls from '../assets/countries/ls.png';
import lt from '../assets/countries/lt.png';
import lu from '../assets/countries/lu.png';
import lv from '../assets/countries/lv.png';
import ly from '../assets/countries/ly.png';
import ma from '../assets/countries/ma.png';
import mc from '../assets/countries/mc.png';
import md from '../assets/countries/md.png';
import me from '../assets/countries/me.png';
import mf from '../assets/countries/mf.png';
import mg from '../assets/countries/mg.png';
import mh from '../assets/countries/mh.png';
import mk from '../assets/countries/mk.png';
import ml from '../assets/countries/ml.png';
import mm from '../assets/countries/mm.png';
import mn from '../assets/countries/mn.png';
import mo from '../assets/countries/mo.png';
import mp from '../assets/countries/mp.png';
import mq from '../assets/countries/mq.png';
import mr from '../assets/countries/mr.png';
import ms from '../assets/countries/ms.png';
import mt from '../assets/countries/mt.png';
import mu from '../assets/countries/mu.png';
import mv from '../assets/countries/mv.png';
import mw from '../assets/countries/mw.png';
import mx from '../assets/countries/mx.png';
import my from '../assets/countries/my.png';
import mz from '../assets/countries/mz.png';
import na from '../assets/countries/na.png';
import nc from '../assets/countries/nc.png';
import ne from '../assets/countries/ne.png';
import nf from '../assets/countries/nf.png';
import ng from '../assets/countries/ng.png';
import ni from '../assets/countries/ni.png';
import nl from '../assets/countries/nl.png';
import no from '../assets/countries/no.png';
import np from '../assets/countries/np.png';
import nr from '../assets/countries/nr.png';
import nu from '../assets/countries/nu.png';
import nz from '../assets/countries/nz.png';
import om from '../assets/countries/om.png';
import pa from '../assets/countries/pa.png';
import pe from '../assets/countries/pe.png';
import pf from '../assets/countries/pf.png';
import pg from '../assets/countries/pg.png';
import ph from '../assets/countries/ph.png';
import pk from '../assets/countries/pk.png';
import pl from '../assets/countries/pl.png';
import pm from '../assets/countries/pm.png';
import pn from '../assets/countries/pn.png';
import pr from '../assets/countries/pr.png';
import ps from '../assets/countries/ps.png';
import pt from '../assets/countries/pt.png';
import pw from '../assets/countries/pw.png';
import py from '../assets/countries/py.png';
import qa from '../assets/countries/qa.png';
import re from '../assets/countries/re.png';
import ro from '../assets/countries/ro.png';
import rs from '../assets/countries/rs.png';
import ru from '../assets/countries/ru.png';
import rw from '../assets/countries/rw.png';
import sa from '../assets/countries/sa.png';
import sb from '../assets/countries/sb.png';
import sc from '../assets/countries/sc.png';
import sd from '../assets/countries/sd.png';
import se from '../assets/countries/se.png';
import sg from '../assets/countries/sg.png';
import sh from '../assets/countries/sh.png';
import si from '../assets/countries/si.png';
import sj from '../assets/countries/sj.png';
import sk from '../assets/countries/sk.png';
import sl from '../assets/countries/sl.png';
import sm from '../assets/countries/sm.png';
import sn from '../assets/countries/sn.png';
import so from '../assets/countries/so.png';
import sr from '../assets/countries/sr.png';
import ss from '../assets/countries/ss.png';
import st from '../assets/countries/st.png';
import sv from '../assets/countries/sv.png';
import sx from '../assets/countries/sx.png';
import sy from '../assets/countries/sy.png';
import sz from '../assets/countries/sz.png';
import tc from '../assets/countries/tc.png';
import td from '../assets/countries/td.png';
import tf from '../assets/countries/tf.png';
import tg from '../assets/countries/tg.png';
import th from '../assets/countries/th.png';
import tj from '../assets/countries/tj.png';
import tk from '../assets/countries/tk.png';
import tl from '../assets/countries/tl.png';
import tm from '../assets/countries/tm.png';
import tn from '../assets/countries/tn.png';
import to from '../assets/countries/to.png';
import tr from '../assets/countries/tr.png';
import tt from '../assets/countries/tt.png';
import tv from '../assets/countries/tv.png';
import tw from '../assets/countries/tw.png';
import tz from '../assets/countries/tz.png';
import ua from '../assets/countries/ua.png';
import ug from '../assets/countries/ug.png';
import um from '../assets/countries/um.png';
import us from '../assets/countries/us.png';
import uy from '../assets/countries/uy.png';
import uz from '../assets/countries/uz.png';
import va from '../assets/countries/va.png';
import vc from '../assets/countries/vc.png';
import ve from '../assets/countries/ve.png';
import vg from '../assets/countries/vg.png';
import vi from '../assets/countries/vi.png';
import vn from '../assets/countries/vn.png';
import vu from '../assets/countries/vu.png';
import wf from '../assets/countries/wf.png';
import ws from '../assets/countries/ws.png';
import xk from '../assets/countries/xk.png';
import ye from '../assets/countries/ye.png';
import yt from '../assets/countries/yt.png';
import za from '../assets/countries/za.png';
import zm from '../assets/countries/zm.png';
import zw from '../assets/countries/zw.png';

export const countries = {
    ad: Ad,
    ae: Ae,
    af: Af,
    ag: Ag,
    ai: Ai,
    al: Al,
    am: Am,
    ao: Ao,
    aq: Aq,
    ar: Ar,
    as: As,
    at: At,
    au: Au,
    aw: Aw,
    ax: Ax,
    az: Az,
    ba: Ba,
    bb: Bb,
    bd: Bd,
    be: Be,
    bf: Bf,
    bg: Bg,
    bh: Bh,
    bi: Bi,
    bj: Bj,
    bl: Bl,
    bm: Bm,
    bn: Bn,
    bo: Bo,
    bq: Bq,
    br: Br,
    bs: Bs,
    bt: Bt,
    bv: Bv,
    bw: Bw,
    by: By,
    bz: Bz,
    ca: Ca,
    cc: Cc,
    cd: Cd,
    cf: Cf,
    cg: Cg,
    ch: Ch,
    ci: Ci,
    ck: Ck,
    cl: Cl,
    cm: Cm,
    cn: Cn,
    co: Co,
    cr: Cr,
    cu: Cu,
    cv: Cv,
    cw: Cw,
    cx: Cx,
    cy: Cy,
    cz: Cz,
    de: De,
    dj: Dj,
    dk: Dk,
    dm: Dm,
    do: Do,
    dz: Dz,
    ec: Ec,
    ee: Ee,
    eg: Eg,
    eh: Eh,
    er: Er,
    es: Es,
    et: Et,
    eu: Eu,
    fi: Fi,
    fj: Fj,
    fk: Fk,
    fm: Fm,
    fo: Fo,
    fr: Fr,
    ga: Ga,
    gb: Gb,
    gd: Gd,
    ge: Ge,
    gf: Gf,
    gg: Gg,
    gh: Gh,
    gi: Gi,
    gl: Gl,
    gm: Gm,
    gn: Gn,
    gp: Gp,
    gq: Gq,
    gr: Gr,
    gs: Gs,
    gt: Gt,
    gu: Gu,
    gw: Gw,
    gy: Gy,
    hk: Hk,
    hm: Hm,
    hn: Hn,
    hr: Hr,
    ht: Ht,
    hu: Hu,
    id: Id,
    ie: Ie,
    il: Il,
    im: Im,
    in: In,
    io: Io,
    iq: Iq,
    ir: Ir,
    is: Is,
    it: It,
    je: je,
    jm: jm,
    jo: jo,
    jp: jp,
    ke: ke,
    kg: kg,
    kh: kh,
    ki: ki,
    km: km,
    kn: kn,
    kp: kp,
    kr: kr,
    kw: kw,
    ky: ky,
    kz: kz,
    la: la,
    lb: lb,
    lc: lc,
    li: li,
    lk: lk,
    lr: lr,
    ls: ls,
    lt: lt,
    lu: lu,
    lv: lv,
    ly: ly,
    ma: ma,
    mc: mc,
    md: md,
    me: me,
    mf: mf,
    mg: mg,
    mh: mh,
    mk: mk,
    ml: ml,
    mm: mm,
    mn: mn,
    mo: mo,
    mp: mp,
    mq: mq,
    mr: mr,
    ms: ms,
    mt: mt,
    mu: mu,
    mv: mv,
    mw: mw,
    mx: mx,
    my: my,
    mz: mz,
    na: na,
    nc: nc,
    ne: ne,
    nf: nf,
    ng: ng,
    ni: ni,
    nl: nl,
    no: no,
    np: np,
    nr: nr,
    nu: nu,
    nz: nz,
    om: om,
    pa: pa,
    pe: pe,
    pf: pf,
    pg: pg,
    ph: ph,
    pk: pk,
    pl: pl,
    pm: pm,
    pn: pn,
    pr: pr,
    ps: ps,
    pt: pt,
    pw: pw,
    py: py,
    qa: qa,
    re: re,
    ro: ro,
    rs: rs,
    ru: ru,
    rw: rw,
    sa: sa,
    sb: sb,
    sc: sc,
    sd: sd,
    se: se,
    sg: sg,
    sh: sh,
    si: si,
    sj: sj,
    sk: sk,
    sl: sl,
    sm: sm,
    sn: sn,
    so: so,
    sr: sr,
    ss: ss,
    st: st,
    sv: sv,
    sx: sx,
    sy: sy,
    sz: sz,
    tc: tc,
    td: td,
    tf: tf,
    tg: tg,
    th: th,
    tj: tj,
    tk: tk,
    tl: tl,
    tm: tm,
    tn: tn,
    to: to,
    tr: tr,
    tt: tt,
    tv: tv,
    tw: tw,
    tz: tz,
    ua: ua,
    ug: ug,
    um: um,
    us: us,
    uy: uy,
    uz: uz,
    va: va,
    vc: vc,
    ve: ve,
    vg: vg,
    vi: vi,
    vn: vn,
    vu: vu,
    wf: wf,
    ws: ws,
    xk: xk,
    ye: ye,
    yt: yt,
    za: za,
    zm: zm,
    zw: zw,
}