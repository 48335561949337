import * as fromActions from '../actions';
import { ofType, combineEpics, StateObservable } from 'redux-observable';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
 
import { extractError } from '../../services/error-handler';
import AppStorage from '../../services/storage.service';
import { Observable } from 'rxjs';
import { Action } from '@reduxjs/toolkit';
import * as SurveysService from '../../services/surveys.service';
import { MAIN_APP_ROUTES } from '../../routes';
import * as fromSelectors from '../selectors';

const getMyActiveSurveysEpic = (action$: Observable<Action>, state$: StateObservable<any>): Observable<Action> => action$.pipe(
    ofType(fromActions.getMyActiveSurveys),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const myActiveSurveyState = fromSelectors.selectMyActiveSurveysState(state);
        return SurveysService.getMyActiveSurveys({ page: 0, limit: myActiveSurveyState.limit })
            .then(response => fromActions.getMyActiveSurveysSuccess(response))
            .catch(error => fromActions.getMyActiveSurveysError(extractError(error, MAIN_APP_ROUTES.MY_SURVEYS)))
    })
);

const getMoreMyActiveSurveysEpic = (action$: Observable<Action>, state$: StateObservable<any>): Observable<Action> => action$.pipe(
    ofType(fromActions.getMoreMyActiveSurveys),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const myActiveSurveyState = fromSelectors.selectMyActiveSurveysState(state);
        return SurveysService.getMyActiveSurveys({ page: myActiveSurveyState.page + 1, limit: myActiveSurveyState.limit })
            .then(response => fromActions.getMoreMyActiveSurveysSuccess(response))
            .catch(error => fromActions.getMoreMyActiveSurveysError(extractError(error, MAIN_APP_ROUTES.MY_SURVEYS)))
    })
);

const postMyActiveSurveyEpic = (action$: Observable<Action>) : Observable<Action> => action$.pipe(
    ofType(fromActions.postMyActiveSurvey.type),
    map((action: any) => action.payload),
    switchMap((payload) => {
        const question  = SurveysService.getQuestionSendFormat(payload);
        return SurveysService.postMySurvey(question)
            .then(response => {
                // navigate(MAIN_APP_ROUTES.MY_SURVEYS);
                return fromActions.postMyActiveSurveySuccess(response);
            })
            .catch(error => fromActions.postMyActiveSurveyError(extractError(error, MAIN_APP_ROUTES.MY_SURVEYS)))
    })
);


// const uploadImageEpic = (action$: Observable<Action>, state$: StateObservable<any>): Observable<Action> => action$.pipe(
//     ofType(fromActions.uploadMyActiveSurveyImage),
//     map((action: any) => action.payload),
//     switchMap(async (payload: any) => {
//         try {
//             if (payload.imageType === 'question' && payload.data.imagePath !== null) {
//                 const res = await SurveysService.uploadQuestionImage(payload.data);
//                 return 
//             }
//         } catch (error) {
//             return fromActions.postMyActiveSurveyError(extractError(error))
//         }
//     })
// )


const updateMyActiveSurveyEpic = (action$: Observable<Action>) : Observable<Action> => action$.pipe(
    ofType(fromActions.updateMyActiveSurvey),
    map((action: any) => action.payload),
    switchMap((payload) => {
        return SurveysService.updateQuestion(payload)
            .then((res) => fromActions.updateMyActiveSurveySuccess(res))
            .catch(error => fromActions.updateMyActiveSurveyError(extractError(error, MAIN_APP_ROUTES.POST_QUESTION)))
    })
)

const deleteMyActiveSurveyEpic = (action$: Observable<Action>) : Observable<Action> => action$.pipe(
    ofType(fromActions.deleteMyActiveSurvey),
    map((action: any) => action.payload),
    switchMap((payload) => {
        return SurveysService.deleteQuestion(payload)
            .then((res) => fromActions.deleteMyActiveSurveySuccess(payload))
            .catch(error => fromActions.deleteMyActiveSurveyError(extractError(error, MAIN_APP_ROUTES.POST_QUESTION)))
    })
)

const deleteMyActiveSurveySuccessEpic = (action$: Observable<Action>) : Observable<Action> => action$.pipe(
    ofType(fromActions.deleteMyActiveSurveySuccess),
    switchMap(() => [fromActions.toastMessage('Removed successfully')])
);

export const myActiveSurveysEpics = combineEpics(
    getMyActiveSurveysEpic,
    getMoreMyActiveSurveysEpic,
    postMyActiveSurveyEpic,
    updateMyActiveSurveyEpic,
    deleteMyActiveSurveyEpic,
    deleteMyActiveSurveySuccessEpic
);