import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { THEME } from "../enums";
import { getRemainingTime } from "../services";
import { IconProvider } from "../utils";
import CustomText from "../utils/custom-text";

interface Props {
    timeStamp: string
}
export function SurveyTimer(props: Props) {
    const { timeStamp } = props;
    const { t, i18n } = useTranslation();
    const [counter, setCounter] = useState<any>(getRemainingTime(timeStamp));


    useEffect(() => {
        const interval = setInterval(() => {
            setCounter(getRemainingTime(timeStamp));
        }, 1000)
        return () => {
            if (interval) clearInterval(interval);
        }
    }, [timeStamp])

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center'
            }}>
                <CustomText customStyle={{ marginRight: 5, fontWeight: '600' }}>{t('surveyTimeLeft')}</CustomText>
                <div style={{
                    width: 25, height: 25
                }}>
                    <IconProvider type={"stopwatch"} />
                </div>
            </div>
            <div style={{}}>
                <CustomText customStyle={{ color: THEME.ORANGE_RED, fontSize: 14 }}>{counter}</CustomText>
            </div>

        </div>
    )
}