import React, { Component, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CountriesList, CountrySeclector, CustomButton, TextScroller } from "../components";
import { DEVICE_TYPE, FONT, THEME } from "../enums";
import CustomText from "../utils/custom-text";
import { currentWindowHeight, currentWindowWidth, CURRENT_OS } from "../variables";
import { useSelector, useDispatch } from 'react-redux'
import { selectCountries, selectCountriesEntities, selectCountriesState } from "../store/selectors";
import { getCountries, saveUserCountry } from "../store/actions";
import { Country } from "../interfaces";
import { LOGIN_SIGNUP_ROUTES } from "../routes";
import { SpinnerScreen } from "../utils";

const appInfoRecords = [
    { info: 'welocomeMessage', id: '1213232' },
    { info: 'welocomeMessage', id: '1213233' },
    { info: 'welocomeMessage', id: '1213234' },
]
const wrapperHeight = (currentWindowHeight() / 3) * 2;

export function WelcomScreen(props: any) {
    const { history, location } = props;

    const countries = useSelector((state) => selectCountries(state));
    const countriesEntities = useSelector((state) => selectCountriesEntities(state));
    const { loading, error } = useSelector((state) => selectCountriesState(state));
    const dispatch = useDispatch();
    const [selectedCountry, setSelectedCountry] = useState<Country>();
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [scrollIndex, setScrollIndex] = useState<number>(0);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (!countries || countries.length === 0) dispatch(getCountries());
        if (countries && countries.length > 0) setSelectedCountry(countriesEntities['US']);
        return () => {

        }
    }, [countries]);

    if (loading) {
        return (
            <div style={styles.container}>
                <SpinnerScreen />
            </div>
        )
    }

    return (
        <div style={{ ...styles.container, paddingTop: 20 }}>
            <div style={styles.header}>
                <CustomText customStyle={styles.headerText}>{t('welcomeToPubrule2')}</CustomText>
            </div>
            <TextScroller list={appInfoRecords} />
            <div style={styles.chooseCountry}>
                {selectedCountry && <CountrySeclector
                    lable={t('chooseCountry')}
                    listLength={countries.length}
                    selected={selectedCountry}
                    onClick={() => setIsVisible(true)}
                />}
            </div>
            <div style={styles.action}>
                <CustomButton
                    name={t('getStarted')}
                    enable={selectedCountry ? true : false}
                    onClick={() => {
                        if (selectedCountry) dispatch(saveUserCountry(selectedCountry?.code));
                        history.push({ pathname: LOGIN_SIGNUP_ROUTES.LOGIN, state: location.state, });
                    }}
                />
            </div>
            {(isVisible && countries && countries.length > 0) && 

                <CountriesList
                    countries={countries}
                    selected={selectedCountry}
                    loading={loading}
                    setCountry={(doc) => { setSelectedCountry(doc); setIsVisible(false) }}
                    title={t("gender")}
                    visible={isVisible}
                    wrapperHeight={wrapperHeight}
                    onClose={() => { setIsVisible(false) }}
                    onDissmiss={() => { setIsVisible(false) }}
                />
            }
        </div>
    )

}



const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: THEME.WHITE,
        position: 'relative',
        height: '100%',
        width: '100%',
        boxSizing: 'border-box'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    headerText: {
        fontSize: 17,
        color: THEME.BLACK
    },
    chooseCountry: {
        padding: 20,
        marginTop: 30,
    },
    action: {
        paddingLeft: 20,
        paddingRight: 20,
        // marginTop: 30,
    },
})

