
export const MAIN_SCREEN = "MainApp";
export const ADD_EMAIL = "AddEmail";
export const SELECT_STANDARD = "SelectStandard";
export const SELECT_COUNTRY = "SelectCountry";


export enum MAIN_APP_ROUTES {
    MAIN_APP = "/",
    LOGIN_SIGNUP = "/login-signup",

    HOME = "/",
    CONTACT_US = "/contact-us",
    EDIT_PROFILE = "/edit-profile",
    PRIVACY_POLICY = "/privacy-policy",
    ABOUT_US = "/about-us",
    VIDEO_PLAYER = "/video-player",

    YOUR_HELP_MATTERS = "/your-help-matters",
    NOTIFICATIONS = "/notifications",
    SETTINGS = "/settings",

    ACTIVE_SURVEYS_LIST = "/your-help-matters/active-surveys",
    COMPLETED_SURVEYS_LIST = "/your-help-matters/completed-surveys",
    SURVEY = "/your-help-matters/survey",
    INVITED_SURVEY = "/your-help-matters/invited-survey",

    PREVIEW_QUESTION = "/preview-question",
    POST_QUESTION = "/post-question",
    MENU = "/menu",
    DRAFTS = "/drafts",
    GROUPS = "/groups",
    EDIT_GROUP = "/edit-group",
    CREATE_GROUP = "/create-group",
    FRIENDS = "/friends",
    MANAGE_FRIENDS = "/manage-friends",
    INVITE_FRIENDS = "/invite-friends",
    SEARCH = "/search-results",
    QUESTION_BANK = "/question-bank",
    SURVEY_RESULTS = "/survey-results",

    HOME_BOTTOM_TAB = "/home-bottom-tab",
    HOME_TAB = "/home-tab",
    LIVE_SURVEYS = "/live-surveys",
    PUBLIC_CHOICE = "/public-choice",

    CREATE_QUESTION = "/public-choice/create-question",
    MY_SURVEYS = "/public-choice/my-surveys",
    SINGLE_OPTION = "/public-choice/single-option",
    MULTIPLE_CHOICE = "/public-choice/multiple-choice",
    SHORT_ANSWER = "/public-choice/short-answer",

    NOT_FOUND = "/not-found",

    //friends
    MY_FRIENDS = "/friends/my-friends",
    RECIEVED = "/friends/received",
    SENT = "/friends/sent",
    ADD_FRIENDS = "/friends/add-friends",
    // ADD_FRIEND = "Add Friend",

    // NETWORK_ERROR = "NetworkError",

    // APP_LOADING = "AppLoading"

    //     SINGLE_OPTION_RESULTS = "SingleOptionResults",
    //     MULTIPLE_CHOICE_RESULTS = "MultipleChoiceResults",
    //     SHORT_ANSWER_RESULTS = "ShortAnswerResults",
}