import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { THEME } from "../enums";
import { CommentForm, MySurvey, SurveyAnswer, SurveyOption } from "../interfaces";
import { getIconNameByQuestionType, IconProvider, ImageWithSpinner, RadioButton } from "../utils";
import CustomText from "../utils/custom-text";
import { currentWindowWidth } from "../variables";
import { ProgressBar } from './progress-bar';
import { SurveyTimer } from "./survey-timer";
import { CommentsSection } from "./survey-comment";
import * as fromStore from "../store";
import { getCustomDateFormat, isSurveyActive } from "../services";
import { AiOutlineBook } from "react-icons/ai";



const InputDoc = {
    field: 'comment',
    label: '',
    required: true,
    secure: false,
    type: 'none',
    textContentType: 'none',
    placeholder: 'typeYourComment',
    multiline: true,
    numberOfLines: 4,
    backgroundColor: THEME.INPUT_BACKGROUND

};

interface Props {
    survey: MySurvey,
    totalComments: number,
    answers: SurveyAnswer[],
    onImage: (uri: string) => void,
}

export function SingleOptionResults(props: Props) {
    const {
        survey,
        answers,
        totalComments,
        onImage,
    } = props;

    const { t, i18n } = useTranslation();
    const { type, id, category } = survey;

    return (

        <div style={styles.container}>
            {survey?.imageUrl && <div
                style={{
                    ...styles.prevContainer,
                    // ...pressed && {opacity: 0.6}
                }}
                onClick={() => onImage(survey?.imageUrl)}
            >
                <ImageWithSpinner
                    customStyle={styles.prevImage}
                    uri={survey?.imageUrl}
                    resizeMode={"contain"}
                    spinnerSize={30}
                />
            </div>}
            <div style={styles.surveyInfo}>
                <div style={styles.surveyIcon}>
                    {(category && category.imageUrl) ? <ImageWithSpinner
                        resizeMode={"contain"}
                        customStyle={{
                            height: "100%",
                            width: "100%",
                            backgroundColor: "transparent",
                            borderRadius: 53,
                        }}
                        uri={category.imageUrl}
                        spinnerSize={20}
                    /> : <IconProvider type={getIconNameByQuestionType(type)} />}
                </div>
                <div style={styles.surveyData}>
                    <CustomText customStyle={styles.question}>{survey.text}</CustomText>
                    <div style={styles.contentInfo}>
                        <CustomText customStyle={styles.timeStamp}>{survey.startTime ? getCustomDateFormat(survey.endTime) : ''}</CustomText>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {/* <AiOutlineBook style={{ marginRight: 10 }} color={"red"} size={12} /> */}
                            <CustomText customStyle={styles.comments}>{`${totalComments} ${t('comments')}`}</CustomText>
                        </div>
                    </div>
                    <div style={styles.surveyOptionsStatus}>
                        {survey.options.map((doc, index) => (
                            <OptionWithProgress
                                key={doc.id}
                                index={index}
                                option={doc}
                                optionAnswers={answers.filter(item => item.option && (item.option.id === doc.id))}
                                totalUsers={survey.sharedToUsers?.length}
                            />
                        ))}
                    </div>
                    <SurveyTimer timeStamp={survey.endTime} />
                </div>

            </div>
            
        </div>

    );

}

interface OptionWithProgressProps {
    index: number,
    option: SurveyOption,
    optionAnswers: SurveyAnswer[],
    totalUsers: number,
}

function OptionWithProgress(props: OptionWithProgressProps) {
    const { index, option, optionAnswers, totalUsers } = props;
    return (
        <div style={{ marginBottom: 5 }}>
            <RadioButton
                labelStyle={{ fontSize: 14 }}
                selected={false}
                label={option.value}
                onClick={(value) => { }}
            />
            <ProgressBar votes={optionAnswers.length} totalUsers={totalUsers} />
        </div>

    )
}



const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex',
        flexDirection: 'column',

    },
    prevContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 15
    },
    prevImage: {
        width: `${(100/2)}%`,
        height: 120
    },
    surveyInfo: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        paddingBottom: 20,
        borderBottomWidth: 1,
        borderBottomColor: '#E1E1E1',
        marginTop: 10,
    },
    surveyIcon: {
        width: 50,
        height: 50,
        borderRadius: 50,
        marginRight: 15,
        //backgroundColor: '#F7B500',
        padding: 8,
        boxSizing: 'border-box'
    },
    surveyData: {
        display: 'flex',
        flexDirection: 'column',
        width: '80%'
    },
    question: {
        color: THEME.ORANGE_RED,
        fontSize: 14
        // marginRight: CURRENT_OS === DEVICE_TYPE.IOS ? 43 : 60,
    },
    contentInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 15,
        marginBottom: 15
        // marginRight: 43,
    },
    timeStamp: {
        fontSize: 11,
        color: "#777777"
    },
    comments: {
        fontSize: 11,
        color: 'red'
    },

    //survey options 
    surveyOptionsStatus: {
        display: 'flex',
        flexDirection: 'column',
    },


    //Comments sections
    commentsSection: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 15
    },
    submitComment: {
        display: 'flex',
        flexDirection: 'column',
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    btn: {
        width: `${(100/2) - 5}%`,
        height: 40
    },
})