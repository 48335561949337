import React from "react";
import { useTranslation } from "react-i18next";
import { THEME } from "../enums";
import CustomText from "../utils/custom-text";



interface Props {
    votes: number,
    totalUsers: number,
}
export function ProgressBar(props: Props) {
    const { votes, totalUsers } = props;
    const { t, i18n } = useTranslation();
    const percentage = (totalUsers && votes <= totalUsers) ? Math.round(votes / totalUsers * 100) : 0;
    return (
        <div style={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
        }}>
            <div style={{
                position: 'relative',
                // padding: 10,
                height: 20,
                backgroundColor: THEME.INPUT_BORDER,
                width: '100%',
            }}>
                <div style={{
                    position: 'absolute',
                    backgroundColor: THEME.ORANGE_RED,
                    left: 0,
                    top: 0,
                    bottom: 0,
                    width: `${percentage}%`,
                    maxWidth: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    {percentage !== 0 && <CustomText customStyle={{ color: THEME.WHITE, fontSize: 10 }}>{`${percentage}%`}</CustomText>}
                </div>
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
            }}>
                <CustomText customStyle={{ fontSize: 10, color: '#777777' }}>{`${t('votes')}: ${votes}/${totalUsers}`}</CustomText>
            </div>
        </div>

    )
}