

const {
    REACT_APP_API_URL,
    REACT_APP_WEB_CLIENT_ID,
    REACT_APP_FACEBOOK_ID,
    REACT_APP_VERSION,
    REACT_APP_BUILD_NUMBER,
    REACT_APP_YEAR,
    NODE_ENV,
    REACT_APP_WEB_APP_DOMAIN,
    REACT_APP_PRIVACY_POLICY,
    REACT_APP_ABOUT_US,
    REACT_APP_MARKETING,
} = process.env;

export const googleSigninConfig = {
    scopes: ['email', 'profile'], // what API you want to access on behalf of the user, default is email and profile
    webClientId: REACT_APP_WEB_CLIENT_ID, // client ID of type WEB for your server (needed to verify user ID and offline access)
    offlineAccess: true, // if you want to access Google API on behalf of the user FROM YOUR SERVER
    //hostedDomain: '', // specifies a hosted domain restriction
    //loginHint: '', // [iOS] The user's ID, or email address, to be prefilled in the authentication UI if possible. [See docs here](https://developers.google.com/identity/sign-in/ios/api/interface_g_i_d_sign_in.html#a0a68c7504c31ab0b728432565f6e33fd)
    //forceConsentPrompt: true, // [Android] if you want to show the authorization prompt at each login.
    //accountName: '', // [Android] specifies an account name on the device that should be used
    //iosClientId: '<FROM DEVELOPER CONSOLE>', // [iOS] optional, if you want to specify the client ID of type iOS (otherwise, it is taken from GoogleService-Info.plist)
}

export const environment = {
    WEB_APP_DOMAIN: REACT_APP_WEB_APP_DOMAIN,
    BASE_API_URL: REACT_APP_API_URL,
    VERSION: REACT_APP_VERSION,
    BUILD_NUMBER: REACT_APP_BUILD_NUMBER,
    YEAR: REACT_APP_YEAR,
    ENV: NODE_ENV,
    GOOGLE_WEB_CLIENT_ID: REACT_APP_WEB_CLIENT_ID as string,
    FACEBOOK_ID: REACT_APP_FACEBOOK_ID as string,
    PRIVACY_POLICY: REACT_APP_PRIVACY_POLICY,
    ABOUT_US: REACT_APP_ABOUT_US,
    MARKETING: REACT_APP_MARKETING,
}
