import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { THEME } from "../enums";
import { AppError, CommentForm, ImageResponse } from "../interfaces";
import { SpinnerInfo, ErrorMessage } from "../utils";
import { currentWindowWidth } from "../variables";
import * as SurveyService from '../services/surveys.service';
import { checkErrorResponse, extractError } from "../services";
import { MAIN_APP_ROUTES } from "../routes";
import * as fromStore from "../store";
import CustomText from "../utils/custom-text";
import * as MediaService from '../services/media.service';
import AppStorage from "../services/storage.service";
import { CircularProgress } from "@material-ui/core";

interface Props {
    visible: boolean,
    headerText?: string,
    loading?: boolean,
    doc: {
        text: string,
        video: File | null,
        userId: string | undefined,
        questionId: string
    },
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccess: () => void,
}

interface State {
    status: string,
    error: AppError | null,
    loading: boolean
}

export function PostCommentModal(props: Props) {
    const {
        visible,
        headerText,
        loading,
        doc,
        onClose,
        onDissmiss,
        onSuccess,
    } = props;
    const dispatch = useDispatch();
    const [state, setState] = useState<any>({
        status: "",
        progress: 0,
        error: null,
        loading: true,
    })

    useEffect(() => {
        if (visible && doc) postMyComment();
    }, [visible])

    // function onClose() {
    //     if (text && loading === false) onError();
    //     if (!text && loading === false) onSuccess();
    // }

    async function postMyComment() {

        function onProgress(event) {
            let uploaded = Math.round((event.loaded * 100) / event.total);
            setState({
                ...state,
                status: `Uploading media ${uploaded}%`,
                loading: true,
                progress: uploaded
            })
        }
        try {
            setState({ ...state, error: null, loading: true, status: "Uploading media.." });
            const mediaResponse = doc.video ? await SurveyService.uploadCommentMedia(doc.video, onProgress) : null;
            const data: CommentForm = {
                text: doc.text,
                mediaPath: mediaResponse ? mediaResponse.path : null,
                mediaUrl: mediaResponse ? mediaResponse.signedUrl : null,
                question: {
                    id: doc.questionId,
                    user: {
                        id: doc?.userId as string
                    }
                }
            }
            setState({ ...state, status: "Posting.." });
            const response = await SurveyService.postComment(data);
            dispatch(fromStore.postCommentSuccess(response));
            setState({ ...state, loading: false, status: "Success.." });
            onSuccess();
        } catch (error: any) {
            const newError = extractError(error, MAIN_APP_ROUTES.SURVEY);
            setState({ ...state, error: newError, loading: false });
        }

    }

    // function uploadVideo(doc: ImagePickerResponse, token: string) {
    //     return MediaService.uploadVideo(doc, token)
    //         .uploadProgress({ interval: 250 }, (written, total) => {
    //             let uploaded = (written / total) * 100;
    //             setState({
    //                 ...state,
    //                 progress: uploaded.toFixed(1)
    //             })
    //         })
    //         .then(res => {
    //             return res.data;
    //         })
    // }

    return (
        <div style={styles.modalContainer}>
            <div style={styles.container}>
                {(state.loading && !state.error) && <ActivityViewer text={state.status} />}
                {state.error && <ErrorMessage
                    text={checkErrorResponse(state.error)}
                    onClose={() => {
                        setState({ ...state, error: null });
                        onClose();
                    }}
                />}
            </div>
        </div>
    )
}

function ActivityViewer(props) {
    const { text } = props;
    return (
        <div style={styles.modalView}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <CircularProgress size={20} style={{ marginBottom: 10 }} />
                <CustomText customStyle={styles.modalText}>{text}</CustomText>
            </div>
        </div>
    )
}

const styles: { [key: string]: React.CSSProperties } = ({
    modalContainer: {
        position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, zIndex: 100, 
    },
    container: {
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        height: '100%',
        width: '100%'
        // marginTop: 22
    },

    contentContainer: {
        position: 'relative',
        width: `${(100/2)}%`,
        backgroundColor: THEME.WHITE,
        borderRadius: 10,

        display: 'flex',
        flexDirection: 'column',
        minHeight: 100
    },

    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: THEME.INPUT_BORDER
    },

    modalText: {
        // marginBottom: 15,
        textAlign: "center"
    },

    messageContainer: {
        // paddingTop: 10, paddingBottom: 10,
        // paddingLeft: 15, paddingRight: 15,
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    closeIcon: {
        position: 'absolute',
        top: -10,
        right: -10,
        padding: 5,
        backgroundColor: THEME.RED,
        borderRadius: 20,
    },

    actionContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },

    actionBtn: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: `${100 / 2}%`,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 15, paddingBottom: 15,


    },
    actionBtnText: {
        fontWeight: 500,
        fontSize: 16
    },

    //Activity viewer
    modalView: {
        // margin: 20,
        width: `${(100/2)}%`,
        backgroundColor: THEME.WHITE,
        borderRadius: 10,
        padding: 15,
        alignItems: "center",
    },

})