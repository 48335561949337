import React from "react";
import { useTranslation } from "react-i18next";
import { THEME } from "../enums";
import { IconProvider } from "../utils";
import CustomText from "../utils/custom-text";
import {
    FacebookShareButton,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    LinkedinShareButton,
    TwitterShareButton,
    PinterestShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    RedditShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    TelegramIcon,
    WhatsappIcon,
    RedditIcon,
} from 'react-share';

const SHARE_OPTIONS = [
    { icon: 'facebook-share', title: 'Facebook' },
    { icon: 'twitter', title: 'Twitter' },
    { icon: 'google-plus', title: 'Google+' },
    { icon: 'whats-app', title: "What's App" },
]
interface InviteLinkProps {
    inviteLink: string,
    onShare: () => void,
    onCopy: () => void,
}
export function InviteLink(props: InviteLinkProps) {
    const { inviteLink, onShare, onCopy } = props;
    const { t, i18n } = useTranslation();
    return (
        <div style={styles.container}>
            <div style={styles.linkContainer}>
                <div style={styles.link}>
                    <div style={styles.linkTxt}>
                        {/* <CustomText customStyle={{ color: "#919BBF" }}>{inviteLink}</CustomText> */}
                        <input
                            style={{ padding: 0, color: "#919BBF", width: '100%', backgroundColor: 'transparent', minHeight: 30, border: 'none' }}
                            value={inviteLink}
                            disabled={true}
                            autoCapitalize={"none"}
                            // autoCompleteType={"off"}
                            autoFocus={false}
                        // caretHidden={false}
                        // contextMenuHidden={false}
                        />
                    </div>
                    <div
                        style={styles.copy}
                        onClick={onCopy}
                    >
                        <CustomText customStyle={{ color: THEME.ORANGE_RED }}>{t('copy')}</CustomText>
                    </div>
                </div>
            </div>
            <div style={styles.shareContainer}>
                <div style={styles.shareOptions}>
                    {getShareOptions(inviteLink, "invite link").map((doc, index) => (
                        <div
                            key={index}
                            style={{
                                ...{ opacity: 0.7, marginRight: 10 }
                            }}
                            onClick={onShare}
                        >
                            {doc}
                        </div>
                    ))}
                    {/* {SHARE_OPTIONS.map((doc, index) => (
                        <div
                            key={index}
                            style={{
                                ...{ opacity: 0.7 }
                            }}
                            onClick={onShare}
                        >

                            <div style={{ width: 30, height: 30, borderRadius: 15, marginRight: 15 }}>
                                <IconProvider type={doc.icon} />
                            </div>

                        </div>
                    ))} */}
                </div>
            </div>
        </div>
    );


    function getShareOptions(shareUrl: string, title: string) {

        return [
            <FacebookShareButton
                url={shareUrl}
                quote={title}
                className="Demo__some-network__share-button"
            >
                <FacebookIcon size={32} round />
            </FacebookShareButton>,
            <FacebookMessengerShareButton
                url={shareUrl}
                appId={"521270401588372"}
                className="Demo__some-network__share-button"
            >
                <FacebookMessengerIcon size={32} round />
            </FacebookMessengerShareButton>,
            <TwitterShareButton
                url={shareUrl}
                title={title}
                className="Demo__some-network__share-button"
            >
                <TwitterIcon size={32} round />
            </TwitterShareButton>,
            <WhatsappShareButton
                url={shareUrl}
                title={title}
                separator=":: "
                className="Demo__some-network__share-button"
            >
                <WhatsappIcon size={32} round />
            </WhatsappShareButton>,
            <TelegramShareButton
                url={shareUrl}
                title={title}
                className="Demo__some-network__share-button"
            >
                <TelegramIcon size={32} round />
            </TelegramShareButton>
        ]
    }
}


const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        boxSizing: 'border-box'
    },
    linkContainer: {

    },
    shareContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 15,
        marginTop: 25
    },
    link: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: THEME.INPUT_BACKGROUND,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 15,
        paddingRight: 15,
        borderRadius: 10,
    },
    linkTxt: {
        width: '85%',
    },
    copy: {
        cursor: 'pointer'
    },
    shareOptions: {
        display: 'flex',
        flexDirection: 'row',

    }
})