import React from "react";
import { useTranslation } from "react-i18next";
import { THEME } from "../enums";
import { MAIN_APP_ROUTES } from "../routes";
import CustomText from "../utils/custom-text";
import { currentWindowHeight, currentWindowWidth } from "../variables";

const headerColumnSize = `${100 / 3}%`;

const menuList = [
    {
        title: 'createANewGroup',
        route: MAIN_APP_ROUTES.CREATE_GROUP,
        params: {}
    },
    {
        title: 'drafts',
        route: MAIN_APP_ROUTES.DRAFTS,
        params: {}
    },
    {
        title: 'friends',
        route: MAIN_APP_ROUTES.FRIENDS,
        params: {}
    },
    {
        title: 'myProfile',
        route: MAIN_APP_ROUTES.EDIT_PROFILE,
        params: {}
    },
    // {
    //     title: 'inviteFriends',
    //     route: MAIN_APP_ROUTES.INVITE_FRIENDS,
    //     params: {}
    // },
    // {
    //     title: 'createASurvey',
    //     route: MAIN_APP_ROUTES.HOME,
    //     params: { screen: MAIN_APP_ROUTES.HOME, params: { screen: MAIN_APP_ROUTES.DECISIONS, params: { screen: MAIN_APP_ROUTES.CREATE_QUESTION } } }
    // },
    // {
    //     title: 'postOnSurvey',
    //     route: MAIN_APP_ROUTES.YOUR_HELP_MATTERS,
    //     params: {}
    // },
    // {
    //     title: 'notifications',
    //     route: MAIN_APP_ROUTES.NOTIFICATIONS,
    //     params: {}
    // },
    // {
    //     title: 'settings',
    //     route: MAIN_APP_ROUTES.SETTINGS,
    //     params: {}
    // },
    // {
    //     title: 'friends',
    //     route: MAIN_APP_ROUTES.ADD_FRIENDS,
    //     params: {}
    // },
]

export interface AccessMenuProps {
    style: any,
    onClick: (route: string, params: any) => void,
    onClose: () => void,
}
export function AccessMenu(props: AccessMenuProps) {
    const {
        style,
        onClick,
        onClose,
    } = props;
    const { t, i18n } = useTranslation();
    return (

        <div
            onClick={onClose}
            style={styles.container}
        >
            <div style={{
                ...styles.menuContainer,
                ...style
            }}>
                {
                    menuList.map((doc, index) => (
                        <div
                            key={index}
                            className={"on-press-cls"}
                            style={{
                                ...styles.listItem,
                                ...index !== menuList.length - 1 && { borderBottomWidth: 1, borderBottomColor: THEME.INPUT_BORDER, borderBottomStyle: 'solid' },
                                // ...{ backgroundColor: pressed ? 'rgba(230,230,230,0.2)' : 'transparent' }
                            }}
                            onClick={() => onClick(doc.route, doc.params)}
                        >
                            <CustomText customStyle={{}}>{t(doc.title)}</CustomText>

                        </div>
                    ))
                }
            </div>
        </div>
    )
}

const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        position: 'absolute',
        zIndex: 100,
        backgroundColor: 'rgba(0,0,0,0.1)',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    menuContainer: {
        position: 'absolute',
        width: `${(100/2)}%`,
        backgroundColor: THEME.WHITE,
        right: 10,
        top: 55,
        zIndex: 1,
        borderRadius: 10,
    },
    listItem: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 10,
        paddingBottom: 10,
        cursor: 'pointer'
    },
})