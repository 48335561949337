import React, { PureComponent, useEffect, useRef, useState } from 'react';
// import { TextInput, View, Keyboard, Text, StyleSheet, div, Image, FlatList } from 'react-native';
import { useTranslation } from 'react-i18next';
import CustomText from '../utils/custom-text';
import { APP_THEME_COLOR, currentWindowWidth, CURRENT_OS } from '../variables';
import { DEVICE_TYPE, FONT, THEME } from '../enums';
import { IconProvider } from './icon-provider';
import { formValidators } from '../services';
// import Svg, { Path } from 'react-native-svg';
// import { IconProvider } from './icon-provider';
// import * as ImagePicker from 'react-native-image-picker';
// import { Icon } from 'react-native-elements';
// import { ImagePickerResponse } from 'react-native-image-picker';
// import Video from 'react-native-video';

/* ----------------- FormInput --------------------- */


interface Props {
    value: any,
    secure?: any,
    placeholder: any,
    keyboardtype?: any,
    type: any,
    id: string,
    pattern?: any,
    label?: string,
    password?: string,
    fieldStyles?: any,
    clearButtonMode?: any,
    editable?: any,
    placeholderTextColor?: string,
    confirmPassword?: string,
    multiline?: boolean,
    numberOfLines?: number,
    required?: boolean,
    textContentType?: string,
    backgroundColor?: string,
    onChange: (id: string, value: any) => void
}

export function FormInput(props: Props) {
    const { t } = useTranslation();
    const {
        value,
        secure,
        placeholder,
        keyboardtype,
        type,
        id,
        pattern,
        label,
        password,
        fieldStyles,
        clearButtonMode,
        editable,
        placeholderTextColor,
        multiline,
        numberOfLines,
        required,
        textContentType,
        backgroundColor
    } = props;
    const [error, setError] = useState<string | null>('');
    const [prevPassword, setPrevPassword] = useState<string | undefined>(props.password);
    


    useEffect(()=>{
        if((id === "password" || id === "confirmPassword") && props.password && props.password != prevPassword) {
            setPrevPassword(props.password);
            handleValidation(props.value);
        }
    },[props.password])


    const handleChange = (value: any) => {
        const text = value;
        props.onChange(props.id, text);
    }

    const handleValidation = (text: any) => {
        const { id, pattern, password, confirmPassword } = props;
        setError(formValidators(t, text, id, pattern, password));
    }



    return (
        <div style={{ ...{ display: 'flex', flexDirection: 'column' }, ...fieldStyles }}>
            {label ? (
                <CustomText customStyle={styles.lableHeader}>{label + (required ? ' *' : '')}</CustomText>
            ) : null}
            <div style={{ ...styles.inputIconView, ...(editable === false ? { opacity: 0.4, } : null) }}>
                {multiline ?
                    <textarea
                        className={"form-input"}
                        style={{
                            // ...styles.input,
                            ...{ minWidth: '100%', maxWidth: '100%', boxSizing: 'border-box', minHeight: 80, maxHeight: numberOfLines ? numberOfLines * 30 : 45  },
                            ...{ backgroundColor: backgroundColor ? backgroundColor : THEME.WHITE },

                        }}
                        placeholder={placeholder}
                        onChange={event => {
                            handleChange(event.target.value);
                            handleValidation(event.target.value);
                        }}
                        value={value}
                        autoCapitalize={"none"}
                        autoFocus={false}
                        disabled={editable === false}
                        
                    />
                    : <input
                        className={"form-input"}
                        style={{
                            // ...styles.input,
                            // ...{ height: numberOfLines ? numberOfLines * 30 : 45 },
                            ...{ backgroundColor: backgroundColor ? backgroundColor : THEME.WHITE },

                        }}
                        type={textContentType ? textContentType : type}
                        placeholder={placeholder}
                        // placeholderTextColor={placeholderTextColor ? placeholderTextColor : THEME.PLACEHOLDER}
                        // textContentType={textContentType ? textContentType : type}
                        // secureTextEntry={secure}
                        // keyboardType={keyboardtype}
                        onChange={event => {
                            handleChange(event.target.value);
                            handleValidation(event.target.value);
                        }}
                        value={value}
                        autoCapitalize={"none"}
                        // autoCompleteType={"off"}
                        autoFocus={false}
                        disabled={editable === false}
                    // caretHidden={false}
                    // clearButtonMode={clearButtonMode ? clearButtonMode : "while-editing"}
                    // contextMenuHidden={false}
                    // editable={editable}
                    // multiline={multiline}
                    // numberOfLines={numberOfLines}
                    //onBlur={Keyboard.dismiss} 
                    />}
                {error ? <CustomText customStyle={styles.validatorError}>{error}</CustomText> : null}
            </div>

        </div>
    )

}




/* ----------------- MultipleFormSelector --------------------- */

interface MultipleFormSelectorProps {
    lable?: string,
    placeholder: any,
    selected: any[],
    fieldStyles?: any,
    multiline?: boolean,
    numberOfLines?: number,
    onClick: () => void,
}

export function MultipleFormSelector(props: MultipleFormSelectorProps) {
    const { lable, placeholder, selected, fieldStyles, multiline, numberOfLines, onClick } = props;

    return (
        <div style={{ ...styles.container, ...fieldStyles }}>
            {lable ? <CustomText customStyle={styles.lableHeader}>{lable}</CustomText> : null}
            <div
                style={{
                    ...styles.innercontainer,
                    // ...{ backgroundColor: pressed ? 'rgba(230,230,230,0.2)' : THEME.WHITE }
                }}
                onClick={onClick}
            >
                <div style={styles.innervalue}>
                    {/* {selected?.url && <div style={styles.thumbnail}>
                        <Image
                            source={{ uri: selected?.url }}
                            resizeMode={"cover"}
                            style={{ width: "100%", height: "100%", borderRadius: 10 }}
                        />
                    </div>} */}
                    <div style={styles.multiInput}>
                        {(selected && selected.length > 0) ? selected.map((doc, index) => (
                            <div key={index} style={{}}>
                                <CustomText customStyle={styles.selectorText}>{doc.name}</CustomText>
                            </div>
                        )) :
                            (
                                typeof (placeholder) === "string" ? <CustomText customStyle={styles.selectorPlaceholder}>{placeholder}</CustomText>
                                    : placeholder.map((doc, index) => (
                                        <div key={index} style={{}}>
                                            <CustomText customStyle={styles.selectorPlaceholder}>{doc}</CustomText>
                                        </div>
                                    ))
                            )
                        }

                    </div>
                </div>
                <div style={{
                    marginTop: 20
                }}>
                    {/* <Svg viewBox="0 0 10 6" style={styles.triangle}>
                        <Path
                            strokeWidth={0}
                            fill="rgba(88,91,90,1)"
                            fillOpacity={1}
                            strokeOpacity={1}
                            d="M5.00 0.00 L10.00 6.00 L0.00 6.00 L5.00 0.00 Z"
                        ></Path>
                    </Svg> */}
                </div>

            </div>

        </div>
    )
}


const boxWidth = 70;

/* ----------------- Radio button --------------------- */
interface RadioButtonProps {
    selected: boolean,
    label: string,
    disabled?: boolean,
    highlightColor?: string,
    customStyle?: any,
    labelStyle?: any,
    onClick: (value: boolean) => void,
}
export function RadioButton(props: RadioButtonProps) {
    const { selected, label, disabled, highlightColor, customStyle, labelStyle, onClick } = props;

    return (
        <div
            style={{
                ...customStyle
            }}
            onClick={() => {
                if(disabled) return;
                onClick(!selected);
            }}
        >

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                alignItems: 'center',
                
            }}>
                <div style={{
                    ...{
                        display: 'flex',
                        height: 24,
                        width: 24,
                        borderRadius: 24,
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: THEME.DEFAULT_BORDER,
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: 10,
                        cursor: 'pointer'
                        // backgroundColor: pressed ? THEME.INPUT_BORDER : (selected ? THEME.WHITE : "#D8D8D8")
                    }
                }}>
                    {
                        (selected && !disabled) ?
                            <div style={{
                                height: 18,
                                width: 18,
                                borderRadius: 18,
                                backgroundColor: highlightColor ? highlightColor : THEME.ORANGE_RED,
                            }} />
                            : null
                    }
                </div>
                <div style={{}}>
                    <CustomText customStyle={{ ...{ textTransform: 'capitalize', color: selected ? THEME.ORANGE_RED : THEME.BLACK }, ...labelStyle }}>{label}</CustomText>
                </div>
            </div >

        </div >
    );
}




const styles: { [key: string]: React.CSSProperties } = ({
    lableHeader: {
        fontSize: 13,
        fontFamily: FONT.MANROPE_REGULAR, //Helvatica
        marginBottom: 10,
        marginTop: 10,
        color: THEME.BLACK
    },
    inputIconView: {
        display: 'flex',
        flexDirection: 'column',
    },
    input: {
        // textAlignVertical: "top",
        minHeight: 45,
        color: THEME.BLACK,
        borderRadius: 10,
        paddingLeft: 15,
        fontFamily: FONT.MANROPE_REGULAR,
        borderWidth: 1,
        borderBlockStyle: 'solid',
        borderColor: THEME.INPUT_BORDER,
        fontSize: 12
    },
    validatorError: {
        color: 'red',
        padding: 5,
        fontSize: 10,
        fontFamily: FONT.MANROPE_REGULAR

    },

    //selector styles
    container: {
        display: 'flex', flexDirection: 'column',
        width: '100%',
        position: 'relative',
    },
    innercontainer: {
        position: 'relative',
        width: '100%',
        borderRadius: 10,
        borderWidth: 2,
        borderBlockStyle: 'solid',
        borderColor: 'rgb(244,244,244)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 10,
    },
    // label: {
    //     position: 'absolute',
    //     top: -8,
    //     left: 20,
    //     backgroundColor: THEME.WHITE,
    //     paddingLeft: 10,
    //     paddingRight: 10,
    // },
    labelTxt: {
        fontSize: 12,
        fontFamily: FONT.MANROPE_REGULAR
    },
    innervalue: {
        display: 'flex',
        flexDirection: 'row',
    },
    thumbnail: {
        width: 30, height: 25, marginTop: 10, marginRight: 10, borderRadius: 10
    },
    triangle: {
        height: 6,
        width: 10,
        backgroundColor: "transparent",
        borderColor: "transparent",
        // transform: [
        //     {
        //         rotate: "-180.00deg"
        //     }
        // ],

    },

    multiInput: {
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column'
    },
    selectorText: {
        fontSize: 11,
        fontFamily: FONT.MANROPE_REGULAR, //Helvatica
        color: THEME.BLACK
    },

    selectorPlaceholder: {
        fontSize: 11,
        fontFamily: FONT.MANROPE_REGULAR, //Helvatica
        color: THEME.PLACEHOLDER
    },

    //Image input

    imgInputContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        borderRadius: 10,
        padding: 10,
        borderWidth: 1,
        borderBlockStyle: 'solid',
        borderColor: THEME.INPUT_BORDER,
        borderStyle: 'dashed'
    },
    imgInstructions: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    imgPreviewContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    imgInputPlaceholder: {
        fontSize: 16, color: '#B2B2B2'
    },
    brwTxt: {
        textDecorationLine: 'underline'
    },

    //image preview styles

    imgPreviewItem: {
        position: 'relative',
        display: 'flex',
        width: boxWidth,
        height: boxWidth + 10,
        marginTop: 8,
        borderWidth: 1,
        borderBlockStyle: 'solid',
        borderColor: THEME.INPUT_BORDER,
        borderRadius: 5,
        marginRight: 10
    },

    addMoreContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: boxWidth - 20,
        height: boxWidth - 20,
        marginTop: 20,
        borderWidth: 1,
        borderBlockStyle: 'solid',
        borderColor: THEME.INPUT_BORDER,
        borderRadius: 5
    },


    //Image & form input styles

    optionImg: {
        width: 20, height: 30, borderWidth: 1, borderBlockStyle: 'solid', borderColor: 'rgba(130,130,130,0.2)'
    },
    optionImgIcon: {
        width: 20, height: 20
    },

    optionMenuContainer: {
        // position: 'absolute',
        // bottom: -65,
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        alignItems: 'flex-end',
        width: '100%',
        paddingTop: 5, paddingBottom: 5,
        backgroundColor: THEME.WHITE,
    },

    optionMenu: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: THEME.WHITE,
        borderWidth: 1,
        borderBlockStyle: 'solid',
        borderColor: THEME.INPUT_BORDER,
        // borderRadius: 10,
        // shadowColor: 'rgba(0,0,0,0.2)',
        // shadowOffset: { width: 0, height: 0 },
        // shadowOpacity: 0.7,
        // shadowRadius: 2,
        // elevation: 5
    },

    optionBtn: {
        paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5
    },

});

// export default withTranslation()(FieldForm) ;