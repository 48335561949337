import React, { useEffect, useState } from "react";
import { THEME } from "../enums";
import { IconProvider } from "../utils";
import { BOTTOM_NAV_BAR_HEIGHT } from "../variables";
import { MAIN_APP_ROUTES } from "../routes";
import '../scss/bottom-nav.scss';
import CustomText from "../utils/custom-text";

const MENU = [
    { icon: 'home', route: MAIN_APP_ROUTES.HOME, name: "CREATE A SURVEY", },
    { icon: 'ballet-box', route: MAIN_APP_ROUTES.YOUR_HELP_MATTERS, name: "YOUR HELP MATTERS", },
    { icon: 'notification', route: MAIN_APP_ROUTES.NOTIFICATIONS, name: "NOTIFICATIONS", },
    { icon: 'settings-s', route: MAIN_APP_ROUTES.SETTINGS, name: "SETTINGS", },
]

interface Props {
    scrolled?: any,
    bottomLocation?: any,
    history?: any,
    location?: any,
    style?: any,
    notifications: number,
}

export function BottomNav(props: Props) {
    const { scrolled, style, bottomLocation, notifications, history, location } = props;

    function isHomeRoute(name: string) {
        switch (name) {
            case MAIN_APP_ROUTES.PUBLIC_CHOICE: return true;
            case MAIN_APP_ROUTES.CREATE_QUESTION: return true;
            case MAIN_APP_ROUTES.SINGLE_OPTION: return true;
            case MAIN_APP_ROUTES.MULTIPLE_CHOICE: return true;
            case MAIN_APP_ROUTES.SHORT_ANSWER: return true;
            case MAIN_APP_ROUTES.MY_SURVEYS: return true;
            case MAIN_APP_ROUTES.MENU: return true;
            case MAIN_APP_ROUTES.CREATE_GROUP: return true;
            case MAIN_APP_ROUTES.GROUPS: return true;
            case MAIN_APP_ROUTES.INVITE_FRIENDS: return true;
            case MAIN_APP_ROUTES.FRIENDS: return true;
            default: return false;
        }
    }


    return (
        <div style={{ ...styles.container, ...style }}>
            {MENU.map((doc, index) => (
                <div
                    key={index}
                    title={doc.name}
                    className={"icon-tab"}
                    style={{
                        ...styles.tab,
                        ...index === 0 && { backgroundColor: isHomeRoute(location?.pathname) ? 'rgba(180,180,180,0.2)' : THEME.WHITE },
                        ...index !== 0 && { backgroundColor: location?.pathname.includes(doc.route) ? 'rgba(180,180,180,0.2)' : THEME.WHITE }
                    }}
                    onClick={() => {
                        if (!history) return;
                        history.push(doc?.route);
                    }}
                >
                    <div style={styles.icon} >
                        <IconProvider type={doc.icon}  title={doc.name} />
                    </div>
                    {(doc?.route === MAIN_APP_ROUTES.NOTIFICATIONS && notifications && notifications > 0)
                        ? <div style={styles.notifContainer}>
                            <CustomText customStyle={styles.notifText}>{`${notifications}`}</CustomText>
                        </div> : null
                    }
                </div>
            ))}
        </div>
    )
}


const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: BOTTOM_NAV_BAR_HEIGHT,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: THEME.WHITE,
        width: '100%'
    },
    tab: {
        position: 'relative',
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 15,
        paddingRight: 15,
        borderRadius: 5,
        cursor: 'pointer'
    },
    icon: {
        width: 26,
        height: 26
    },
    notifContainer: {
        position: 'absolute',
        top: -12,
        right: 5,
        backgroundColor: 'red',
        width: 22,
        height: 22,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 22
    },
    notifText: {
        color: THEME.WHITE, fontSize: 16, fontWeight: 600
    }
})