import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { CommentFormScreen } from "./comment-form";
import { QUESTION_TYPE, THEME } from "../enums";
import { CommentForm, MySurvey, SurveyAnswer, SurveyAnswerForm, SurveyOption } from "../interfaces";
import { getCustomDateFormat, isSurveyActive } from "../services";
import { FormInput, getIconNameByQuestionType, IconProvider, ImageWithSpinner, RadioButton, VideoInput } from "../utils";
import CustomText from "../utils/custom-text";
import { currentWindowWidth } from "../variables";
import { CustomButton } from "./button";
import { ProgressBar } from './progress-bar';
import { CommentsSection, SurveyCommentItem } from "./survey-comment";
import { SurveyTimer } from "./survey-timer";
import { AiOutlineBook } from "react-icons/ai";

const InputDoc = {
    field: 'comment',
    label: '',
    required: true,
    secure: false,
    type: 'none',
    textContentType: 'none',
    placeholder: 'typeYourComment',
    multiline: true,
    numberOfLines: 2,
    backgroundColor: THEME.INPUT_BACKGROUND

};

interface Props {
    survey: MySurvey,
    totalComments: number,
    state: any,
    onImage: (uri: string) => void,
    onStateChange: (state: any) => void,
    onSubmitAnswer: (doc: SurveyAnswerForm) => void,
}

export function MultiChoiceSurvey(props: Props) {
    const {
        state,
        survey,
        totalComments,
        onImage,
        onStateChange,
        onSubmitAnswer,
    } = props;
    const { t, i18n } = useTranslation();
    const { type, id, category } = survey;


    return (
        <div style={styles.container}>
            <div style={styles.surveyInfo}>
                <div style={styles.surveyIcon}>
                    {(category && category.imageUrl) ? <ImageWithSpinner
                        resizeMode={"contain"}
                        customStyle={{
                            height: "100%",
                            width: "100%",
                            backgroundColor: "transparent"
                        }}
                        uri={category.imageUrl}
                        spinnerSize={20}
                    /> : <IconProvider type={getIconNameByQuestionType(type)} />}
                </div>
                <div style={styles.surveyData}>
                    <CustomText customStyle={styles.question}>{survey.text}</CustomText>
                    <div style={styles.contentInfo}>
                        <CustomText customStyle={styles.timeStamp}>{survey.startTime ? getCustomDateFormat(survey.startTime) : ''}</CustomText>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {/* <AiOutlineBook style={{ marginRight: 10 }} color={"red"} size={12} /> */}
                            <CustomText customStyle={styles.comments}>{`${totalComments} ${t('comments')}`}</CustomText>
                        </div>
                    </div>
                    <div style={styles.surveyOptionsStatus}>
                        {
                            survey?.options.map((doc, index) => (
                                <AnswerOptionItem
                                    key={doc.id}
                                    index={index}
                                    doc={doc}
                                    isEnable={isSurveyActive(survey.endTime)}
                                    selected={isOptionSelected(doc.id)}
                                    onImage={onImage}
                                    onToggle={(value) => {
                                        onStateChange({ ...state, selectedOption: value ? doc.id : '' });
                                        onSubmitAnswer({
                                            question: { id: survey.id, user: { id: survey.user.id } },
                                            option: {
                                                id: doc.id
                                            }
                                        })
                                    }}
                                />
                            ))
                        }
                        <SurveyTimer timeStamp={survey.endTime} />
                    </div>
                </div>

            </div>
        </div>
    );

    function isOptionSelected(id: string) {
        if (state.selectedOption && state.selectedOption === id) return true;
        // if(answer && answer.option && answer.option.id === id) return true;
        return false;
    }
}

interface AnswerOptionItemProps {
    index: number,
    doc: SurveyOption,
    selected: boolean,
    isEnable: boolean,
    onImage: (uri: string) => void,
    onToggle: (value: boolean) => void
}

function AnswerOptionItem(props: AnswerOptionItemProps) {
    const { index, doc, selected, isEnable, onImage, onToggle } = props;
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 10
            }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <div style={{}}>
                    <RadioButton
                        labelStyle={{ fontSize: 14 }}
                        selected={selected}
                        label={`${index + 1}. ${doc.value}`}
                        onClick={(value) => isEnable ? onToggle(value) : {}}
                    />
                    {/* <CustomText customStyle={{ fontSize: 14, textTransform: 'capitalize' }}>{`${index + 1}. ${doc.value}`}</CustomText> */}
                </div>
                {doc && doc.imageUrl &&
                    <div
                        style={{
                            ...{ width: 20, height: 30, borderWidth: 1, borderColor: THEME.INPUT_BORDER }
                        }}
                        onClick={() => onImage(doc?.imageUrl)}
                    >

                        <img
                            src={doc?.imageUrl}
                            style={{
                                height: "100%",
                                width: "100%",
                                backgroundColor: "transparent",
                                objectFit: 'cover'
                            }}
                        />


                    </div>}
            </div>
        </div>
    )
}

const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
}

const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    surveyInfo: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        marginBottom: 25

    },
    surveyIcon: {
        width: 50,
        height: 50,
        borderRadius: 50,
        marginRight: 15,
        //backgroundColor: '#F7B500',
        padding: 8,
        boxSizing: 'border-box'
    },
    surveyData: {
        display: 'flex',
        flexDirection: 'column',
        width: '80%'
    },
    question: {
        color: THEME.ORANGE_RED,
        fontSize: 14
        // marginRight: CURRENT_OS === DEVICE_TYPE.IOS ? 43 : 60,
    },
    contentInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 15,
        marginBottom: 15
        // marginRight: 43,
    },
    timeStamp: {
        fontSize: 11,
        color: "#777777"
    },
    comments: {
        fontSize: 11,
        color: 'red'
    },

    //survey options 
    surveyOptionsStatus: {
        display: 'flex',
        flexDirection: 'column',
    },

    //Comment Form
    commentForm: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 15
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    btn: {
        width: `${(100 / 2) - 5}%`,
        height: 40
    },
    //Comments sections
    commentsSection: {
        display: 'flex',
        flexDirection: 'column',
    }
})