import React from "react";
import { useTranslation } from "react-i18next";
import { ScreenHolder } from "../components";
import { DEVICE_TYPE, DRAWER_TYPE, QUESTION_TYPE, THEME } from "../enums";
import { Draft, MySurvey } from "../interfaces";
import { MAIN_APP_ROUTES } from "../routes";
import { getCustomDateFormat, getRouteNameByQuestionType } from "../services";
import { IconProvider } from "../utils";
import CustomText from "../utils/custom-text";
import { currentWindowWidth, CURRENT_OS } from "../variables";


const actionSize = currentWindowWidth() / 3;

const QUESTIONS: MySurvey[] = require("../../assets/others/question-bank.json");

export function QuestionBankScreen(props: any) {
    const { history, location } = props;
    const { t, i18n } = useTranslation();



    return (
        <ScreenHolder location={location} history={history} >
            <div style={styles.screenContainer}>
                <div style={styles.screenHeader}>
                    <CustomText customStyle={styles.shdtxt}>{t('questionBank')}</CustomText>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}>
                    {QUESTIONS.map((item, index) =>
                        <DecisionItem
                            key={index}
                            item={item}
                            onClick={(doc) => {
                                history.push({
                                    pathname: `${getRouteNameByQuestionType(doc.type)}/${doc.id}`
                                })
                            }}

                        />)}
                </div>
            </div>
        </ScreenHolder>
    )
}

interface DecisionItemProps {
    item: MySurvey,
    onClick: (doc: MySurvey) => void;
}

function DecisionItem(props: DecisionItemProps) {
    const { item, onClick } = props;
    const { type, id, text } = item;

    function getIconNameByQuestionType(type: string) {
        switch (type) {
            case QUESTION_TYPE.SINGLE_OPTION: return 'relationship';
            case QUESTION_TYPE.MULTIPLE_CHOICE: return 'clothes';
            case QUESTION_TYPE.SHORT_ANSWER: return 'emotions';
            default: return 'relationship';;
        }
    }
    return (
        <div
            style={{
                ...styles.decisionContainer,
                // ...{ backgroundColor: pressed ? 'rgba(220,220,220,0.2)' : THEME.WHITE }
            }}
            onClick={() => onClick(item)}
        >

            <div style={styles.decisionItem}>
                <div style={styles.prevIcon}>
                    <IconProvider type={getIconNameByQuestionType(type)} />
                </div>
                <div style={styles.decisionContent}>
                    <CustomText customStyle={styles.question}>{item.text}</CustomText>
                    <div style={styles.contentInfo}>
                        <CustomText customStyle={styles.timeStamp}>{item.createdAt ? getCustomDateFormat(item.createdAt) : ''}</CustomText>
                        {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Icon style={{ marginRight: 10 }} color={"red"} size={12} type={"ionicon"} name="book-outline" />
                                <CustomText customStyle={styles.comments}>{`0 Comment`}</CustomText>
                            </div> */}

                    </div>
                </div>
            </div>

        </div>

    )
}

const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: THEME.BLACK,
        height: '100%'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 25,
    },
    actionLeft: {
        width: 26,
        height: 26,
        padding: 5,
        borderRadius: 12,
        marginLeft: 10,

    },
    logo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,

    },
    actionRight: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: actionSize,
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: THEME.WHITE,
        height: '100%',
        width: '100%',
        borderTopLeftRadius: 30,
        borderTopRightRadius: 30,
        paddingBottom: 120
    },
    image: {
        width: actionSize,
        height: "100%",
    },
    bottomNav: {
        position: 'absolute',
        display: 'flex',
        width: '100%',
        bottom: 0,
        backgroundColor: THEME.WHITE,
        borderTopWidth: 1,
        borderTopColor: 'rgb(244,244,244)'
    },

    // Screen container styles

    screenContainer: {
        display: 'flex',
        flexDirection: 'column',

    },
    screenHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 15,
        borderBottomWidth: 1,
        borderBottomColor: THEME.DEFAULT_BORDER,
        borderBottomStyle: 'solid'
    },
    shdtxt: {
        fontSize: 17,
        color: THEME.ORANGE_RED,
        textTransform: 'uppercase',
    },
    screenContent: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 20,
        paddingRight: 20
    },

    //Decision item styles

    decisionContainer: {
        position: 'relative',
        paddingLeft: 20,
        paddingRight: 20,
        width: '100%',
        boxSizing: 'border-box'

    },
    decisionItem: {
        display: 'flex',
        flexDirection: 'row',
        // justifyContent: 'space-between',
        position: 'relative',
        borderBottomWidth: 1,
        borderBottomColor: THEME.INPUT_BORDER,
        borderBottomStyle: 'solid',
        paddingBottom: 15,
        paddingTop: 15

    },
    prevIcon: {
        width: 50,
        height: 50,
        borderRadius: 50,
        marginRight: 15,
        //backgroundColor: '#F7B500',
        padding: 8,
        boxSizing: 'border-box'
    },
    decisionContent: {
        display: 'flex',
        flexDirection: 'column',
        width: '80%'
    },
    question: {
        color: THEME.ORANGE_RED,
        fontSize: 14
        // marginRight: CURRENT_OS === DEVICE_TYPE.IOS ? 43 : 60,
    },
    contentInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 15,
        // marginRight: 43,
    },
    timeStamp: {
        fontSize: 11,
        color: "#777777"
    },
    comments: {
        fontSize: 11,
        color: 'red'
    },

    loadMoreContainer: {
        // height: 60, 
        padding: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
})