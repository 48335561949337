import React, { Component, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DEVICE_TYPE, FONT, THEME } from "../enums";
import { Country, SurveyCategory } from "../interfaces";
import { BottomDialogHoderType, BottomDialogHolder, CustomScrollView, FieldForm, ImageWithSpinner } from "../utils";
import CustomText from "../utils/custom-text";
import { CURRENT_OS } from "../variables";
import '../scss/selector-list.scss';
interface Basic {
    name: string, code: string, url?: any
}
interface Props {
    list: any[];
    countAll?: number,
    selected: any,
    loading: boolean,
    type?: string,
    setItem: (doc: any) => void,
    visible: boolean,
    title: string,
    wrapperHeight: any,
    onClose: () => void,
    onDissmiss?: () => void
}

const SearchDoc = {
    field: 'search',
    label: '',
    secure: false,
    type: 'name',
    placeholder: 'Search...',
    pattern: ''

}

export function SelectorList(props: Props) {
    const {
        list,
        loading,
        countAll,
        selected,
        setItem,
        type,
        visible,
        title,
        wrapperHeight,
        onClose,
        onDissmiss,
    } = props;
    const [search, setSearch] = useState<string>('');
    const dialogRef = useRef<BottomDialogHoderType>(null);

    function handleFieldChange(fieldId, value) {
        setSearch(value);
    }

    return (
        <BottomDialogHolder
            ref={dialogRef}
            wrapperHeight={wrapperHeight}
            visible={visible}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: THEME.WHITE,
                width: '100%',
                height: '100%',
                boxSizing: 'border-box',
                borderTopLeftRadius: 28,
                borderTopRightRadius: 28,
            }}>
                <div style={styles.actionHeader}>
                    <CustomText customStyle={{ color: 'red', fontSize: 18 }}>{title}</CustomText>
                </div>
                <div style={styles.searchContainer}>
                    <FieldForm
                        key={SearchDoc.field}
                        id={SearchDoc.field}
                        onChange={handleFieldChange}
                        secure={SearchDoc.secure}
                        placeholder={SearchDoc.placeholder}
                        type={SearchDoc.type}
                        value={search}
                        pattern={SearchDoc.pattern}
                        label={SearchDoc.label}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', height: '76%', boxSizing: 'border-box', overflowY: 'auto' }}>
                    {type === "basic" && list.map((item, index) => (
                        <ListItem
                            key={index}
                            item={item}
                            selected={selected}
                            onClick={(doc) => { onOptionChange(doc) }}

                        />
                    ))}
                    
                    {type === "surveyCategory" && <CustomScrollView
                        listLength={list.length}
                        countAll={countAll as number}
                        refreshing={loading}
                        onRefresh={() => { }}
                        fetchMore={() => { }}
                    >
                        {list.filter(country => country?.name.toLowerCase().includes(search?.toLowerCase()))
                            .map((item, index) => (
                                <CategoryListItem
                                    key={item.id}
                                    item={item}
                                    selected={selected}
                                    onClick={(doc) => { onOptionChange(doc) }}

                                />
                            ))
                        }
                    </CustomScrollView>}
                </div>

            </div>
        </BottomDialogHolder>
    )

    function onOptionChange(item) {
        if (dialogRef && dialogRef.current) dialogRef.current.closeAnimation(() => setItem(item));
    }
}


interface ListItemProps {
    item: Basic,
    selected: Basic,
    onClick: (doc: Basic) => void,
}


function ListItem(props: ListItemProps) {
    const { item, selected, onClick } = props;
    const { t, i18n } = useTranslation();
    return (
        <div
            // className={"selector-list-item-container"}
            style={{
                // ...{ backgroundColor: pressed ? 'rgba(230,230,230,0.2)' : THEME.WHITE },
                ...styles.countryItemContainer
            }}
            onClick={() => onClick(item)}
        >

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                boxSizing: 'border-box',
            }}>
                <div className={"selector-radio-btn"} style={{
                    ...{ backgroundColor: (selected?.code === item?.code) ? THEME.ORANGE_RED : THEME.WHITE }
                }}></div>
                <div style={styles.innervalue}>
                    {item?.url && <div style={styles.thumbnail}>
                        <ImageWithSpinner
                            uri={item?.url}
                            customStyle={{
                                width: "100%",
                                height: "100%",
                                borderRadius: 10,
                            }}
                            imgStyle={{
                                width: "100%",
                                height: "100%",
                                borderRadius: 10,
                                objectFit: 'contain'
                            }}
                            spinnerSize={15}
                        />
                    </div>}
                    <CustomText customStyle={{}}>{t(item.name)}</CustomText>
                </div>
            </div>

        </div>
    )
}



interface CategoryListItemProps {
    item: SurveyCategory,
    selected: SurveyCategory,
    onClick: (doc: SurveyCategory) => void,
}


function CategoryListItem(props: CategoryListItemProps) {
    const { item, selected, onClick } = props;

    return (
        <div
            // className={"selector-list-item-container"}
            style={{
                // ...{ backgroundColor: pressed ? 'rgba(230,230,230,0.2)' : THEME.WHITE },
                ...styles.countryItemContainer
            }}
            onClick={() => onClick(item)}
        >

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                boxSizing: 'border-box',
            }}>
                <div className={"selector-radio-btn"} style={{
                    ...{ backgroundColor: (selected?.id === item?.id) ? THEME.ORANGE_RED : THEME.WHITE }
                }}></div>
                <div style={styles.innervalue}>
                    {item?.imageUrl && <div style={styles.thumbnail}>
                        <ImageWithSpinner
                            uri={item?.imageUrl}
                            customStyle={{
                                width: "100%",
                                height: "100%",
                                borderRadius: 10,
                            }}
                            imgStyle={{
                                width: "100%",
                                height: "100%",
                                borderRadius: 10,
                                objectFit: 'contain'
                            }}
                            spinnerSize={15}
                        />
                    </div>}
                    <CustomText customStyle={{}}>{item.name}</CustomText>
                </div>
            </div>

        </div>
    )
}

const styles: { [key: string]: React.CSSProperties } = ({
    searchContainer: {
        paddingLeft: 20,
        paddingRight: 20,
        marginBottom: 10,
    },
    actionHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: THEME.INPUT_BORDER,
        borderBottomStyle: 'solid',
        marginBottom: 10
    },
    countryItemContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        minHeight: 60,

        paddingLeft: 20,
        paddingRight: 20,
        borderWidth: 1,
        borderColor: 'rgb(244,244,244)',
        borderStyle: 'solid',
        boxSizing: 'border-box',
    },
    radioBtn: {
        width: 20,
        height: 20,
        borderRadius: 10,
        marginRight: 10,
        marginTop: 3,
        borderWidth: 1,
        borderColor: THEME.INPUT_BORDER,
        borderStyle: 'solid'
    },
    innervalue: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    thumbnail: {
        width: 30, height: 25, marginRight: 10, borderRadius: 10
    },
})