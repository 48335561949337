import * as fromActions from '../actions';
import { ofType, combineEpics, StateObservable } from 'redux-observable';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
 
import { extractError } from '../../services/error-handler';
import AppStorage from '../../services/storage.service';
import { Observable } from 'rxjs';
import { Action } from '@reduxjs/toolkit';
import * as SurveysService from '../../services/surveys.service';
import { MAIN_APP_ROUTES } from '../../routes';
import * as fromSelectors from '../selectors';

const getMyCompletedSurveysEpic = (action$: Observable<Action>, state$: StateObservable<any>): Observable<Action> => action$.pipe(
    ofType(fromActions.getMyCompletedSurveys),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const myCompletedSurveyState = fromSelectors.selectMyCompletedSurveysState(state);
        return SurveysService.getMyCompletedSurveys({ page: 0, limit: myCompletedSurveyState.limit })
            .then(response => fromActions.getMyCompletedSurveysSuccess(response))
            .catch(error => fromActions.getMyCompletedSurveysError(extractError(error, MAIN_APP_ROUTES.MY_SURVEYS)))
    })
);

const getMoreMyCompletedSurveysEpic = (action$: Observable<Action>, state$: StateObservable<any>): Observable<Action> => action$.pipe(
    ofType(fromActions.getMoreMyCompletedSurveys),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const myCompletedSurveyState = fromSelectors.selectMyCompletedSurveysState(state);
        return SurveysService.getMyCompletedSurveys({ page: myCompletedSurveyState.page + 1, limit: myCompletedSurveyState.limit })
            .then(response => fromActions.getMoreMyCompletedSurveysSuccess(response))
            .catch(error => fromActions.getMoreMyCompletedSurveysError(extractError(error, MAIN_APP_ROUTES.MY_SURVEYS)))
    })
);

const postMyCompletedSurveyEpic = (action$: Observable<Action>) : Observable<Action> => action$.pipe(
    ofType(fromActions.postMyCompletedSurvey.type),
    map((action: any) => action.payload),
    switchMap((payload) => {
        const question  = SurveysService.getQuestionSendFormat(payload);
        return SurveysService.postMySurvey(question)
            .then(response => {
                // navigate(MAIN_APP_ROUTES.MY_SURVEYS);
                return fromActions.postMyCompletedSurveySuccess(response);
            })
            .catch(error => fromActions.postMyCompletedSurveyError(extractError(error, MAIN_APP_ROUTES.MY_SURVEYS)))
    })
);


// const uploadImageEpic = (action$: Observable<Action>, state$: StateObservable<any>): Observable<Action> => action$.pipe(
//     ofType(fromActions.uploadMyCompletedSurveyImage),
//     map((action: any) => action.payload),
//     switchMap(async (payload: any) => {
//         try {
//             if (payload.imageType === 'question' && payload.data.imagePath !== null) {
//                 const res = await SurveysService.uploadQuestionImage(payload.data);
//                 return 
//             }
//         } catch (error) {
//             return fromActions.postMyCompletedSurveyError(extractError(error))
//         }
//     })
// )


const updateMyCompletedSurveyEpic = (action$: Observable<Action>) : Observable<Action> => action$.pipe(
    ofType(fromActions.updateMyCompletedSurvey),
    map((action: any) => action.payload),
    switchMap((payload) => {
        return SurveysService.updateQuestion(payload)
            .then((res) => fromActions.updateMyCompletedSurveySuccess(res))
            .catch(error => fromActions.updateMyCompletedSurveyError(extractError(error, MAIN_APP_ROUTES.POST_QUESTION)))
    })
)

const deleteMyCompletedSurveyEpic = (action$: Observable<Action>) : Observable<Action> => action$.pipe(
    ofType(fromActions.deleteMyCompletedSurvey),
    map((action: any) => action.payload),
    switchMap((payload) => {
        return SurveysService.deleteQuestion(payload)
            .then((res) => fromActions.deleteMyCompletedSurveySuccess(payload))
            .catch(error => fromActions.deleteMyCompletedSurveyError(extractError(error, MAIN_APP_ROUTES.POST_QUESTION)))
    })
)

const deleteMyCompletedSurveySuccessEpic = (action$: Observable<Action>) : Observable<Action> => action$.pipe(
    ofType(fromActions.deleteMyCompletedSurveySuccess),
    switchMap(() => [fromActions.toastMessage('Removed successfully')])
);

export const myCompletedSurveysEpics = combineEpics(
    getMyCompletedSurveysEpic,
    getMoreMyCompletedSurveysEpic,
    postMyCompletedSurveyEpic,
    updateMyCompletedSurveyEpic,
    deleteMyCompletedSurveyEpic,
    deleteMyCompletedSurveySuccessEpic
);