//const axios = require('axios');
import axios, { AxiosInstance } from 'axios';
import { environment } from '../app.config';
import AppStorage from './storage.service';

const axiosInstance: AxiosInstance = axios.create({
    baseURL: environment.BASE_API_URL,
    headers: { 'Content-Type': 'application/json', 'Authorization': '' }
});

// request interceptor 
axiosInstance.interceptors.request.use((config) => {
    // Do something before request is sent 
    //If the header does not contain the token and the url not public, redirect to login  
    const idToken = AppStorage.getIdToken();
    if (idToken && config.method !== 'OPTIONS') {
        config.headers.Authorization = 'Bearer ' + idToken;
        return config;
    };
    return config;

}, (error) => {
    // AppLogger.error("request interceptor error\n", error);
    // Do something with request error 
    return error;
});
// axiosInstance.interceptors.response.use((response)=> {
//     // Do something with response data
//     console.dir(response, response.status);

//     return response;
// }, (error) => {
//     // Do something with response error
//     console.dir(error);
//     console.dir(error.response);
//     return error;
// });
export default axiosInstance;