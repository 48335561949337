import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { SearchBar, CustomButton } from "../components";
import { DEVICE_TYPE, FONT, THEME } from "../enums";
import { AppError, FriendRequest, GlobalContactInfo } from "../interfaces";
import CustomText from "../utils/custom-text";
import { currentWindowHeight, currentWindowWidth, CURRENT_OS, } from "../variables";
import * as fromStore from "../store";
import { CustomScrollView } from "../utils";
import { checkErrorResponse, getUserName } from "../services";
// import { Icon } from "react-native-elements";
// import * as ContactsService from '../services/contacts.service';
// import { ScrollView } from "react-native-gesture-handler";
// import SpinnerScreen from "../utils/spinner.screen";
import { FriendRequestItem } from "./friends-recieved";
import { ContactIcon } from "../icons";
// import { TabView, TabBar, SceneMap } from 'react-native-tab-view';

const actionSize = currentWindowWidth() / 3;
const halfSize = currentWindowHeight() / 2;
const contactsWindowHeight = halfSize + halfSize / 2 - 10;

export function AddFriendsScreen(props: any) {
    const { history, location, match } = props;
    const { t, i18n } = useTranslation();
    let delayTimer;
    const dispatch = useDispatch();
    const [search, setSearch] = useState<string>('');
    // const [prevSearch, setPrevSearch] = useState<string>('');
    const [onSearch, setOnSearch] = useState<boolean>(false);

    const searchFriends = useSelector((state) => fromStore.selectSearchFriendList(state));
    const searchFriendState = useSelector((state) => fromStore.selectSearchFriendState(state));
    const friendRquests = useSelector((state) => fromStore.selectFriendRequests(state));
    const friendRquestsState = useSelector((state) => fromStore.selectFriendRequestsState(state));
    // const sentRquests = useSelector((state) => fromStore.selectSentRequests(state));

    const acceptState = useSelector((state) => fromStore.selectAcceptFriendState(state));
    const rejectState = useSelector((state) => fromStore.selectRejectFriendState(state));
    const addState = useSelector((state) => fromStore.selectAddFriendState(state));
    const contacts = useSelector((state) => fromStore.selectGlobalContacts(state));
    const { loading, error, countAll } = useSelector((state) => fromStore.selectGlobalContactsState(state));

    useEffect(() => {
        getContactsInfo()
        return () => {
            dispatch(fromStore.removeGlobalContacts());
        }
    }, []);

    function getContactsInfo() {
        dispatch(fromStore.getGlobalContacts());
        // if(friendRquests && friendRquests.length === 0) dispatch(fromStore.getSentRequests());
        if (friendRquests && friendRquests.length === 0) dispatch(fromStore.getFriendRequests());
    }


    // function filterContacts() {
    //     return contacts
    //         .filter(doc => {
    //             return doc.email && doc?.email.toLowerCase().includes(search?.toLowerCase()) ||
    //                 getUserName(doc) && getUserName(doc)?.toLowerCase().replace(/ /g, '').includes(search?.toLowerCase().replace(/ /g, ''));
    //         })
    // }




    return (
        <div style={styles.screenContent}>
            <div style={{ width: '100%', boxSizing: 'border-box' }}>
                <SearchBar
                    id={"search"}
                    type={"none"}
                    value={search}
                    placeholder={t("searchPeople")}
                    placeholderTextColor={THEME.PLACEHOLDER}
                    onChange={(id, value) => searchUser(value)}
                    onFocus={(event) => {
                        if (!search) setOnSearch(true);
                    }}
                    onBlur={(event) => {
                        if (!search) setOnSearch(false)
                    }}
                />
            </div>
            <div style={{ marginTop: 10, marginLeft: 10 }}>
                {/* t('suggestedPeople') */}
                <CustomText customStyle={{ opacity: 0.7 }}>{''}</CustomText>
            </div>


            <div style={styles.listHolder}>
                {(search) ?
                    <CustomScrollView
                        listLength={searchFriends.length}
                        countAll={searchFriendState.countAll}
                        emptyTitle={search ? t('userNotFound') : t('searchUser')}
                        error={searchFriendState.error}
                        refreshing={searchFriendState.loading}
                        onToast={displayToastMessage}
                        onRefresh={() => searchUser(search)}
                        fetchMore={() => { }}
                    >
                        {searchFriends.map((item, index) => (
                            <CurrentUserState
                                key={item.id}
                                item={item}
                                friendRquests={friendRquests}
                                acceptState={acceptState}
                                rejectState={rejectState}
                                onRequestAccept={onRequestAccept}
                                onRequestReject={onRequestReject}
                                addState={addState}
                                onAddFriend={onAddFriend}
                            />
                        ))}
                    </CustomScrollView>
                    :
                    <CustomScrollView
                        listLength={contacts.length}
                        countAll={countAll}
                        emptyTitle={t('noUsers')}
                        error={error}
                        refreshing={loading}
                        onToast={displayToastMessage}
                        onRefresh={getContactsInfo}
                        fetchMore={() => { dispatch(fromStore.getMoreGlobalContacts()); }}
                    >
                        {contacts.map((item, index) => (
                            <CurrentUserState
                                key={item.id}
                                item={item}
                                friendRquests={friendRquests}
                                acceptState={acceptState}
                                rejectState={rejectState}
                                onRequestAccept={onRequestAccept}
                                onRequestReject={onRequestReject}
                                addState={addState}
                                onAddFriend={onAddFriend}
                            />
                        ))}
                    </CustomScrollView>

                }
            </div>


        </div>
    );

    function displayToastMessage(error) {
        if (!error) return;
        dispatch(fromStore.toastMessage(checkErrorResponse(error)));
    }

    function searchUser(value: string) {
        setSearch(value);
        if (!value) {
            dispatch(fromStore.removeSearchFriend());
            if (delayTimer) clearTimeout(delayTimer);
            // setPrevSearch('');
            return;
        }
        // if (value.length < 3) {
        //     dispatch(fromStore.removeSearchFriend());
        //     if (delayTimer) clearTimeout(delayTimer);
        //     // setPrevSearch('');
        //     return;
        // }

        // if(prevSearch === value) {
        //     if (delayTimer) clearTimeout(delayTimer);
        //     return;
        // }
        // setPrevSearch(value);
        if (delayTimer) clearTimeout(delayTimer);
        delayTimer = setTimeout(function () {
            // Do the ajax stuff
            dispatch(fromStore.searchFriend(value));
        }, 1600); // Will do the ajax stuff after 1000 ms, or 1 s


    }

    function onAddFriend(doc: GlobalContactInfo) {
        dispatch(fromStore.addFriend({
            recipientUser: {
                id: doc.id,
                login: doc.login,
                firstName: doc.firstName,
                lastName: doc.lastName,
                email: doc.email,
            }
        }));
    }

    function onRequestAccept(doc: FriendRequest) {
        dispatch(fromStore.acceptFriend(doc.id));
    }

    function onRequestReject(doc: FriendRequest) {
        dispatch(fromStore.rejectFriend(doc.id));
    }





}


function CurrentUserState(props) {
    const friend = (props.friendRquests && props.friendRquests.length > 0) ? props.friendRquests.find(doc => doc.requestedUser.id === props.item.id) : null;
    // const sentRquest = sentRquests.find(doc => doc.recipientUser.id === item.id);
    if (friend) {
        return (
            <FriendRequestItem
                item={friend}
                acceptState={props.acceptState}
                rejectState={props.rejectState}
                onAccept={props.onRequestAccept}
                onReject={props.onRequestReject}
            />
        )
    }

    return (
        <UserContact
            key={props.item.id}
            item={props.item}
            // isSent={props.sentRquest ? true : false}
            addState={props.addState}
            onAddFriend={props.onAddFriend}
        />
    )
}


interface UserContactProps {
    item: GlobalContactInfo,
    addState?: any,
    isSent?: boolean,
    onAddFriend: (doc: GlobalContactInfo) => void,

}
function UserContact(props: UserContactProps) {
    const {
        item,
        addState,
        isSent,
        onAddFriend,
    } = props;
    const { t, i18n } = useTranslation();

    return (
        <div style={styles.contactPressable}>

            <div style={styles.contactContainer}>
                <div style={styles.contactInfo}>
                    <div style={styles.contactIcon}>
                        <img
                            src={item.profilePicUrl ? item.profilePicUrl : ContactIcon}
                            style={{
                                height: "100%",
                                width: "100%",
                                backgroundColor: "transparent",
                                borderRadius: 25,
                                objectFit: 'cover'
                            }}
                        />
                    </div>
                    <div style={{
                        width: '75%',
                        whiteSpace: "nowrap",
                        overflowX: "hidden",
                        textOverflow: "ellipsis",
                    }}>
                        <CustomText customStyle={{ fontSize: 14 }} ellipsizeMode={"tail"} numberOfLines={2}>{getUserName(item)}</CustomText>
                    </div>
                </div>
                <div style={{ width: '30%' }}>
                    <RequestState />
                </div>

            </div>

        </div>

    )


    function RequestState() {

        if (item.request === "SENT" || isSent) {
            return (
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end'
                }}>
                    {/* <CustomText customStyle={{ color: 'green' }}>{t('invited')}</CustomText> */}
                    <CustomButton
                        customStyle={{}}
                        textStyle={{ textTransform: 'uppercase', color: '#fff' }}
                        name={t('invited')}
                        padding={10}
                        enable={false}
                        onClick={() => { }}
                    />
                </div>
            )
        }

        if (item.request === "NA" || item.request === "DECLINED") {
            return (
                <CustomButton
                    customStyle={{}}
                    textStyle={{ textTransform: 'uppercase' }}
                    loading={addState.id === item.id ? addState.loading : false}
                    name={t("addFriend")}
                    padding={10}
                    enable={true}
                    onClick={() => onAddFriend(item)}
                />
            )
        }

        return null
    }
}



const styles: { [key: string]: React.CSSProperties } = ({
    // Screen container styles

    screenContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        boxSizing: 'border-box'
    },
    screenHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 15,
        borderBottomWidth: 1,
        borderBottomColor: THEME.DEFAULT_BORDER,
        borderBottomStyle: 'solid'
    },
    shdtxt: {
        fontSize: 17,
        color: THEME.ORANGE_RED,
        textTransform: 'uppercase',
    },
    screenContent: {
        width: '100%',
        height: '100%',
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: THEME.WHITE,
        boxSizing: 'border-box'
    },

    listHolder: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
        marginLeft: 10,
        height: '85%',
        overflowY: 'auto'
    },

    contactPressable: {
        paddingTop: 10, paddingBottom: 10, borderBottomWidth: 1, borderBottomColor: THEME.INPUT_BORDER, borderBottomStyle: 'solid'
    },
    contactContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    contactInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '65%',

    },
    contactIcon: {
        width: 45, height: 45, borderRadius: 25, marginRight: 20
    },
    indicator: {
        width: 24,
        height: 24,
        padding: 2,
        borderWidth: 1,
        borderColor: THEME.INPUT_BORDER,
        borderStyle: 'solid',
        borderRadius: 12,
    },
    refreshError: {
        display: 'flex',
        flexDirection: 'column',
        height: '85%'
    }
})