import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineClose } from "react-icons/ai";
import { THEME } from "../enums";
import { ActionDialogHolder, ActionDialogHolderType } from "../utils";
import CustomText from "../utils/custom-text";
import { FILE_SIZE_LIMIT } from "../variables";


interface Props {
    visible: boolean,
    type?: 'image' | 'video',
    onClose: () => void,
    onDissmiss?: () => void
}

export function UploadInfoModel(props: Props) {
    const {
        visible,
        type,
        onClose,
        onDissmiss
    } = props;
    const { t, i18n } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={'65%'}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }}>
                <div style={{
                    padding: 10,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    borderBottomWidth: 1,
                    borderBottomColor: THEME.INPUT_BORDER,
                    borderBottomStyle: 'solid',
                }}>
                    <CustomText customStyle={{ fontSize: 17, color: THEME.ORANGE_RED }}>{t('allowedFormat')}</CustomText>
                </div>
                <div style={{
                    padding: 15,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                }}>
                    <CustomText customStyle={{ fontSize: 14, textAlign: 'center' }}>{t(type == 'image' ? 'allowedImageInfo' : 'allowedVideoInfo', { limit: FILE_SIZE_LIMIT })}</CustomText>
                </div>
                <div style={{
                        position: 'absolute',
                        right: -6,
                        top: -6,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 5,
                        backgroundColor: 'red',
                        borderRadius: 50,
                        width: 25,
                        height: 25,
                        cursor: 'pointer',
                    }}
                    onClick={closePopup}
                >
                    <AiOutlineClose size={20} color={THEME.WHITE}/>
                </div>

            </div>
        </ActionDialogHolder>

    );

    function closePopup() {
        if (actionRef && actionRef.current) actionRef.current.closeAction();
    }

}