import React from "react";
import { THEME } from "../enums";
import { MySurvey } from "../interfaces";
import { getCustomDateFormat } from "../services";
import { IconProvider, getIconNameByQuestionType, ImageWithSpinner } from "../utils";
import CustomText from "../utils/custom-text";
import { AiOutlineCheck, AiOutlineBook } from 'react-icons/ai'
import { DeleteIcon } from "./delete-icon";
import { SurveyTimer } from "./survey-timer";

interface DecisionItemProps {
  item: MySurvey,
  state: any,
  isCompleted: boolean,
  onClick: (doc: MySurvey) => void,
  onDelete: (doc: MySurvey) => void
}

export function DecisionItem(props: DecisionItemProps) {
  const { item, state, onClick, onDelete, isCompleted } = props;
  const { type, id, category } = item;


  return (
    <div
      className={"on-press-cls"}
      style={{
        ...styles.decisionContainer,
        // ...{ backgroundColor: pressed ? 'rgba(220,220,220,0.2)' : THEME.WHITE },
      }}
    // onClick={() => onClick(item)}
    >

      <div style={styles.prevIcon} onClick={() => onClick(item)}>
        {(category && category.imageUrl) ? <ImageWithSpinner
          resizeMode={"contain"}
          customStyle={{
            height: "100%",
            width: "100%",
            backgroundColor: "transparent",
            borderRadius: 45,
          }}
          imgStyle={{
            height: "100%",
            width: "100%",
            backgroundColor: "transparent",
            borderRadius: 45,
          }}
          uri={category.imageUrl}
          spinnerSize={20}
        /> : <IconProvider type={getIconNameByQuestionType(type)} />}
      </div>

      <div className={"list-item-cls"} style={styles.decisionContent}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 5, }} onClick={() => onClick(item)}>
          <CustomText customStyle={styles.question}>{item.text}</CustomText>
          {isCompleted && <div style={{ padding: 10 }}><AiOutlineCheck style={{ minHeight: 20, minWidth: 20 }} color={'green'} size={20} /></div>}
        </div>
        {(isCompleted === false) && <SurveyTimer timeStamp={item.endTime} />}

        <div style={{ ...styles.contentInfo }}>
          <CustomText customStyle={styles.timeStamp}>
            {item.startTime ? getCustomDateFormat(item.startTime) : ''}
          </CustomText>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            {/* <AiOutlineBook
              style={{ marginRight: 10 }}
              color={'red'}
            // size={12}
            // type={'ionicon'}
            // name="book-outline"
            /> */}
            <CustomText customStyle={styles.comments}>{`${item.commentsCount} Comment`}</CustomText>

          </div>
          {isCompleted &&
            <DeleteIcon
              customStyle={{ padding: 10, borderRadius: 50 }}
              item={item}
              state={state}
              onClick={onDelete}
            />}
        </div>


      </div>
    </div >
  );
}

const styles: { [key: string]: React.CSSProperties } = {

  //Decision item styles

  decisionContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    justifyContent: 'space-between',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 15,
    paddingBottom: 15,
    width: '100%',
    cursor: 'pointer',
    boxSizing: 'border-box'
  },
  decisionItem: {
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'space-between',
    position: 'relative',
  },
  prevIcon: {
    width: 50,
    height: 50,
    borderRadius: 50,
    marginRight: 15,
    //backgroundColor: '#F7B500',
    padding: 8,
    boxSizing: 'border-box'
  },
  decisionContent: {
    // flex: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly'
  },
  question: {
    // marginRight: CURRENT_OS === DEVICE_TYPE.IOS ? 43 : 60,
  },
  contentInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    // marginTop: 10,
    // marginRight: 43,
  },
  timeStamp: {
    fontSize: 11,
    color: '#777777',
    marginTop: 10,
    marginRight: 10,
    marginBottom: 10,
  },
  comments: {
    fontSize: 11,
    color: 'red',
  },

  loadMoreContainer: {
    // height: 60,
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  refreshError: {
    display: 'flex',
    flexDirection: 'column',
    height: '90%',
  }
};
