import React, { Component } from 'react';
import { DEVICE_TYPE, QUESTION_TYPE, THEME } from '../enums';
import { ContactInfo, Draft, GroupInfo, ImageResponse, MultipleChoiceSurveyForm, MySurvey, SharedToGrupsForm, SharedUser, ShortAnswerSurveyForm, SingleOptionSurveyForm, SurveyComment, SurveyOption } from '../interfaces';
import { MAIN_APP_ROUTES } from '../routes';
import { CURRENT_OS } from '../variables';
import { getDurationByDate, getImageFormData, getVideoFormData } from './helpers.services';
import restService from './rest.service';
import * as MediaService from './media.service';

export const getAllCategories = (doc: {page: number, limit: number}) => {
    return restService.getAllCategories(doc).then(response => {
        return {
            ...doc,
            countAll: parseInt(response.headers["x-total-count"]),
            result: response.data
        };
    });
}


//Question
export const searchSurveys = (value: string) => {
    return restService.searchQuestions(value).then(response => {
        return response.data;
    })
}

export const getMySurveysById = (id: string) => {
    return restService.getQuestionById(id).then(response => response.data);
}

export const getSurveyById = (id: string) => {
    return restService.getQuestionById(id).then(response => response.data);
}

export const getInvitedSurveyById = (key: string) => {
    return restService.getInvitedQuestion(key).then(response => response.data);
}

export const getMySurveys = (doc: {page: number, limit: number}) => {
    return restService.getMyQuestions(doc).then(response => {
        return {
            ...doc,
            countAll: parseInt(response.headers["x-total-count"]),
            result: response.data
        };
    });
}

export const getMyActiveSurveys = (doc: {page: number, limit: number}) => {
    return restService.getMyActiveQuestions(doc).then(response => {
        return {
            ...doc,
            countAll: parseInt(response.headers["x-total-count"]),
            result: response.data
        };
    });
}

export const getMyCompletedSurveys = (doc: {page: number, limit: number}) => {
    return restService.getMyCompletedQuestions(doc).then(response => {
        return {
            ...doc,
            countAll: parseInt(response.headers["x-total-count"]),
            result: response.data
        };
    });
}

export const postMySurvey = (doc: any) => {
    return restService.postMyQuestionToGlobal(doc).then(response => response.data);
}

export const updateQuestion = (doc: any): Promise<any> => {
    return restService.updateQuestion(doc).then(response => response.data);
}

export const updateQuestionWithPatch = (doc: any): Promise<any> => {
    return restService.updateQuestionWithPatch(doc).then(response => response.data);
}

export const deleteQuestion = (id: string): Promise<any> => {
    return restService.deleteQuestion(id).then(response => response.data);
}

export const shareMySurvey = (doc) => {
    return restService.shareMyQuestion(doc).then(response => response.data);
}

export const getSharedSurveys = (doc: {page: number, limit: number}) => {
    return restService.getSharedQuestions(doc).then(response => {
        return {
            ...doc,
            countAll: parseInt(response.headers["x-total-count"]),
            result: response.data
        };
    });
}

export const getOthersSurveys = () => {
    return restService.getOthersQuestions().then(response => response.data);
}

//Answers
export const getSurveyAnswers = (id: string) => {
    return restService.getAllSurveyAnswers(id).then(response => response.data);
}

export const getMySurveyAnswer = (id: string) => {
    return restService.getMySurveyAnswer(id).then(response => response.data);
}

export const postMyAnswer = (doc: any) => {
    return restService.postMyAnswer(doc).then(response => response.data);
}

export const updateMyAnswer = (doc: any) => {
    return restService.updateMyAnswer(doc).then(response => response.data);
}

//Comments
export const getComments = (id: string) => {
    return restService.getComments(id).then(response => response.data);
}

export const postComment = (doc: any) => {
    return restService.postComment(doc).then(response => response.data);
}

export const deleteComment = (id: string) => {
    return restService.deleteComment(id).then(response => response.data);
}

export const uploadCommentMedia = (doc, onProgress?: (event) => void) => {
    return MediaService.mediaUpload('comments/video', getVideoFormData(doc), onProgress).then(response => response.data);
    // return restService.uploadCommentMedia(media).then(response => response.data);
}


//Image

export const uploadQuestionImage = (doc, onProgress?: (event) => void) => {
    return MediaService.mediaUpload('questions/image', getImageFormData(doc), onProgress).then(response => response.data);
    // return restService.uploadQuestionImage(getImageFormData(doc)).then(response => response.data);
}

export const uploadOptionImage = (doc, onProgress?: (event) => void) => {
    return MediaService.mediaUpload('options/image', getImageFormData(doc), onProgress).then(response => response.data);
    // return restService.uploadOptionImage(getImageFormData(doc)).then(response => response.data);
}




export const getQuestionSendFormat = (doc: any) => {
    const { duration, options } = doc;
    const startTime = new Date();
    const endTime = new Date();
    const data = new FormData()
    endTime.setMinutes(endTime.getMinutes() + duration.code);
    if (doc.type === QUESTION_TYPE.SINGLE_OPTION) {

        return {
            type: doc.type,
            text: doc.question,
            code: null,
            imagePath: null,
            startTime: startTime.toISOString(),
            endTime: endTime.toISOString(),
            category: doc.category,
            options: [
                {
                    value: "Yes",
                    imagePath: null
                },
                {
                    value: "No",
                    imagePath: null
                }
            ]
        }
    } else if (doc.type === QUESTION_TYPE.MULTIPLE_CHOICE) {

        return {
            type: doc.type,
            text: doc.question,
            code: null,
            imagePath: null,
            startTime: startTime.toISOString(),
            endTime: endTime.toISOString(),
            category: doc.category,
            options: Object.keys(options).map((key) => options[key])
        }
    } else if (doc.type === QUESTION_TYPE.SHORT_ANSWER) {

        return {
            type: doc.type,
            text: doc.question,
            code: null,
            imagePath: null,
            startTime: startTime.toISOString(),
            endTime: endTime.toISOString(),
            category: doc.category,
            options: []
        }
    }

}

export function getRouteNameByQuestionType(type) {
    switch (type) {
        case QUESTION_TYPE.SINGLE_OPTION:
            return MAIN_APP_ROUTES.SINGLE_OPTION;
        case QUESTION_TYPE.MULTIPLE_CHOICE:
            return MAIN_APP_ROUTES.MULTIPLE_CHOICE;
        case QUESTION_TYPE.SHORT_ANSWER:
            return MAIN_APP_ROUTES.SHORT_ANSWER;
    }
}

export function getContactsIds(docs: ContactInfo[]): Array<{ id: string }> {
    return docs.map(doc => {
        return {
            id: doc.recipientUser.id,
        }
    })
}

export function getSharedUserIds(docs: SharedUser[]): Array<{ id: string }> {
    return docs.map(doc => {
        return {
            id: doc.id,
        }
    })
}

export function getSharedGroupIds(docs: GroupInfo[]): Array<SharedToGrupsForm> {
    return docs.map(doc => {
        return {
            id: doc.id,
            createdByUser: {
                id: doc.createdByUser.id,
            },
            members: getSharedUserIds(doc.members),
        }
    })
}

export function isSurveyActive(endTime: string) {
    const date = new Date();
    const endDate = new Date(endTime);
    if (date < endDate) return true;
    return false;
}

export function isInactiveSurvey(endTime: string) {
    const date = new Date();
    const endDate = new Date(endTime);
    if (date > endDate) return true;
    return false;
}

export function getQuestionInitObject(doc: MySurvey) {
    const { type } = doc;
    if (type === QUESTION_TYPE.SINGLE_OPTION) {
        return ({
            ...doc,
            nameTheSurvey: doc.nameTheSurvey ? doc.nameTheSurvey : '',
            duration: getDurationByDate(doc.startTime, doc.endTime),
            image: null,
        } as SingleOptionSurveyForm)
    }
    if (type === QUESTION_TYPE.SHORT_ANSWER) {
        return ({
            ...doc,
            nameTheSurvey: doc.nameTheSurvey ? doc.nameTheSurvey : '',
            duration: getDurationByDate(doc.startTime, doc.endTime),
            image: null,
        } as ShortAnswerSurveyForm)
    }
    if (type === QUESTION_TYPE.MULTIPLE_CHOICE) {
        return ({
            ...doc,
            nameTheSurvey: doc.nameTheSurvey ? doc.nameTheSurvey : '',
            duration: getDurationByDate(doc.startTime, doc.endTime),
            options: convertArrayToObj(doc.options)
        } as MultipleChoiceSurveyForm)
    }


    // return {
    //     id: undefined,
    //     type: QUESTION_TYPE.MULTIPLE_CHOICE,
    //     text: '',
    //     category: '',
    //     nameTheSurvey: '',
    //     duration: '',
    //     options: undefined
    // }
}

export function initMultiChoiceOptions(options: any[]) {
    return options.map((option, index) => {
        return {
            field: `${index}`,
            label: 'option',
            optionNumber: index,
            required: true,
            secure: false,
            type: 'option',
            textContentType: 'none',
            placeholder: 'Enter Option',
            backgroundColor: THEME.INPUT_BACKGROUND,
            remove: (index === 0 || index === 1) ? false : true,
        }
    })
}

export function convertArrayToObj(array: SurveyOption[]) {
    if (!array) return {};
    const obj = {
        ...array.map(doc => {
            return { ...doc, image: null }
        })
    }
    return obj;

}

export function getUpdateQuestionObj(doc: any) {
    return {
        id: doc.id,
        text: doc.text,
        type: doc.type,
        category: doc.category,
        nameTheSurvey: doc.nameTheSurvey,
        imagePath: doc.imagePath,
        imageUrl: doc.imageUrl,
        options: doc.options,
        endTime: doc.endTime,
        startTime: doc.startTime,
        user: doc.user,
        createdAt: doc.createdAt,
        modifiedAt: doc.modifiedAt,
        isDraft: doc.isDraft,
        // groups: doc.groups,
        // shares: doc.shares,
        // answers: doc.answers,
    }

}

export function getImagePath(response: ImageResponse | null, imagePath?: any) {
    if (response) return response.path;
    else if (imagePath) return imagePath;
    else return null;
}

export function getOptions(type: string) {
    if (type === QUESTION_TYPE.SHORT_ANSWER) return [];
    else if (type === QUESTION_TYPE.SINGLE_OPTION) return [
        {
            value: "Yes",
            imagePath: null
        },
        {
            value: "No",
            imagePath: null
        }
    ]
}

