import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FONT, THEME } from "../enums";
import { MAIN_APP_ROUTES } from "../routes";
import { ActionDialogHolder, ActionDialogHolderType, ActionMessage, CustomScrollView } from "../utils";
import * as fromStore from "../store";
import { checkErrorResponse, isCompleted } from "../services";
import { DecisionItem } from "../components";

export function MySurveysScreen(props: any) {
    const { history, location } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const surveys = useSelector((state) => fromStore.selectMyCompletedSurveys(state));
    const mySurveyState = useSelector(state => fromStore.selectMyCompletedSurveysState(state));
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [visible, setVisible] = useState<boolean>(false);
    const [delId, setDelId] = useState<string | null>(null);


    useEffect(() => {
        if (surveys.length === 0 && !mySurveyState.error) getMySurveysCallback();
        return () => {
        }
    }, [])

    function getMySurveysCallback() {
        dispatch(fromStore.getMyCompletedSurveys());
    }
    function displayToastMessage(error) {
        if (!error) return;
        dispatch(fromStore.toastMessage(checkErrorResponse(error)));
    }

    return (
        <div style={styles.container}>
            <CustomScrollView
                listLength={surveys.length}
                countAll={mySurveyState.countAll}
                error={mySurveyState.error}
                emptyTitle={t('noNewSurveys')}
                onToast={displayToastMessage}
                refreshing={mySurveyState.loading}
                onRefresh={getMySurveysCallback}
                fetchMore={() => { dispatch(fromStore.getMoreMyCompletedSurveys()); }}
            >
                {surveys.map((item, index) => (
                    <DecisionItem
                        key={item.id}
                        item={item}
                        isCompleted={isCompleted(item)}
                        state={mySurveyState.delete}
                        onClick={doc => history.push(`${MAIN_APP_ROUTES.SURVEY_RESULTS}/${doc.id}`, { id: doc.id })}
                        onDelete={(item) => { setVisible(true); setDelId(item.id) }}

                    />
                ))}
            </CustomScrollView>
            {visible && <ActionDialogHolder
                ref={actionRef}
                visible={visible}
                wrapperWidth={'65%'}
                onClose={() => { }}
                onDissmiss={() => { }}
            >
                <ActionMessage
                    text={t('dialog.deleteSurvey')}
                    onCancel={onCancelDelete}
                    onAccept={onConfirmDelete}
                    headerText={t('attention!')}
                    actions={[`${t('yes')}`, `${t('no')}`]}
                />
            </ActionDialogHolder>}
        </div>
    );

    function onCancelDelete() {
        if (actionRef && actionRef.current) actionRef.current.closeAction(() => {
            setVisible(false);
            setDelId(null);
        })

    }

    function onConfirmDelete() {
        if (actionRef && actionRef.current) actionRef.current.closeAction(() => {
            if (delId) dispatch(fromStore.deleteMyCompletedSurvey(delId));
            setVisible(false);
        })

    }

}

const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: THEME.WHITE,
        height: '100%'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 20
    },
    headerTxt: {
        fontSize: 18,
        fontFamily: FONT.MANROPE_REGULAR,
        color: THEME.ORANGE_RED
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 20,
        paddingLeft: 25,
        paddingRight: 25,
    },
    btn: {
        marginBottom: 20,
    },

    //Decision screen

    action: {
        marginTop: 20,
        paddingLeft: 25,
        paddingRight: 25,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },

    decisions: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
    },

})