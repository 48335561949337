import * as fromActions from '../actions';
import { ofType, combineEpics } from 'redux-observable';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import * as AuthService from '../../services/auth.service';
import { extractError } from '../../services/error-handler';
import { LOGIN_SIGNUP_ROUTES, MAIN_APP_ROUTES, ADD_EMAIL } from '../../routes';
import { IdToken, User } from '../../interfaces';
import { Action } from '@reduxjs/toolkit';
import { Observable, of } from 'rxjs';
import AppStorage from '../../services/storage.service';

/*-------------- Facebook Login ------------------------*/
//facebook side effects/epics
const facebookLoginEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.facebookLogin),
    map((action: any) => action.payload),
    switchMap((payload) => {
        return AuthService.facebookLoginRequest(payload)
            .then((response: IdToken) => fromActions.facebookLoginSuccess(response))
            .catch(error => fromActions.facebookLoginError(extractError(error, LOGIN_SIGNUP_ROUTES.LOGIN)))
    })
);

// const getFacebookUserDetailsEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
//     ofType(fromActions.getFacebookUserDetails),
//     switchMap(() => {
//         return AuthService.facebookUserDataRequest()
//             .then((response : IdToken) => fromActions.facebookLoginSuccess(response))
//             .catch(error => {
//                 AuthService.logoutFacebookSignin();
//                 return fromActions.facebookLoginError(extractError(error, LOGIN_SIGNUP_ROUTES.LOGIN))
//             })
//     })
// )

const facebookLoginSuccessEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.facebookLoginSuccess),
    map((action: any) => action.payload),
    switchMap((response: IdToken) => [fromActions.saveUserIdToken(response)]),
);

/*-------------- Google Login ------------------------*/
//google side effects/epics
const googleLoginEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.googleLogin),
    map((action: any) => action.payload),
    switchMap((payload) => {
        return AuthService.googleLoginRequest(payload)
            .then((response: IdToken) => {
                return fromActions.googleLoginSuccess(response);
            })
            .catch(error => {
                const newError = AuthService.checkGoogleStatusCodes(error);
                return fromActions.googleLoginError(extractError(newError, LOGIN_SIGNUP_ROUTES.LOGIN))
            })
    })

);

const googleLoginSuccessEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.googleLoginSuccess),
    map((action: any) => action.payload),
    switchMap((response: IdToken) => [fromActions.saveUserIdToken(response)]),
);

/*-------------- Apple Login ------------------------*/

//apple login side effects/epics
// const appleLoginEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
//     ofType(fromActions.appleLogin),
//     switchMap(() => {
//         return AuthService.appleLoginRequest()
//             .then((response: IdToken) => {
//                 return fromActions.appleLoginSuccess(response);
//             })
//             .catch(error => {
//                 const newError = AuthService.checkAppleStatusCodes(extractError(error, LOGIN_SIGNUP_ROUTES.LOGIN));
//                 return fromActions.appleLoginError(newError != null ? newError : null)
//             })
//     })

// );

const appleLoginSuccessEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.appleLoginSuccess),
    map((action: any) => action.payload),
    switchMap((response: IdToken) => [fromActions.saveUserIdToken(response)]),
);


/*-------------- Email Login ------------------------*/
//email login/signup side effects/epics
const emailLoginEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.emailLogin),
    map((action: any) => action.payload),
    switchMap((userLoginData) => {
        return AuthService.emailLoginRequest(userLoginData)
            .then((response: IdToken) => {
                return fromActions.emailLoginSuccess(response);
            })
            .catch((error) => fromActions.emailLoginError(extractError(error, LOGIN_SIGNUP_ROUTES.LOGIN)))
    })
);


const emailLoginSuccessEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.emailLoginSuccess),
    map((action: any) => action.payload),
    switchMap((response: IdToken) => [fromActions.saveUserIdToken(response)]),
);

/*-------------- Email Signup/Register ------------------------*/

// const emailSignupEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
//     ofType(fromActions.emailSignup),
//     map((action: any) => action.payload),
//     switchMap((signupData) => {
//         return AuthService.emailSignupRequest(signupData)
//             .then(response => {
//                 // navigate(LOGIN_SIGNUP_ROUTES.THANKS);
//                 return fromActions.emailSignupSuccess();
//             })
//             .catch((error) => fromActions.emailSignupError(extractError(error, LOGIN_SIGNUP_ROUTES.REGISTER)))
//     })
// );


/*-------------- App Logout ------------------------*/
//logout side effects/epics
const logoutEpic = (action$, state$): Observable<Action> => action$.pipe(
    ofType(fromActions.logout),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { user } = state.userState;
        return AuthService.logoutRequest(user?.loginType)
            .then(() => {
                return fromActions.logoutSuccess();
            })
            .catch(error => {
                const newError = AuthService.checkUserCancelErrors(extractError(error, LOGIN_SIGNUP_ROUTES.LOGIN));
                if (newError == null) return fromActions.logoutCancel();
                return fromActions.logoutError(newError);
            })
    })
);

const logoutSuccessEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.logoutSuccess),
    switchMap(() => {
        // AuthService.registerFcmToken({ token: '', deviceType: getDeviceType() });
        return [fromActions.removeUser(), fromActions.removeAppState()];
    })
);

const logoutErrorEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.logoutError),
    switchMap(() => {
        // AuthService.registerFcmToken({ token: '', deviceType: getDeviceType() });
        return [fromActions.removeUser()];
    })
);


/*-------------- Update User Profile ------------------------*/
const updateUserProfileEpic = (action$, state$): Observable<Action> => action$.pipe(
    ofType(fromActions.updateUserProfile),
    map((action: any) => action.payload),
    withLatestFrom(state$),
    switchMap(([payload, state]) => {
        const { id, accessToken } = state.userState.user;
        return AuthService.updateUserProfile(id, payload.data)
            .then((user) => {
                return fromActions.updateUserProfileSuccess({ user: { ...user, accessToken }, route: payload.route });
            })
            .catch(error => fromActions.updateUserProfileError(extractError(error, ADD_EMAIL)))
    })

);

const updateUserProfileSuccessEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.updateUserProfileSuccess),
    map((action: any) => action.payload),
    map((payload: any) => {
        if (payload.route) {
            // navigate(payload.route);
            return fromActions.addUser(payload.user);
        }
        return fromActions.userExist();
    })
);


/*-------------- Change User Password ------------------------*/

// const changeUserPasswordEpic = (action$, state$) => action$.pipe(
//     ofType(fromActions.changeUserPassword),
//     map((action: any) => action.payload),
//     withLatestFrom(state$),
//     switchMap(([payload, state]) => {
//         return AuthService.changeUserPassword(payload)
//             .then((res) => {
//                 // navigate(LOGIN_SIGNUP_ROUTES.LOGIN);
//                 return fromActions.changeUserPasswordSuccess();
//             })
//             .catch(error => fromActions.changeUserPasswordError(extractError(error, LOGIN_SIGNUP_ROUTES.RESET_PASSWORD)))
//     })

// );

// const changeUserPasswordSuccessEpic = (action$: Observable<Action>) => action$.pipe(
//     ofType(fromActions.changeUserPasswordSuccess),
//     switchMap(() => [fromActions.toastMessage('Password changed successfully')])
// );


/*-------------- Reset User password  ------------------------*/
// const resetUserPasswordEpic = (action$: Observable<Action>) => action$.pipe(
//     ofType(fromActions.resetUserPassword),
//     map((action: any) => action.payload),
//     switchMap(email => {
//         return AuthService.resetPassword(email)
//             .then(res => {
//                 // navigate(LOGIN_SIGNUP_ROUTES.LOGIN);
//                 return fromActions.resetUserPasswordSuccess(res);
//             })
//             .catch(error => fromActions.resetUserPasswordError(extractError(error, LOGIN_SIGNUP_ROUTES.FORGOT_PASSWORD)))
//     })
// );

// const resetUserPasswordSuccessEpic = (action$: Observable<Action>) => action$.pipe(
//     ofType(fromActions.resetUserPasswordSuccess),
//     map((action: any) => action.payload),
//     switchMap(payload => [fromActions.toastMessage('Email with reset password sent')])
// );

/*-------------- Activate Account  ------------------------*/
const activateAccountEpic = (action$: Observable<Action>) => action$.pipe(
    ofType(fromActions.activateAccount),
    map((action: any) => action.payload),
    switchMap(key => {
        return AuthService.activateAccount(key)
            .then(res => {
                // navigate(LOGIN_SIGNUP_ROUTES.LOGIN);
                return fromActions.activateAccountSuccess(res);
            })
            .catch(error => fromActions.activateAccountError(extractError(error, LOGIN_SIGNUP_ROUTES.ACCOUNT_ACTIVATE)))
    })
);

const activateAccountSuccessEpic = (action$: Observable<Action>) => action$.pipe(
    ofType(fromActions.activateAccountSuccess),
    map((action: any) => action.payload),
    switchMap(payload => [fromActions.toastMessage('Pubrule account activated')])
);


// /*-------------- Save Country  ------------------------*/
const saveUserCountryEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.saveUserCountry),
    map((action: any) => action.payload),
    switchMap((payload: string) => {
        AppStorage.setUserCountry(payload)
        return of(fromActions.saveUserCountrySuccess(payload));
    })
);

const getUserCountryEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.getUserCountry),
    switchMap(() => {
        const res = AppStorage.getUserCountry();
        if (res) return of(fromActions.getUserCountrySuccess(res))
        return of(fromActions.getUserCountrySuccess(''));
        //     .catch(error => fromActions.getUserCountryError(extractError(error)))
    })
);

export const authEpics = combineEpics(
    facebookLoginEpic,
    // getFacebookUserDetailsEpic,
    facebookLoginSuccessEpic,

    googleLoginEpic,
    googleLoginSuccessEpic,

    // appleLoginEpic,
    // appleLoginSuccessEpic,

    emailLoginEpic,
    emailLoginSuccessEpic,
    // emailSignupEpic,

    logoutEpic,
    logoutSuccessEpic,
    logoutErrorEpic,

    updateUserProfileEpic,
    updateUserProfileSuccessEpic,

    // changeUserPasswordEpic,
    // changeUserPasswordSuccessEpic,

    // resetUserPasswordEpic,
    // resetUserPasswordSuccessEpic,

    activateAccountEpic,
    activateAccountSuccessEpic,

    saveUserCountryEpic,
    getUserCountryEpic
);