import React, { Component } from "react";
import { useTranslation } from "react-i18next";
import { CustomButton, ScreenHolder } from "../components";
import { FONT, THEME } from "../enums";
import { LOGIN_SIGNUP_ROUTES, MAIN_APP_ROUTES } from "../routes";
import CustomText from "../utils/custom-text";

export function RegistrationAKScreen(props: any) {
    const { history, location } = props;
    const { t, i18n } = useTranslation();
    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <CustomText customStyle={styles.headerText}>{t('registerSuccessMessage')}</CustomText>
                <CustomText customStyle={styles.headerText}>{t('checkMailToActivateAccount')}</CustomText>
            </div>
            <div style={styles.message}>
                <CustomText customStyle={styles.msgText}>{t('appAknw')}</CustomText>
            </div>
            <div style={styles.action}>
                <CustomButton
                    name={t('next')}
                    enable={true}
                    onClick={() => history.push({pathname: LOGIN_SIGNUP_ROUTES.LOGIN, state: location.state})}
                />
            </div>
        </div>
    )

}

const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: THEME.WHITE,
        position: 'relative',
        boxSizing: 'border-box',
        width: '100%',
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
        width: '100%',
        paddingTop: 15,
        // paddingBottom: 15,
        paddingLeft: 20,
        paddingRight: 20
    },
    headerText: {
        fontSize: 17,
        color: THEME.BLACK,
        textAlign: 'center',
        // fontFamily: FONT.MANROPE_REGULAR,
    },
    message: {
        display: 'flex',
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 30,
        paddingBottom: 40,
        boxSizing: 'border-box',
        width: '100%',
    },
    msgText: {
        fontSize: 25,
        color: THEME.ORANGE_RED,
        // fontFamily: FONT.MANROPE_REGULAR,
        textAlign: 'center',
    },
    action: {
        paddingLeft: 20,
        paddingRight: 20,
        // marginTop: 30,
    },
})